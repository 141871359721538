import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import * as Sentry from "@sentry/react";
import AppStateContext from "../../../../appState/AppStateContext";
import InfoMessage from "../../../atoms/General/InfoMessage";
import DesktopButtons from "../../../atoms/Dashboard/Button/DesktopButton";
import MobileButtons from "../../../atoms/Dashboard/Button/MobileButton";
import LicenseFormItem from "../../../molecules/Dashboard/License/LicenseFormItem";
import Spinner from "../../../atoms/General/Spinner";
import Message from "../../../atoms/General/Message";
import Modal from "../../../atoms/General/Modal";
import "./LicenseItem.css";

/**
 * Renders the LicenseItem component.
 * This component displays detailed information about a license and provides actions for activation and renewal.
 *
 * @returns {JSX.Element} The LicenseItem component.
 */
const LicenseItem = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { state, dispatch, cookieLoaded } = useContext(AppStateContext);
  const [licenseData, setLicenseData] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingSpinner, setLoadingSpinner] = useState(true);
  const [incompletenessOccurred, setIncompletenessOccurred] = useState(false);
  const [noRole, setNoRole] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [isModalOpenActivation, setIsModalOpenActivation] = useState(false);
  const [isModalOpenRenew, setIsModalOpenRenew] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (cookieLoaded) {
      const fetchLicenseItem = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/contract/${location.state.itemId}`,
            {
              headers: {
                Authorization: `Bearer ${state.accessToken}`,
                "Refresh-Token": state.userDetails.refreshToken,
              },
              withCredentials: true,
            }
          );
          if (
            response.data.accessToken &&
            response.data.accessToken !== state.accessToken
          ) {
            dispatch({
              type: "UPDATE_ACCESS_TOKEN",
              payload: response.data.accessToken,
            });
          }
          if (response.status === 200) {
            setLicenseData(response.data.formattedContract);
            setLoading(false);
            setLoadingSpinner(false);
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            navigate("/automatic/logout");
          } else if (error.response && error.response.status === 403) {
            setNoRole(true);
            setLoading(false);
            setLoadingSpinner(false);
          } else if (error.response && error.response.status === 404) {
            setIncompletenessOccurred(true);
            setLoading(false);
            setLoadingSpinner(false);
          } else {
            setErrorOccurred(true);
            setLoading(false);
            setLoadingSpinner(false);
          }
          Sentry.captureException(error, {
            extra: {
              message: "Error retrieving license details",
              component: "LicenseList",
            },
          });
        }
      };
      fetchLicenseItem();
    }
  }, [cookieLoaded]);



  const handleModalActivation = () => {
    setIsModalOpenActivation(true);
  };

  const handleModalRenew = () => {
    setIsModalOpenRenew(true);
  };

  // Spinner view while loading data
  if (loading) {
    return (
      <div className="loading">
        <div className="loading__info">Ładowanie...</div>
        {loadingSpinner && (
          <div className="spinner">
            <Spinner />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="item">
      <div className="item__btn">
        <InfoMessage additionalInfo="W tym miejscu możesz zobaczyć szczegółowe informacje o swojej licencji. Nieopłacona licencja wygasa automatycznie. Opłacenie licencji aktywuje ją ponownie." />
        {!loading && !incompletenessOccurred && !noRole && !errorOccurred && (
          <div>
            <DesktopButtons
              action="license"
              modalActivation={handleModalActivation}
              modalRenew={handleModalRenew}
              isActive={licenseData.isActive}
            />
            <MobileButtons
              action="license"
              modalActivation={handleModalActivation}
              modalRenew={handleModalRenew}
              isActive={licenseData.isActive}
            />
          </div>
        )}
      </div>
      <div className="singleLicense">
        <div className="singleLicense__message">
          <Message
            incompleteness={incompletenessOccurred}
            error={errorOccurred}
            noRole={noRole}
            incompletenessMessage="Nie znaleziono odpowiednich danych. Skontaktuj się z administratorem!"
            errorMessage="Błąd komunikacji z serwerem. Skontaktuj się z administratorem!"
            noRoleMessage="Brak odpowiedniej roli. Skontaktuj się z administratorem!"
          />
        </div>
        {!loading && !incompletenessOccurred && !noRole && !errorOccurred && (
          <>
            <div className="modal__status">
              <div className="modal__header">
                <div
                  className={licenseData.isActive ? "dot--green" : "dot--red"}
                ></div>
                <h1>Licencja {licenseData.licenseName}</h1>
              </div>
            </div>
            <LicenseFormItem licenseData={licenseData}/>
          </>
        )}
      </div>
      <Modal
        action="activation"
        isOpen={isModalOpenActivation}
        onClose={() => setIsModalOpenActivation(false)}
        title="Aktywacja licencji"
        content="Poniżej wpisz kod aktywacyjny."
      />
      <Modal
        action="renew"
        isOpen={isModalOpenRenew}
        onClose={() => setIsModalOpenRenew(false)}
        title="Odnowienie licencji"
        content="Na jak długo chcesz odnowić licencje?"
        contractID={id}
      />
    </div>
  );
};

export default LicenseItem;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 *
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER  +
 *
 * PASS
 ***/
