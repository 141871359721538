import React from "react";
import "./Card.css";

/**
 * Renders a card component with different types and content based on the provided props.
 *
 * @param {Object} props - The props for the Card component.
 * @param {string} props.type - The type of the card.
 * @param {string} props.title - The title of the card.
 * @param {string} props.sum - The sum value for the card.
 * @param {string} props.duration - The duration value for the card.
 * @param {string} props.discount - The discount value for the card.
 * @param {string} props.tableFrom - The starting table value for the card.
 * @param {string} props.tableTo - The ending table value for the card.
 * @param {boolean} props.isSelected - Indicates whether the card is selected.
 * @param {function} props.onClick - The click event handler for the card.
 * @param {Object} props.purchase - The purchase details for the card.
 * @param {string} props.step - The current step of the card.
 * @returns {JSX.Element} The rendered Card component.
 */
const Card = ({
  type,
  title,
  sum,
  duration,
  discount,
  tableFrom,
  tableTo,
  isSelected,
  onClick,
  purchase,
  step,
}) => {
  let cardClass;
  let content;
  let month;
  let tables;

  // Assigning a css class depending on the type
  switch (type) {
    case "mini":
      cardClass = isSelected ? "card--mini card--selected" : "card--mini";
      break;
    case "non-functional":
      cardClass = "card--non-functional";
      break;
    case "summary":
      cardClass = "card__summary";
      break;
    default:
      cardClass = isSelected ? "card card--selected" : "card";
      break;
  }

  // Assigning content depending on the title
  switch (title) {
    case "Licencja":
      content =
        "Wykupiona licencja umożliwia korzystanie z usługi. Licencja powinna zostać przypisana do lokalizacji. Każda lokalizacja wymaga odrębnej licencji.";
      break;
    case "1":
      content = `Czas: ${duration} miesiąc, Cena: ${sum} zł, Rabat: ${discount}%`;
      break;
    case "3":
      content = `Czas: ${duration} miesiące, Cena: ${sum} zł, Rabat: ${discount}%`;
      break;
    case "6":
      content = `Czas: ${duration} miesiący, Cena: ${sum} zł, Rabat: ${discount}%`;
      break;
    case "12":
      content = `Czas: ${duration} miesiący, Cena: ${sum} zł, Rabat: ${discount}%`;
      break;
    case "Samodzielna":
      content = "Implementacja samodzielna zgodnie z załączoną instrukcją.";
      break;
    case "Elastyczna":
      content = `Implementacja przeprowadzona przez specjalistę. Cena: ${sum} zł, Rabat: ${discount}%`;
      break;
    case "Brak":
      content = "Nie dotyczy mam już zestaw startowy który mogę wykorzystać";
      break;
    case "Start":
      content = `Do ${tableTo} stolików. Cena: ${sum} zł, Rabat: ${discount}%`;
      break;
    case "Start Plus":
      content = `Od ${tableFrom} do ${tableTo} stolików. Cena: ${sum} zł, Rabat: ${discount}%`;
      break;
    case "Start Max":
      content = `Od ${tableFrom} do ${tableTo} stolików. Cena: ${sum} zł, Rabat: ${discount}%`;
      break;
    case "Wsparcie 24/7":
      content = `Wsparcie online 24/7 Cena: ${sum} zł, Rabat: ${discount}%`;
      break;
    case "Chip NFC":
      content = `Dodatkowych 10 chip-ów NFC Cena: ${sum} zł`;
      break;
    case "Podsumowanie":
      content = "";
      break;
    default:
      content = "Niepoprawna wartość.";
      break;
  }

  if (purchase) {
    switch (purchase.lengthLicense.key) {
      case "1":
        month = "miesiąc";
        break;
      case "3":
        month = "miesiące";
        break;
      case "6":
        month = "miesięcy";
        break;
      case "12":
        month = "miesięcy";
        break;
      default:
        month = "";
        break;
    }

    switch (purchase.size.key) {
      case "Start":
        tables = "do 50";
        break;
      case "Start Plus":
        tables = "od 50 do 100";
        break;
      case "Start Max":
        tables = "od 100 do 150";
        break;
      default:
        tables = "";
        break;
    }
  }

  const renderAdditionsList = () => {
    // Sprawdzamy, czy dodatki istnieją i czy są tablicą
    if (Array.isArray(purchase.additions) && purchase.additions.length > 0) {
      // Przekształcamy tablicę obiektów, aby zwracała tylko wartości `key`
      const additionsList = purchase.additions.map((addition) => (
        <span className="summary__additions--items" key={addition.key}>
          <p>
            <b>Pakiet dodatkowy:</b> {addition.key}
          </p>
          <b>Cena: {addition.value} zł.</b>
          <p>*Najniższa cena z 30 dni przed obniżką: 2550 zł.</p>
        </span>
      ));

      // Składamy elementy listy w jeden element z przecinkami między nimi
      const renderedAdditions = additionsList.reduce((prev, curr) => [
        prev,
        curr,
      ]);

      // Zwracamy jako element JSX
      return <div>{renderedAdditions}</div>;
    } else {
      return (
        <div>
          <b>Pakiet dodatkowy:</b> żaden pakiet dodatkowy nie został wybrany.
        </div>
      );
    }
  };

  return (
    <div className={cardClass} onClick={onClick}>
      {type === "summary" ? null : (
        <>
          {type === "mini" ? null : <div className="card__title">{title}</div>}
        </>
      )}
      <div className="card__content">{content}</div>
      {step === "summary" ? (
        <div className="summary">
          <div className="summary__details">
            <div className="summary__card">
              <p>
                <b>Długość subskrypcji:</b> {purchase.lengthLicense.key} {month}
              </p>
              <span>
                {purchase.lengthLicense.key === "1" ? null : (
                  <p className="summary__card--p">
                    (miesięcznie {purchase.sum.monthlyFee} zł.)
                  </p>
                )}
              </span>
              <p>
                <b>Rabat: 25%</b>
              </p>
              <p>
                <b>
                  Cena (po udzieleniu rabatu): {purchase.lengthLicense.value}{" "}
                  zł.
                </b>
              </p>

              <p>* Najniższa cena z 30 dni przed obniżką: 2550 zł.</p>
            </div>
            <div className="summary__card">
              {purchase.implementation.key === "Samodzielna" ? (
                <p>
                  <b>Sposób implementacji:</b>{" "}
                  {purchase.implementation.key.toLowerCase()}.
                </p>
              ) : (
                <div className="summary__card--condition">
                  <p>
                    <b>Sposób implementacji:</b> {purchase.implementation.key}
                  </p>
                  <p>
                    <b>Rabat: 25%</b>
                  </p>
                  <p>
                    <b>
                      Cena (po udzieleniu rabatu):{" "}
                      {purchase.implementation.value} zł.
                    </b>
                  </p>

                  <p>* Najniższa cena z 30 dni przed obniżką: 2550 zł.</p>
                </div>
              )}
            </div>

            <div className="summary__card">
              {purchase.size.key === "Brak" ? (
                <p>
                  <b>Zestaw aktywacyjny:</b> posiadam niewykorzystany zestaw
                  aktywacyjny.
                </p>
              ) : (
                <div className="summary__card--condition">
                  <p>
                    <b>Zestaw aktywacyjny:</b> {purchase.size.key}{" "}
                    {purchase.size.key === "Brak" ? null : (
                      <p className="summary__size--p">
                        (Liczba stolików: {tables})
                      </p>
                    )}
                  </p>
                  <p>
                    <b>Rabat: 25%</b>
                  </p>
                  <p>
                    <b>
                      Cena (po udzieleniu rabatu): {purchase.size.value} zł.
                    </b>
                  </p>
                  <p>*Najniższa cena z 30 dni przed obniżką: 2550 zł.</p>
                </div>
              )}
            </div>

            <div className="summary__additions">
              <p>{renderAdditionsList()}</p>
            </div>
          </div>

          <div className="summary__right">
            <div className="summary__delivery">
              <h2>
                {purchase.implementation.key === "Samodzielna" ? (
                  <div>Dane dostawy:</div>
                ) : (
                  <div>Dane implementacji:</div>
                )}
              </h2>
              <p>
                <b>Imię:</b> {purchase.delivery.firstName}
              </p>
              <p>
                <b>Nazwisko:</b> {purchase.delivery.lastName}
              </p>
              <p>
                <b>Adres:</b> {purchase.delivery.address}{" "}
                {purchase.delivery.streetNumber} {purchase.delivery.localNumber}
              </p>
              <p>
                <b>Miasto:</b> {purchase.delivery.city}{" "}
              </p>
              <p>
                <b>Kod pocztowy:</b> {purchase.delivery.zipCode}
              </p>
              <br />
              <span>
                <p className="summary__delivery--p">
                  *W panelu zarządzania licencją będziesz mógł przedłużyć
                  subskrypcję, zmienić jej długość lub ją zakończyć.
                </p>
                {purchase.size.key === "Brak" ? null : (
                  <p className="summary__delivery--p">
                    **W zestawie znajduje się: eleganckie pudełko, przydatne
                    chipy NFC, kolorowe naklejki oraz klarowna instrukcja.
                  </p>
                )}

                {purchase.implementation.key === "Samodzielna" ? null : (
                  <p className="summary__delivery--p">
                    ***Przed implementacją skontaktujemy się z Państwem w celu
                    ustalenia dogodnego terminu.
                  </p>
                )}
              </span>
            </div>

            <div className="summary__fee">
              <p>
                <b>Opłata subskrypcyjna: {purchase.sum.subscriptionFee} zł.</b>
                {purchase.lengthLicense.key === "1" ? null : (
                  <p className="summary__fee--p">
                    (miesięcznie {purchase.sum.monthlyFee} zł.)
                  </p>
                )}
              </p>
              <p>
                <b>Opłaty jednorazowe: {purchase.sum.oneTimeFee} zł.</b>
              </p>
              <br />
              <p>
                <b>
                  Suma (bez VAT): {purchase.sum.totalFeesWithoutTax.toFixed(2)}{" "}
                  zł.
                </b>
              </p>
              <p>
                <b>VAT: 23%</b>
              </p>
              <br />
              <p>
                <b>Suma całkowita: {purchase.sum.totalFees} zł.</b>
              </p>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Card;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
