import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { useParams, useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import handleError from "../../../utils/HandleError";
import AppStateContext from "../../../appState/AppStateContext";
import Message from "../../atoms/General/Message";
import Spinner from "../../atoms/General/Spinner";
import styles from "./MenuItemForCategory.module.css";

/**
 * Represents a menu item component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.buttonAction - The button action function.
 * @param {Function} props.toggleEditable - The toggle editable function.
 * @returns {JSX.Element} The rendered MenuItem component.
 */
const MenuItemForCategory = ({ buttonAction, toggleEditable }) => {
  const navigate = useNavigate();

  const { item } = useParams();
  const { state, dispatch, cookieLoaded } = useContext(AppStateContext);

  const [menuItemDetails, setMenuItemDetails] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [initialValues, setInitialValues] = useState({});

  const [noRole, setNoRole] = useState(false);
  const [successOccurred, setSuccessOccurred] = useState(false);
  const [incompletenessOccurred, setIncompletenessOccurred] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (cookieLoaded) {
      const fetchMenuItemDetails = async () => {
        setSuccessOccurred(false);
        setNoRole(false);
        setIncompletenessOccurred(false);
        setErrorOccurred(false);
        setIsLoading(true);
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/menu/${item}`,
            {
              headers: {
                Authorization: `Bearer ${state.accessToken}`,
                "Refresh-Token": state.userDetails.refreshToken,
              },
              withCredentials: true,
            }
          );

          if (
            response.data &&
            response.data.newAccessToken &&
            response.data.newAccessToken !== state.newAccessToken
          ) {
            dispatch({
              type: "UPDATE_ACCESS_TOKEN",
              payload: response.data.newAccessToken,
            });
          }

          if (response.status === 200) {
            setMenuItemDetails(response.data.menu);
            setFormValues(response.data.menu);
            setInitialValues(response.data.menu);
          }
        } catch (error) {
          Sentry.captureException(error, {
            extra: {
              message: "Error retrieving menu item details.",
              component: "MenuItem",
            },
          });
          handleError(
            error,
            navigate,
            setNoRole,
            setIncompletenessOccurred,
            setErrorOccurred
          );
        } finally {
          setIsLoading(false);
        }
      };
      fetchMenuItemDetails();
    }
  }, [item]);

  const handleInputChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: DOMPurify.sanitize(event.target.value),
    });
  };

  const getChangedValues = () => {
    const changedValues = {};
    for (const key in formValues) {
      if (formValues[key] !== initialValues[key]) {
        changedValues[key] = formValues[key];
      }
    }
    return changedValues;
  };

  const handleSubmit = async () => {
    const changedValues = getChangedValues();
    if (Object.keys(changedValues).length === 0) {
      return;
    }

    try {
      setSuccessOccurred(false);
      setNoRole(false);
      setIncompletenessOccurred(false);
      setErrorOccurred(false);
      setIsLoading(true);
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/menu/${item}`,
        changedValues,
        {
          headers: {
            Authorization: `Bearer ${state.accessToken}`,
            "Refresh-Token": state.userDetails.refreshToken,
          },
          withCredentials: true,
        }
      );

      if (
        response.data &&
        response.data.newAccessToken &&
        response.data.newAccessToken !== state.newAccessToken
      ) {
        dispatch({
          type: "UPDATE_ACCESS_TOKEN",
          payload: response.data.newAccessToken,
        });
      }

      if (response.status === 200) {
        setSuccessOccurred(true);
      }
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          message: "Error updating menu item",
          component: "MenuItem",
        },
      });
      handleError(
        error,
        navigate,
        setNoRole,
        setIncompletenessOccurred,
        setErrorOccurred
      );
    } finally {
      toggleEditable();
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Message
        success={successOccurred}
        incompleteness={incompletenessOccurred}
        error={errorOccurred}
        noRole={noRole}
        successMessage="Element menu została zaktualizowana!"
        incompletenessMessage="Nie znaleziono potrzebnych danych na serwerze. Skontaktuj się z administratorem!"
        errorMessage="Wewnętrzny błąd serwera. Skontaktuj się z administratorem!"
        noRoleMessage="Brak udpowiednich uprawnień. Skontaktuj się z administratorem!"
      />
      {isLoading && <Spinner />}
      <div className={styles.categoryForm}>
        {menuItemDetails && (
          <form className={styles.form}>
            <div className={styles.columns}>
              <div className={styles.column}>
                <div>
                  <label>ID</label>
                  <input
                    className={styles.myInput}
                    type="text"
                    value={formValues.id || ""}
                    readOnly
                  />
                </div>

                <div>
                  <label>Nazwa PL:</label>
                  <input
                    className={styles.myInput}
                    type="text"
                    value={formValues.namePL || ""}
                    name="namePL"
                    onChange={handleInputChange}
                    disabled={buttonAction}
                  />
                </div>

                <div>
                  <label>Opis PL:</label>
                  <input
                    className={styles.myInput}
                    type="text"
                    value={formValues.descriptionPL || ""}
                    name="descriptionPL"
                    onChange={handleInputChange}
                    disabled={buttonAction}
                  />
                </div>
                <div>
                  <label>Składniki PL:</label>
                  <input
                    className={styles.myInput}
                    type="text"
                    value={formValues.ingredientsPL || ""}
                    name="ingredientsPL"
                    onChange={handleInputChange}
                    disabled={buttonAction}
                  />
                </div>
                <div>
                  <label>Cena:</label>
                  <input
                    className={styles.myInput}
                    type="text"
                    value={formValues.price || ""}
                    name="price"
                    onChange={handleInputChange}
                    disabled={buttonAction}
                  />
                </div>
                <div>
                  <label>Vege:</label>
                  <select
                    name="vege"
                    value={formValues.vege}
                    onChange={handleInputChange}
                    disabled={buttonAction}
                    className={styles.mySelect}
                  >
                    <option value="true">Tak</option>
                    <option value="false">Nie</option>
                  </select>
                </div>
              </div>
              <div className={styles.column}>
                <div>
                  <label>Kategoria aktywna:</label>
                  <select
                    name="isActive"
                    value={formValues.isActive}
                    onChange={handleInputChange}
                    disabled={buttonAction}
                    className={styles.mySelect}
                  >
                    <option value="true">Tak</option>
                    <option value="false">Nie</option>
                  </select>
                </div>
                <div>
                  <label>Nazwa EN:</label>
                  <input
                    className={styles.myInput}
                    type="text"
                    value={formValues.nameEN || ""}
                    name="nameEN"
                    onChange={handleInputChange}
                    disabled={buttonAction}
                  />
                </div>

                <div>
                  <label>Opis EN:</label>
                  <input
                    className={styles.myInput}
                    type="text"
                    value={formValues.descriptionEN || ""}
                    name="descriptionEN"
                    onChange={handleInputChange}
                    disabled={buttonAction}
                  />
                </div>
                <div>
                  <label>Składniki EN:</label>
                  <input
                    className={styles.myInput}
                    type="text"
                    value={formValues.ingredientsEN || ""}
                    name="ingredientsEN"
                    onChange={handleInputChange}
                    disabled={buttonAction}
                  />
                </div>
                <div>
                  <label>Poziom ostrości:</label>
                  <select
                    name="spiciness"
                    value={formValues.spiciness}
                    onChange={handleInputChange}
                    disabled={buttonAction}
                    className={styles.mySelect}
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </div>
                <div>
                  <label>Nowość:</label>
                  <select
                    name="novelty"
                    value={formValues.novelty}
                    onChange={handleInputChange}
                    disabled={buttonAction}
                    className={styles.mySelect}
                  >
                    <option value="true">Tak</option>
                    <option value="false">Nie</option>
                  </select>
                </div>
              </div>
            </div>
            <div className={styles.btn}>
              <button
                type="button"
                onClick={handleSubmit}
                disabled={buttonAction}
              >
                Aktualizuj
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default MenuItemForCategory;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
