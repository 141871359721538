import * as Sentry from "@sentry/react";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppStateContext from "../../../appState/AppStateContext";
import handleError from "../../../utils/HandleError";
import Message from "../../atoms/General/Message";
import Pagination from "../../atoms/General/Pagination";
import Spinner from "../../atoms/General/Spinner";
import EmployeeCard from "../molecules/EmployeeCard";
import styles from "./EmployeeList.module.css";

/**
 * Renders a list of employees connected to a specific location.
 *
 * @returns {JSX.Element} The EmployeeList component.
 */
const EmployeeList = () => {
  const navigate = useNavigate();
  const { selectedLocation } = useParams();

  const { state, dispatch, cookieLoaded } = useContext(AppStateContext);
  const [users, setUsers] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [noRole, setNoRole] = useState(false);
  const [incompletenessOccurred, setIncompletenessOccurred] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(3);

  useEffect(() => {
    if (cookieLoaded) {
      const fetchUsers = async () => {
        setIsLoading(true);
        setNoRole(false);
        setIncompletenessOccurred(false);
        setErrorOccurred(false);
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/user/all/${selectedLocation}`,
            {
              headers: {
                Authorization: `Bearer ${state.accessToken}`,
                "Refresh-Token": state.userDetails.refreshToken,
              },
              withCredentials: true,
            }
          );

          if (
            response.data &&
            response.data.newAccessToken &&
            response.data.newAccessToken !== state.newAccessToken
          ) {
            dispatch({
              type: "UPDATE_ACCESS_TOKEN",
              payload: response.data.newAccessToken,
            });
          }

          if (response.status === 200 && Array.isArray(response.data.users)) {
            setUsers(response.data.users);
            setNoRole(false);
            setIncompletenessOccurred(false);
            setErrorOccurred(false);
          }
        } catch (error) {
          Sentry.captureException(error, {
            extra: {
              message: "Error retrieving all users connected to this location",
              component: "EmployeeList",
            },
          });
          handleError(
            error,
            navigate,
            setNoRole,
            setIncompletenessOccurred,
            setErrorOccurred
          );
        } finally {
          setIsLoading(false);
        }
      };

      fetchUsers();
    }
  }, [cookieLoaded]);

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <h1 className={styles.title}>LISTA TWOICH PRACOWNIKÓW</h1>
      <div className={styles.message}>
        <Message
          incompleteness={incompletenessOccurred}
          error={errorOccurred}
          noRole={noRole}
          incompletenessMessage="Nie znaleziono potrzebnych danych na serwerze. Skontaktuj się z administratorem!"
          errorMessage="Wewnętrzny błąd serwera. Skontaktuj się z administratorem!"
          noRoleMessage="Brak udpowiednich uprawnień. Skontaktuj się z administratorem!"
        />
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className={styles.card}>
            {currentUsers.map((user) => (
              <EmployeeCard key={user.id} user={user} />
            ))}
          </div>
          <Pagination
            itemsPerPage={usersPerPage}
            totalItems={users.length}
            paginate={paginate}
          />
        </>
      )}
    </div>
  );
};

export default EmployeeList;

// - AUDIT
// - 25.06.2024
// - LOADING SUPPORT +
// - ERROR HANDLING +
// - SECURING INCOMING DATA +
// - SECURING OUTGOING DATA +
// - API CORRECTNESS +
// - CSS CORRECTNESS +
// - ACCESS TOKEN +
// - MOBILE 360PX +
// - BACKEND CONTROLER +
// - PASS
