import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as Sentry from "@sentry/react";
import AppStateContext from "../../../appState/AppStateContext";
import Message from "../../atoms/General/Message";
import Spinner from "../../atoms/General/Spinner";
import Pagination from "../../atoms/General/Pagination";
import handleError from "../../../utils/HandleError";
import styles from "./LocationList.module.css";
import Card from "../molecules/LocationCard";

/**
 * Renders a list of locations based on the selected company.
 *
 * @component
 * @param {Object} selected - The selected company.
 * @returns {JSX.Element} The rendered LocationList component.
 */
const LocationList = ({ selected }) => {
  const navigate = useNavigate();

  const [hasRenderedCompany, setHasRenderedCompany] = useState(false);

  const { state, dispatch, cookieLoaded } = useContext(AppStateContext);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [companies, setCompanies] = useState([]);
  const [locations, setLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [noRole, setNoRole] = useState(false);
  const [incompletenessOccurred, setIncompletenessOccurred] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [locationsPerPage] = useState(3);

  useEffect(() => {
    if (cookieLoaded) {
      const fetchCompanies = async () => {
        setIsLoading(true);
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/company/all/${state.userDetails.userSysID}`,
            {
              headers: {
                Authorization: `Bearer ${state.accessToken}`,
                "Refresh-Token": state.userDetails.refreshToken,
              },
              withCredentials: true,
            }
          );

          if (
            response.data &&
            response.data.newAccessToken &&
            response.data.newAccessToken !== state.newAccessToken
          ) {
            dispatch({
              type: "UPDATE_ACCESS_TOKEN",
              payload: response.data.newAccessToken,
            });
          }

          if (
            response.status === 200 &&
            Array.isArray(response.data.companies)
          ) {
            setCompanies(response.data.companies);
            setNoRole(false);
            setIncompletenessOccurred(false);
            setErrorOccurred(false);
          }
        } catch (error) {
          Sentry.captureException(error, {
            extra: {
              message: "Error retrieving all companies connected to this user",
              component: "LocationList",
            },
          });
          handleError(
            error,
            navigate,
            setNoRole,
            setIncompletenessOccurred,
            setErrorOccurred
          );
        } finally {
          setIsLoading(false);
        }
      };

      fetchCompanies();
    }
  }, [cookieLoaded]);

  const fetchLocations = async () => {
    setLocations([]);
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/location/all/${selectedCompany}`,
        {
          headers: {
            Authorization: `Bearer ${state.accessToken}`,
            "Refresh-Token": state.userDetails.refreshToken,
          },
          withCredentials: true,
        }
      );

      if (
        response.data &&
        response.data.newAccessToken &&
        response.data.newAccessToken !== state.newAccessToken
      ) {
        dispatch({
          type: "UPDATE_ACCESS_TOKEN",
          payload: response.data.newAccessToken,
        });
      }

      if (response.status === 200 && Array.isArray(response.data.locations)) {
        setLocations(response.data.locations);
        setNoRole(false);
        setIncompletenessOccurred(false);
        setErrorOccurred(false);
      }
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          message: "Error retrieving all location connected to this user",
          component: "LocationList",
        },
      });
      handleError(
        error,
        navigate,
        setNoRole,
        setIncompletenessOccurred,
        setErrorOccurred
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
    selected(event.target.value);
  };

  useEffect(() => {
    if (hasRenderedCompany) {
      handleNextClick();
    } else {
      setHasRenderedCompany(true);
    }
  }, [selectedCompany]);

  const handleNextClick = () => {
    fetchLocations();
  };

  const indexOfLastLocation = currentPage * locationsPerPage;
  const indexOfFirstLocation = indexOfLastLocation - locationsPerPage;
  const currentLocations = locations.slice(
    indexOfFirstLocation,
    indexOfLastLocation
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <h1 className={styles.title}>LISTA TWOICH LOKALIZACJI</h1>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <form className={styles.form}>
            <label className={styles.label}>
              WYBIERZ FIRMĘ
              <select
                value={selectedCompany}
                onChange={handleCompanyChange}
                className={styles.mySelect}
              >
                <option value="">Wybierz firmę</option>{" "}
                {companies.map((company) => (
                  <option key={company.id} value={company.id}>
                    {company.companyName} - {company.address}
                  </option>
                ))}
              </select>
            </label>
          </form>
          <div className={styles.card}>
            {currentLocations.map((location) => (
              <Card key={location.id} location={location} />
            ))}
          </div>
          <Pagination
            itemsPerPage={locationsPerPage}
            totalItems={locations.length}
            paginate={paginate}
          />
          <div className={styles.message}>
            <Message
              incompleteness={incompletenessOccurred}
              error={errorOccurred}
              noRole={noRole}
              incompletenessMessage="Nie znaleziono potrzebnych danych na serwerze. Skontaktuj się z administratorem!"
              errorMessage="Wewnętrzny błąd serwera. Skontaktuj się z administratorem!"
              noRoleMessage="Brak udpowiednich uprawnień. Skontaktuj się z administratorem!"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default LocationList;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 *
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
