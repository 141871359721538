/**
 * Validates a NIP (Polish Tax Identification Number).
 * @param {string} nip - The NIP number to validate.
 * @returns {boolean} True if the NIP number is valid, false otherwise.
 */
export const validateNIP = (nip) => {
  const re = /^[0-9]{10}$/;
  if (!re.test(nip)) return false;

  const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  let sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(nip[i]) * weights[i];
  }

  const controlNumber = sum % 11;
  return parseInt(nip[9]) === controlNumber;
};

/**
 * Validates a REGON (Polish National Business Registry Number).
 * @param {string} regon - The REGON number to validate.
 * @returns {boolean} True if the REGON number is valid, false otherwise.
 */
export const validateREGON = (regon) => {
  return regon.length === 9 || regon.length === 14;
};

/**
 * Validates a Polish zip code.
 * @param {string} zipCode - The zip code to validate.
 * @returns {boolean} True if the zip code is valid, false otherwise.
 */
export const validateZipCode = (zipCode) => {
  const re = /^[0-9]{2}-[0-9]{3}$/;
  return re.test(zipCode);
};

/**
 * Validates an email address.
 * @param {string} email - The email address to validate.
 * @returns {boolean} True if the email address is valid, false otherwise.
 */
export const validateEmail = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

/**
 * Validates a password.
 * @param {string} password - The password to validate.
 * @returns {boolean} True if the password is valid, false otherwise.
 */
export const validatePassword = (password) => {
  const hasLowercase = /[a-z]/.test(password);
  const hasUppercase = /[A-Z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[\W_]/.test(password);
  const isLongEnough = password.length >= 8;

  return (
    hasLowercase && hasUppercase && hasNumber && hasSpecialChar && isLongEnough
  );
};

/**
 * Validates that a text is not empty.
 * @param {string} text - The text to validate.
 * @returns {boolean} True if the text is not empty, false otherwise.
 */
export const validateNotEmpty = (text) => {
  return text.trim().length > 0;
};

/**
 * Validates user input fields and triggers a callback with any validation errors.
 * @param {Object} props - The properties object.
 * @param {string} props.email - The email to validate.
 * @param {string} props.password - The password to validate.
 * @param {string} props.firstName - The first name to validate.
 * @param {string} props.lastName - The last name to validate.
 * @param {Function} props.onValidation - The callback function to be called with validation errors.
 * @returns {null} Returns null.
 */
export const Validation = ({
  email,
  password,
  firstName,
  lastName,
  companyName,
  nip,
  regon,
  address,
  streetNumber,
  localNumber,
  city,
  zipCode,
  onValidation,
}) => {
  const errors = [];

  if (!validateNotEmpty(firstName)) {
    errors.push("Pole powinno zostać uzupełnione.");
  }

  if (!validateNotEmpty(lastName)) {
    errors.push("Pole powinno zostać uzupełnione.");
  }

  if (!validateNotEmpty(companyName)) {
    errors.push("Pole powinno zostać uzupełnione.");
  }

  if (!validateNotEmpty(nip)) {
    errors.push("Pole powinno zostać uzupełnione.");
  } else if (!validateNIP(nip)) {
    errors.push("Nieprawidłowy numer NIP.");
  }

  if (!validateNotEmpty(regon)) {
    errors.push("Pole powinno zostać uzupełnione.");
  } else if (!validateREGON(regon)) {
    errors.push("Nieprawidłowy numer REGON.");
  }

  if (!validateNotEmpty(address)) {
    errors.push("Pole powinno zostać uzupełnione.");
  }

  if (!validateNotEmpty(streetNumber)) {
    errors.push("Pole powinno zostać uzupełnione.");
  }

  if (!validateNotEmpty(localNumber)) {
    errors.push("Pole powinno zostać uzupełnione.");
  }

  if (!validateNotEmpty(city)) {
    errors.push("Pole powinno zostać uzupełnione.");
  }

  if (!validateNotEmpty(zipCode)) {
    errors.push("Pole powinno zostać uzupełnione.");
  } else if (!validateZipCode(zipCode)) {
    errors.push("Nieprawidłowy kod pocztowy.");
  }

  if (!validateEmail(email)) {
    errors.push("Format adresu e-mail jest nieprawidłowy.");
  }

  if (!validatePassword(password)) {
    errors.push(
      "Hasło musi składać się z co najmniej 8 znaków i zawierać przynajmniej jedną małą literę, jedną wielką literę, jedną cyfrę oraz jeden znak specjalny."
    );
  }

  onValidation(errors);
  return null;
};

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
