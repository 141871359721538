import React from "react";
import "../../../style/Main.css";

/**
 * Renders a form for editing company details.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.company - The company object containing the company details.
 * @param {Function} props.handleChange - The function to handle input changes.
 * @param {Function} props.handleSubmit - The function to handle form submission.
 * @param {boolean} props.isEditable - Indicates whether the form is editable or not.
 * @param {Object} props.error - The error object containing validation errors.
 * @returns {JSX.Element} The rendered CompanyEditForm component.
 */
const CompanyEditForm = ({
  company,
  handleChange,
  handleSubmit,
  isEditable,
  error,
}) => {
  return (
    <form onSubmit={handleSubmit} className="grid__form">
      <div className="grid__item">
        <label>
          Imię:
          <input
            value={company.firstName}
            type="text"
            onChange={(e) => handleChange("firstName", e.target.value)}
            disabled={!isEditable}
          />
          {error.firstName && <div className="error">{error.firstName}</div>}
        </label>
        <label>
          Nazwisko:
          <input
            value={company.lastName}
            type="text"
            onChange={(e) => handleChange("lastName", e.target.value)}
            disabled={!isEditable}
          />
          {error.lastName && <div className="error">{error.lastName}</div>}
        </label>
        <label>
          Firma:
          <input
            value={company.companyName}
            type="text"
            onChange={(e) => handleChange("companyName", e.target.value)}
            disabled={!isEditable}
          />
          {error.company && <div className="error">{error.company}</div>}
        </label>
        <label>
          NIP:
          <input
            value={company.nip}
            type="text"
            onChange={(e) => handleChange("nip", e.target.value)}
            disabled={!isEditable}
          />
          {error.nip && <div className="error">{error.nip}</div>}
        </label>
        <label>
          REGON:
          <input
            value={company.regon}
            type="text"
            onChange={(e) => handleChange("regon", e.target.value)}
            disabled={!isEditable}
          />
          {error.regon && <div className="error">{error.regon}</div>}
        </label>
        <label>
          Address:
          <input
            value={company.address}
            type="text"
            onChange={(e) => handleChange("address", e.target.value)}
            disabled={!isEditable}
          />
          {error.address && <div className="error">{error.address}</div>}
        </label>
        <label>
          Street Number:
          <input
            value={company.streetNumber}
            type="text"
            onChange={(e) => handleChange("streetNumber", e.target.value)}
            disabled={!isEditable}
          />
          {error.streetNumber && (
            <div className="error">{error.streetNumber}</div>
          )}
        </label>
        <label>
          Local Number:
          <input
            value={company.localNumber}
            type="text"
            onChange={(e) => handleChange("localNumber", e.target.value)}
            disabled={!isEditable}
          />
          {error.localNumber && (
            <div className="error">{error.localNumber}</div>
          )}
        </label>
        <label>
          City:
          <input
            value={company.city}
            type="text"
            onChange={(e) => handleChange("city", e.target.value)}
            disabled={!isEditable}
          />
          {error.city && <div className="error">{error.city}</div>}
        </label>
        <label>
          Zip Code:
          <input
            value={company.zipCode}
            type="text"
            onChange={(e) => handleChange("zipCode", e.target.value)}
            disabled={!isEditable}
          />
          {error.zipCode && <div className="error">{error.zipCode}</div>}
        </label>
      </div>
      <button className="btn btn--update" type="submit" disabled={!isEditable}>
        Aktualizuj
      </button>
    </form>
  );
};

export default CompanyEditForm;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
