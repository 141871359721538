import React, { useContext, useState } from "react";
import AppStateContext from "../../../appState/AppStateContext";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router-dom";
import Message from "../../atoms/General/Message";
import Spinner from "../../atoms/General/Spinner";
import "../General/Form.css";

/**
 * Component for handling user logout process. Displays logout button, success or error message, and loading spinner.
 * @returns {JSX.Element} Logout component.
 */
const Logout = () => {
  const navigate = useNavigate();

  const { dispatch } = useContext(AppStateContext);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);

  /**
   * Submits logout request.
   */
  const submitData = async () => {
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/logout`,
        {},
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        setRegistrationSuccess(true);

        // Clear cookies upon successful logout
        [
          "refreshToken",
          "roles",
          "userSysID",
          "loggedIn",
          "firstName",
          "lastName",
          "profileImageURL",
        ].forEach((cookie) => {
          if (document.cookie.includes(cookie)) {
            document.cookie = `${cookie}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
          }
        });

        // Dispatch logout action and redirect to home page
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
    } catch (error) {
      setErrorOccurred(true);
      Sentry.captureException(error, {
        extra: {
          message: "Error when clicking log out",
          component: "LogoutForm",
        },
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  /**
   * Handles the logout process.
   * @param {Event} e - The click event.
   */
  const handleLogout = (e) => {
    e.preventDefault();
    submitData();
  };

  return (
    <div className="form__container">
      {!registrationSuccess && !errorOccurred && (
        <button onClick={handleLogout}>Logout</button>
      )}
      <Message
        success={registrationSuccess}
        error={errorOccurred}
        successMessage="Logout successful!"
        errorMessage="An error occurred during logout. Please contact the administrator!"
      />
      {isSubmitting && (
        <div className="spinner">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default Logout;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
