import React, { useState } from "react";
import "./Pagination.css";

/**
 * Pagination component for displaying a list of page numbers and handling pagination.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {number} props.itemsPerPage - The number of items to display per page.
 * @param {number} props.totalItems - The total number of items.
 * @param {Function} props.paginate - The function to call when a page number is clicked.
 * @returns {JSX.Element} The Pagination component.
 */
const Pagination = ({ itemsPerPage, totalItems, paginate }) => {
  // Creation of a local state
  const [activePage, setActivePage] = useState(1);

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  // Single click support
  const handleClick = (number) => {
    setActivePage(number);
    paginate(number);
  };

  return (
    <div className="pagination">
      <ul className="pagination__container">
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={`pagination__item ${
              activePage === number ? "active" : ""
            }`}
          >
            <button
              onClick={() => handleClick(number)}
              className="pagination__link"
            >
              {number}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Pagination;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
