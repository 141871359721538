import React, { useState, useEffect, useContext } from "react";
import DOMPurify from "dompurify";
import * as Sentry from "@sentry/react";
import AppStateContext from "../../../../../appState/AppStateContext";
import "./DeliveryForm.css";

/**
 * Represents a form for delivery information.
 * @param {Object} props - The component props.
 * @param {Function} props.markComplete - Function to mark the form as complete.
 * @param {Function} props.markUnComplete - Function to mark the form as incomplete.
 * @returns {JSX.Element} The rendered DeliveryForm component.
 */
const DeliveryForm = ({ markComplete, markUnComplete }) => {
  // Global state
  const { state, dispatch } = useContext(AppStateContext);
  //Local state
  const [cardClass, setCardClass] = useState(
    "btn__delivery--deactive btn__delivery"
  );
  const [localMarkComplete, setLocalMarkComplete] = useState(false);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    address: "",
    streetNumber: "",
    localNumber: "",
    city: "",
    zipCode: "",
  });

  //Setting set user data
  useEffect(() => {
    try {
      if (state.purchase.delivery !== null) {
        setUserData(state.purchase.delivery);
      }
      markUnComplete();
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          message: "Setting set user data",
          component: "DeliveryForm",
        },
      });
    }
  }, []);

  /**
   * Handles changes in input fields.
   * @param {Object} event - Input change event.
   */
  const handleChange = (event) => {
    const { name, value } = event.target;
    const sanitizedValue = DOMPurify.sanitize(value);
    setUserData((prevState) => ({
      ...prevState,
      [name]: sanitizedValue,
    }));
  };

  /**
   * Calculates the final price based on state.
   * @returns {Object} Final pricing details.
   */
  const calculatingTheFinalPrice = () => {
    const subscriptionFee = state.purchase.lengthLicense.value;
    const monthlyFee =
      state.purchase.lengthLicense.value /
      Number(state.purchase.lengthLicense.key);
    const additionsFee = state.purchase.additions.reduce(
      (acc, obj) => acc + obj.value,
      0
    );
    const oneTimeFee =
      state.purchase.implementation.value +
      state.purchase.size.value +
      additionsFee;
    const totalWithoutTax =
      state.purchase.lengthLicense.value +
      state.purchase.implementation.value +
      state.purchase.size.value +
      additionsFee;
    const taxRate = 0.23;
    const totalFeesWithoutTax = totalWithoutTax / (1 + taxRate);
    const totalFees =
      state.purchase.lengthLicense.value +
      state.purchase.implementation.value +
      state.purchase.size.value +
      additionsFee;
    return {
      subscriptionFee,
      monthlyFee,
      oneTimeFee,
      additionsFee,
      totalFeesWithoutTax,
      totalFees,
    };
  };

  /**
   * Handles form submission.
   * @param {Event} e - Form submission event.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!localMarkComplete) {
        setLocalMarkComplete(true);
        setCardClass("btn__delivery--active btn__delivery");
        markComplete();
      } else {
        setLocalMarkComplete(false);
        setCardClass("btn__delivery--deactive btn__delivery");
        markUnComplete();
      }

      dispatch({
        type: "UPDATE_PURCHASE",
        payload: {
          delivery: userData,
          sum: calculatingTheFinalPrice(),
        },
      });
    } catch (error) {
      Sentry.captureException(error, {
        extra: { message: "Błąd w funkcji handleSubmit." },
        component: "DeliveryForm",
        startLine: "108",
      });
    }
  };

  return (
    <div className="delivery">
      <form onSubmit={handleSubmit} className="deliveryForm">
        <div className="deliveryForm__container">
          <label>
            Imię:
            <input
              name="firstName"
              value={userData.firstName}
              type="text"
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Nazwisko:
            <input
              name="lastName"
              value={userData.lastName}
              type="text"
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Telefon:
            <input
              name="phoneNumber"
              value={userData.phoneNumber}
              type="text"
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Adres:
            <input
              name="address"
              value={userData.address}
              type="text"
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Numer ulicy:
            <input
              name="streetNumber"
              value={userData.streetNumber}
              type="text"
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Numer lokalu:
            <input
              name="localNumber"
              value={userData.localNumber}
              type="text"
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Miasto:
            <input
              name="city"
              value={userData.city}
              type="text"
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Kod pocztowy:
            <input
              name="zipCode"
              value={userData.zipCode}
              type="text"
              onChange={handleChange}
              required
            />
          </label>
        </div>
        <button className={cardClass} type="submit">
          Zapisz
        </button>
      </form>
    </div>
  );
};

export default DeliveryForm;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
