import React from "react";
import DesktopButtons from "../../atoms/Dashboard/Button/DesktopButton";
import MobileButtons from "../../atoms/Dashboard/Button/MobileButton";
import InfoMessage from "../../atoms/General/InfoMessage";
import MenuForm from "../molecules/MenuFormForCategory";

/**
 * Renders the Category Form Page.
 *
 * @returns {JSX.Element} The rendered Category Form Page component.
 */
const MenuFormPageForCategory = () => {
  return (
    <div className="license">
      <div className="message--info">
        <InfoMessage additionalInfo="W tym miejscu możesz dodać nową kategorię postępując zgodnie z poniższymi instrukcjami!" />
        <div>
          <DesktopButtons />
          <MobileButtons />
        </div>
      </div>
      <h1 className="license__h1">UTWÓRZ NOWY ELEMENT MENU</h1>
      <MenuForm />
    </div>
  );
};

export default MenuFormPageForCategory;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
