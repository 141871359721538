import React, { useState } from "react";
import InfoMessage from "../../atoms/General/InfoMessage";
import MobileButtons from "../../atoms/Dashboard/Button/MobileButton";
import DesktopButtons from "../../atoms/Dashboard/Button/DesktopButton";
import EmployeeItem from "../organisms/EmployeeItem";
import styles from "./Employee.module.css";

/**
 * Represents a page for displaying and editing employee details.
 * @component
 */
const EmployeeItemPage = () => {
  const [disabledBtn, setDisabledBtn] = useState(true);

  const toggleEditable = () => {
    if (disabledBtn) {
      setDisabledBtn(false);
    } else {
      setDisabledBtn(true);
    }
  };
  return (
    <div className={styles.employee}>
      <div className={styles.employee__btn}>
        <InfoMessage additionalInfo="W tym miejscu możesz zobaczyć szczegółowe dane danego pracownika oraz je edytować." />
        <div>
          <DesktopButtons
            action="updateLocalUser"
            toggleEditable={toggleEditable}
            isEditable={disabledBtn}
          />
          <MobileButtons
            action="updateLocalUser"
            toggleEditable={toggleEditable}
            isEditable={disabledBtn}
          />
        </div>
      </div>
      <EmployeeItem
        buttonAction={disabledBtn}
        toggleEditable={toggleEditable}
      />
    </div>
  );
};

export default EmployeeItemPage;

// - AUDIT
// - 25.06.2024
// - LOADING SUPPORT +
// - ERROR HANDLING +
// - SECURING INCOMING DATA +
// - SECURING OUTGOING DATA +
// - API CORRECTNESS +
// - CSS CORRECTNESS +
// - ACCESS TOKEN +
// - MOBILE 360PX +
// - BACKEND CONTROLER +
// - PASS`
