import React from "react";
import InfoMessage from "../../atoms/General/InfoMessage";
import MobileButtons from "../../atoms/Dashboard/Button/MobileButton";
import DesktopButtons from "../../atoms/Dashboard/Button/DesktopButton";
import ChangePasswordForm from "../organisms/ChangePasswordForm";
import styles from "./User.module.css";

/**
 * Renders the component for changing user password.
 * @returns {JSX.Element} The rendered ChangeUserPassword component.
 */
const ChangeUserPassword = () => {
  return (
    <div className={styles.user}>
      <div className={styles.user__btn}>
        <InfoMessage additionalInfo="W tym miejscu możesz zmienić swoje hasło. Pamiętaj że hasło powinno składać się z 8 znaków i zawierać: małą literę, dużą literę, cyfrę oraz znak specjalny." />
        <div>
          <DesktopButtons />
          <MobileButtons />
        </div>
      </div>
      <ChangePasswordForm />
    </div>
  );
};

export default ChangeUserPassword;

// - AUDIT
// - 25.06.2024
// - LOADING SUPPORT +
// - ERROR HANDLING +
// - SECURING INCOMING DATA +
// - SECURING OUTGOING DATA +
// - API CORRECTNESS +
// - CSS CORRECTNESS +
// - ACCESS TOKEN +
// - MOBILE 360PX +
// - BACKEND CONTROLER +
// - PASS
