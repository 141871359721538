import React, { useState } from "react";
import InfoMessage from "../../atoms/General/InfoMessage";
import MobileButtons from "../../atoms/Dashboard/Button/MobileButton";
import DesktopButtons from "../../atoms/Dashboard/Button/DesktopButton";
import LocationItem from "../organisms/LocationItem";


/**
 * Renders the LocationItemPage component.
 *
 * @returns {JSX.Element} The rendered LocationItemPage component.
 */
const LocationItemPage = () => {
  const [disabledBtn, setDisabledBtn] = useState(true);

  const toggleEditable = () => {
    if (disabledBtn) {
      setDisabledBtn(false);
    } else {
      setDisabledBtn(true);
    }
  };
  return (
    <div className="license">
      <div className="license__btn">
        <InfoMessage additionalInfo="W tym miejscu możesz zobaczyć szczegóły lokalizacji oraz edytować dane z nią związane." />
        <div>
          <DesktopButtons
            action="updateLocation"
            toggleEditable={toggleEditable}
            isEditable={disabledBtn}
          />
          <MobileButtons
            action="updateLocation"
            toggleEditable={toggleEditable}
            isEditable={disabledBtn}
          />
        </div>
      </div>
      <LocationItem
        buttonAction={disabledBtn}
        toggleEditable={toggleEditable}
      />
    </div>
  );
};

export default LocationItemPage;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 *
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
