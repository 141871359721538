import React from "react";
import { Link } from "react-router-dom";
import styles from "./MenuCard.module.css";

/**
 * Renders a card component for a menu item.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.menuItem - The menu item object.
 * @param {Function} props.handleDelete - The function to handle delete action.
 * @returns {JSX.Element} The rendered card component.
 */
const Card = ({ menuItem, handleDelete }) => {
  return (
    <div className={styles.card}>
      <h2>{menuItem.namePL}</h2>
      <div>
        <Link className={styles.link} to={`${menuItem.id}`}>
          <button className={styles.btn}>Szczegóły</button>
        </Link>
        <Link>
          <button
            className={styles.btn}
            onClick={() => handleDelete(menuItem.id)}
          >
            Usuń
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Card;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/