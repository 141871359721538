import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import * as Sentry from "@sentry/react";
import AppStateContext from "../../../../appState/AppStateContext";
import Message from "../../../atoms/General/Message";
import Spinner from "../../../atoms/General/Spinner";
import Pagination from "../../../atoms/General/Pagination";
import "./LicenseList.css";

/**
 * Renders a list of licenses.
 *
 * @returns {JSX.Element} The LicenseList component.
 */
const LicenseList = () => {
  // Navigate from React Router Dom
  const navigate = useNavigate();

  // Retrieving the global state of the application
  const { state, dispatch, cookieLoaded } = useContext(AppStateContext);

  // Creating local application state
  const [licensesList, setLicensesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingSpinner, setLoadingSpinner] = useState(true);
  const [incompletenessOccurred, setIncompletenessOccurred] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [noRole, setNoRole] = useState(false);

  // Variables for pagination
  const itemsPerPage = 3;

  // Getting the license list, returning an access token when the previous one has expired, setting the local state, error handling
  useEffect(() => {
    if (cookieLoaded) {
      const fetchLicensesList = async () => {
        try {
          let sysContractId = null;
          if (licensesList.length === 0) {
            const response = await axios.get(
              `${process.env.REACT_APP_API_URL}/contract/all/${state.userDetails.userSysID}}`,
              {
                headers: {
                  Authorization: `Bearer ${state.accessToken}`,
                  "Refresh-Token": state.userDetails.refreshToken,
                },
                withCredentials: true,
              }
            );
            if (
              response.data.accessToken &&
              response.data.accessToken !== state.accessToken
            ) {
              dispatch({
                type: "UPDATE_ACCESS_TOKEN",
                payload: response.data.accessToken,
              });
            }
            if (response.status === 200) {
              const data = await response.data.contracts;
              setLicensesList(data);
              setLoading(false);
              setLoadingSpinner(false);
            }
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            navigate("/automatic/logout");
          } else if (error.response && error.response.status === 403) {
            setLoadingSpinner(false);
            setLoading(false);
            setNoRole(true);
          } else if (error.response && error.response.status === 404) {
            setLoadingSpinner(false);
            setLoading(false);
            setIncompletenessOccurred(true);
          } else {
            setLoadingSpinner(false);
            setLoading(false);
            setErrorOccurred(true);
          }
          Sentry.captureException(error, {
            extra: {
              message: "Error retrieving company list",
              component: "LicenseList",
            },
          });
        }
      };

      fetchLicensesList();
    }
  }, [cookieLoaded, state.accessToken, state.refreshToken]);

  // Pagination for the license list
  const paginate = (pageNumber) => {
    if (!Array.isArray(licensesList)) return;
    setCurrentPage(pageNumber);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Array.isArray(licensesList)
    ? licensesList.slice(indexOfFirstItem, indexOfLastItem)
    : [];

  // Spinner view while loading data
  if (loading) {
    return (
      <div className="loading">
        <div className="loading__info">Ładowanie...</div>
        {loadingSpinner && (
          <div className="spinner">
            <Spinner />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="list">
      <h1 className="header__mobile">LISTA TWOICH LICENCJI</h1>
      <Message
        incompleteness={incompletenessOccurred}
        error={errorOccurred}
        noRole={noRole}
        incompletenessMessage="Przekazane dane są niekompletne. Skontaktuj się z administratorem!"
        errorMessage="Twoje licencje nie zostały pobrane prawidłowo. Skontaktuj się z administratorem!"
        noRoleMessage="Brak odpowiedniej roli. Skontaktuj się z administratorem!"
      />

      <ul className="list__container">
        {currentItems.map((item) => (
          <li className="list__item" key={item.id}>
            <div className="item__info">
              <div className={item.isActive ? "dot--green" : "dot--red"}></div>
              <h2>{item.licenseName}</h2>
            </div>
            <div>
              <Link
                to={`read/${item.id}/0d0ab4d8-20f5-4079-998c-d76e2cf194f8`}
                state={{ itemId: item.id }}
                key={item.id}
              >
                <button className="btn">Szczegóły</button>
              </Link>
            </div>
          </li>
        ))}
      </ul>
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={licensesList.length}
        paginate={paginate}
      />
    </div>
  );
};

export default LicenseList;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS
 * ACCESS TOKEN +
 *
 * MOBILE 360PX+
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
