import React from "react";
import LoginForm from "../../organisms/Home/LoginForm";

/**
 * Renders the Login component.
 * @returns {JSX.Element} The rendered Login component.
 */
const Login = () => (
  <>
    <LoginForm />
  </>
);

export default Login;

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
