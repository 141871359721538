import React from "react";
import { Link } from "react-router-dom";
import styles from "./Category.module.css";

/**
 * Renders a card component for a category.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.category - The category object.
 * @param {Function} props.handleDelete - The function to handle category deletion.
 * @returns {JSX.Element} The rendered card component.
 */
const Card = ({ category, handleDelete }) => {
  return (
    <div className={styles.card}>
      <h2>{category.namePL}</h2>
      <div>
        <Link className={styles.link} to={`${category.id}`}>
          <button className={styles.btn}>Szczegóły</button>
        </Link>
        <Link>
          <button
            className={styles.btn}
            onClick={() => handleDelete(category.id)}
          >
            Usuń
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Card;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 *
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
