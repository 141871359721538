import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Message from "../../atoms/General/Message";
import Spinner from "../../atoms/General/Spinner";

import styles from "./OrderSummary.module.css";

/**
 * OrderSummary component displays the summary of an order, including the list of products, total amount, and client information.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.order - The order object containing information about the products in the order.
 * @param {Function} props.handleQuantityChange - The function to handle quantity changes for a product in the order.
 * @param {Function} props.removeFromOrder - The function to remove a product from the order.
 * @param {Function} props.handleInputChange - The function to handle input changes for client information.
 * @param {Function} props.sendPaymentRequest - The function to send a payment request for the order.
 * @param {boolean} props.confirm - A boolean indicating whether the order has been confirmed.
 * @param {string} props.URLToPay - The URL to redirect the user for payment.
 * @param {Function} props.handleDisabledConfirm - The function to handle disabling the confirm button.
 * @param {boolean} props.disabledBtn - A boolean indicating whether the buttons in the component should be disabled.
 * @param {Function} props.handleDisabledBtn - The function to handle disabling the buttons in the component.
 * @param {string} props.language - The language.
 * @param {React.Ref} ref - The reference to the component.
 *
 * @returns {JSX.Element} The rendered OrderSummary component.
 */
const OrderSummary = React.forwardRef((props, ref) => {
  const {
    order,
    handleQuantityChange,
    removeFromOrder,
    handleInputChange,
    sendPaymentRequest,
    confirm,
    URLToPay,
    handleDisabledConfirm,
    disabledBtn,
    handleDisabledBtn,
    language,
    incompletenessOccurredSummary,
    errorOccurredSummary,
    isLoadingSummary,

    closeModal,
  } = props;

  /**
   * Toggles the visibility of the order.
   */
  const toggleOrderVisibility = () => {
    closeModal();
  };

  const [errors, setErrors] = useState({ clientName: "", clientSurname: "" });

  /**
   * Validates the fields in the order form.
   * @returns {boolean} Returns true if all fields are valid, otherwise false.
   */
  const validateFields = () => {
    let valid = true;
    const newErrors = { clientName: "", clientSurname: "" };

    if (!order.clientName) {
      language === "en"
        ? (newErrors.clientName = "Name is required.")
        : (newErrors.clientName = "Imię jest wymagane.");
      valid = false;
    }

    if (!order.clientSurname) {
      newErrors.clientSurname = "Nazwisko jest wymagane.";
      language === "en"
        ? (newErrors.clientSurname = "Last name is required.")
        : (newErrors.clientSurname = "Nazwisko jest wymagane.");

      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  /**
   * Handles the confirmation of the order.
   * If all fields are valid, it sends a payment request and disables the button.
   */
  const handleConfirmOrder = () => {
    if (order.totalAmount === 0) {
      return;
    }
    if (validateFields()) {
      sendPaymentRequest();
      handleDisabledBtn();
    }
  };

  return (
    <div className={styles.orderSummary} ref={ref}>
      <div className={styles.orderSummaryClose} onClick={toggleOrderVisibility}>
        <IoIosCloseCircleOutline />
      </div>
      <h3 className={styles.title}>
        {language === "en" ? "Your order" : "Twoje zamówienie"}
      </h3>
      {order.products.length === 0 ? (
        <p className={styles.emptyMessage}>
          {language === "en"
            ? "No products in the order."
            : "Brak produktów w zamówieniu."}
        </p>
      ) : (
        order.products.map((item) => (
          <div key={item.id} className={styles.orderItemSum}>
            <div className={styles.orderItemContainer}>
              <span className={styles.itemName}>{item.name}</span>
              <div className={styles.orderItemDetails}>
                <button
                  className={styles.quantityBtn}
                  onClick={() =>
                    handleQuantityChange(item.id, item.quantity - 1)
                  }
                  disabled={disabledBtn || item.quantity === 1}
                >
                  -
                </button>
                <span className={styles.quantity}>
                  <b>{item.quantity}</b>
                </span>
                <button
                  className={styles.quantityBtn}
                  onClick={() =>
                    handleQuantityChange(item.id, item.quantity + 1)
                  }
                  disabled={disabledBtn}
                >
                  +
                </button>
              </div>
            </div>
            <div className={styles.orderItemContainer}>
              <span className={styles.itemPrice}>PLN {item.totalPrice}</span>
              <button
                className={styles.removeBtn}
                onClick={() => removeFromOrder(item.id)}
                disabled={disabledBtn}
              >
                <FaTrash />
              </button>
            </div>
          </div>
        ))
      )}
      <h4 className={styles.totalAmount}>
        {" "}
        {language === "en" ? "Together:" : "Razem: "} PLN {order.totalAmount}
      </h4>

      <form className={styles.orderForm}>
        <div className={styles.formGroup}>
          <label htmlFor="clientName">
            {language === "en" ? "Name:" : "Imię:"}
          </label>
          <input
            type="text"
            id="clientName"
            name="clientName"
            value={order.clientName}
            onChange={handleInputChange}
            className={`${styles.fullWidthInput} ${
              errors.clientName ? styles.inputError : ""
            }`}
            disabled={disabledBtn}
          />
          {errors.clientName && (
            <span className={styles.error}>{errors.clientName}</span>
          )}
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="clientSurname">
            {" "}
            {language === "en" ? "Surname:" : "Nazwisko:"}
          </label>
          <input
            type="text"
            id="clientSurname"
            name="clientSurname"
            value={order.clientSurname}
            onChange={handleInputChange}
            className={`${styles.fullWidthInput} ${
              errors.clientSurname ? styles.inputError : ""
            }`}
            disabled={disabledBtn}
          />
          {errors.clientSurname && (
            <span className={styles.error}>{errors.clientSurname}</span>
          )}
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="description">
            {" "}
            {language === "en" ? "Comments to order:" : "Uwagi do zamówienia:"}
          </label>
          <input
            type="text"
            id="description"
            name="description"
            value={order.description}
            onChange={handleInputChange}
            className={styles.fullWidthInput}
            disabled={disabledBtn}
          />
        </div>
      </form>

      <div className={styles.buttonContainer}>
        {!confirm ? (
          <div className={styles.orderSummaryBtn}>
            <div className={styles.messageContainer}>
              <div className={styles.message}>
                <Message
                  incompleteness={incompletenessOccurredSummary}
                  error={errorOccurredSummary}
                  incompletenessMessage="Nie znaleziono potrzebnych danych na serwerze. Skontaktuj się z administratorem!"
                  errorMessage="Wewnętrzny błąd serwera. Skontaktuj się z administratorem!"
                />
              </div>
              {isLoadingSummary && <Spinner className={styles.spinner} />}
            </div>
            {!isLoadingSummary && (
              <button
                className={styles.confirmBtn}
                onClick={handleConfirmOrder}
                disabled={order.totalAmount === 0}
              >
                {language === "en" ? "Confirm order" : "Potwierdzam zamówienie"}
              </button>
            )}
          </div>
        ) : (
          <div className={styles.confirmationButtons}>
            <Link
              to={URLToPay}
              target="_blank"
              className={styles.fullWidthLink}
            >
              <button className={styles.paymentBtn}>
                {language === "en" ? "Pay" : "Zapłać"}
              </button>
            </Link>
            <button className={styles.editBtn} onClick={handleDisabledConfirm}>
              {language === "en" ? "Edit" : "Edytuj"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
});

export default OrderSummary;
