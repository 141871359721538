import React from "react";
import "../../style/Main.css";
import "./RefreshButton.css";

/**
 * A button component that refreshes the page when clicked.
 *
 * @returns {JSX.Element} The RefreshButton component.
 */
const RefreshButton = () => {
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <button className="btn btn--refresh btn--mobile" onClick={refreshPage}>
      Odśwież
    </button>
  );
};

export default RefreshButton;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
