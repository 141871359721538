import React from "react";
import PackageSelector from "../Card/PackageSelector";
import "./Step.css";

/**
 * Renders the fourth step of the dashboard stepper.
 * @param {Object} props - The component props.
 * @param {Array} props.productsData - The data for the products.
 * @param {Function} props.markComplete - The function to mark the step as complete.
 * @param {Function} props.markUnComplete - The function to mark the step as incomplete.
 * @returns {JSX.Element} The rendered fourth step component.
 */
const StepFourth = ({ productsData, markComplete, markUnComplete }) => {
  return (
    <div className="content">
      <h2 className="content__h2">DODATKI</h2>
      <p className="content__p">
        Wybierz z poniższych opcji dodatki do licencji.
      </p>
      <PackageSelector
        productsData={productsData}
        step="additions"
        markComplete={markComplete}
        markUnComplete={markUnComplete}
      />
    </div>
  );
};

export default StepFourth;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
