import React, { useState, useContext, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import AppStateContext from "../../../appState/AppStateContext";
import Navigation from "../../organisms/Dashboard/Navigation";
import Header from "../../organisms/Dashboard/Header";
import Main from "../../organisms/Dashboard/Main";
import LoginForm from "../Home/Login";
import Spinner from "../../atoms/General/Spinner";
import "./Dashboard.css";

/**
 * Represents the Dashboard component.
 * @component
 */
const Dashboard = () => {
  const { state, cookieLoaded } = useContext(AppStateContext);
  const [loading, setLoading] = useState(true);
  const [loadingSpinner, setLoadingSpinner] = useState(true);
  const [content, setContent] = useState(false);

  /**
   * Determines whether the settings are enabled for changing the width of the main section.
   * @type {boolean}
   */
  const isSettingsEnabled = state.settings.changingWidthMain;

  /**
   * Determines if the device screen is in mobile view.
   * @type {boolean}
   */
  const isMobile = useMediaQuery({ minWidth: 1000 });

  /**
   * CSS class for the main section based on mobile view and settings.
   * @type {string}
   */
  let mainClass;

  if (isMobile && isSettingsEnabled) {
    mainClass = "main__desktop--close";
  } else if (isMobile && !isSettingsEnabled) {
    mainClass = "main__desktop--open";
  }

  useEffect(() => {
    if (cookieLoaded) {
      if (state.userDetails.loggedIn) {
        setContent(true);
        setLoading(false);
        setLoadingSpinner(false);
      }
    }
  }, [cookieLoaded]);

  if (content) {
    return (
      <>
        {state.userDetails.loggedIn ? (
          <div className="dashboard">
            <div className="dashboard__container">
              <Header />
              <Navigation />
              <div className={mainClass}>
                <Main />
              </div>
            </div>
          </div>
        ) : (
          <LoginForm />
        )}{" "}
      </>
    );
  }

  return (
    <>
      {loading && (
        <div className="loading">
          <div className="loading__info">Ładowanie...</div>
          {loadingSpinner && (
            <div className="spinner">
              <Spinner />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Dashboard;

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
