import React, { useState } from "react";
import pl from "../../img/flags/poland.png";
import uk from "../../img/flags/united-kingdom.png";
import styles from "./FlagsComponent.module.css";

/**
 * FlagsComponent is a component that displays a language dropdown menu with flags.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.handleLanguage - The function to handle language selection.
 * @returns {JSX.Element} The rendered FlagsComponent.
 */
const FlagsComponent = ({ handleLanguage }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  /**
   * Toggles the dropdown state.
   */
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  /**
   * Selects a language and performs necessary actions.
   *
   * @param {string} language - The language to be selected.
   * @returns {void}
   */
  const selectLanguage = (language) => {
    handleLanguage(language);
    setDropdownOpen(false);
  };

  return (
    <div className={styles.languageDropdown}>
      <button onClick={toggleDropdown} className={styles.languageButton}>
        🌐
      </button>
      {dropdownOpen && (
        <div className={styles.dropdownMenu}>
          <button
            className={styles.buttonFlag}
            onClick={() => selectLanguage("pl")}
          >
            <img src={pl} alt="Language PL" className={styles.flag} />
          </button>
          <button
            className={styles.buttonFlag}
            onClick={() => selectLanguage("en")}
          >
            <img src={uk} alt="Language EN" className={styles.flag} />
          </button>
        </div>
      )}
    </div>
  );
};

export default FlagsComponent;
