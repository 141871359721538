import React, { useState } from "react";
import InfoMessage from "../../atoms/General/InfoMessage";
import MobileButtons from "../../atoms/Dashboard/Button/MobileButton";
import DesktopButtons from "../../atoms/Dashboard/Button/DesktopButton";
import CategoryList from "../organisms/CategoryList";

/**
 * Represents the Category component.
 * This component displays a list of categories based on the selected location.
 * It also provides functionality to create and edit categories.
 */
const Category = () => {
    const [selectedLocation, setSelectedLocation] = useState("");
    const [btnDisabled, setBtnDisabled] = useState(true);

    /**
     * Handles the selection of a location.
     * Updates the selected location state and enables the button.
     * @param {string} selected - The selected location.
     */
    const handleSelected = (selected) => {
        setSelectedLocation(selected);
        setBtnDisabled(false);
    };

    return (
        <div className="license">
            <div className="license__btn">
                <InfoMessage additionalInfo="Wystarczy że wybierzesz firmę oraz lokalizację a będzie można zobaczyć wszystkie kategorie. Każdą kategorie można edytować klikająć w przycisk szczegóły. Nową kategorie można utworzyć gdy zostanie wybrana firma i lokalizacja oraz kliknięty przycisk nowa." />
                <div>
                    <DesktopButtons
                        action="newCategory"
                        selectedCompany={selectedLocation}
                        btnStatus={btnDisabled}
                    />
                    <MobileButtons
                        action="newCategory"
                        selectedCompany={selectedLocation}
                        btnStatus={btnDisabled}
                    />
                </div>
            </div>
            <CategoryList selected={handleSelected} />
        </div>
    );
};

export default Category;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 *
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
