import React from "react";
import LicenseList from "../../organisms/Dashboard/License/LicenseList";
import InfoMessage from "../../atoms/General/InfoMessage";
import MobileButtons from "../../atoms/Dashboard/Button/MobileButton";
import DesktopButtons from "../../atoms/Dashboard/Button/DesktopButton";
import "../../organisms/Dashboard/Company/CompanyForm.css";
import "../../organisms/General/Form.css";
import "../../style/Main.css";
import "./License.css";

/**
 * Renders the License component.
 * This component displays a list of licenses and provides options to view details of each license and activate new licenses.
 *
 * @returns {JSX.Element} The rendered License component.
 */
const License = () => {
  return (
    <div className="license">
      <div className="license__btn">
        <InfoMessage additionalInfo="W tym miejscu znajdują się wszystkie Twoje licencje. Jeśli chcesz możesz zobaczyć szczegóły danej licencji. Licencja nieopłacona wygasa automatycznie aby ją ponownie aktywować opłać licencje." />
        <div>
          <DesktopButtons action="newLicense" />
          <MobileButtons action="newLicense" />
        </div>
      </div>
      <LicenseList />
    </div>
  );
};

export default License;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS
 * ACCESS TOKEN + 
 * 
 * MOBILE 360PX+
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
