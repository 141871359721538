import React, { useState, useEffect, useContext } from "react";
import * as Sentry from "@sentry/react";
import AppStateContext from "../../../../../appState/AppStateContext";
import Card from "./Card";
import DeliveryForm from "../Content/DeliveryForm";
import "./PackageSelector.css";

/**
 * Renders a package selector component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.productsData - The data for the products.
 * @param {string} props.step - The current step of the package selection process.
 * @param {Function} props.markComplete - Callback function to mark the step as complete.
 * @param {Function} props.markUnComplete - Callback function to mark the step as incomplete.
 * @returns {JSX.Element} The rendered package selector component.
 */
const PackageSelector = ({
  productsData,
  step,
  markComplete,
  markUnComplete,
}) => {
  // Set global state
  const { state, dispatch } = useContext(AppStateContext);

  // Set local state
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedAdditions, setSelectedAdditions] = useState([]);

  // Retrieving which option has been selected from the global state when a step changes and we want to return to a previously selected step
  useEffect(() => {
    const getAndSetState = async () => {
      try {
        if (state.purchase.lengthLicense && step === "lengthLicense") {
          setSelectedCard(state.purchase.lengthLicense.key);
        }
        if (state.purchase.implementation && step === "implementation") {
          setSelectedCard(state.purchase.implementation.key);
        }
        if (state.purchase.size && step === "size") {
          setSelectedCard(state.purchase.size.key);
        }
        if (state.purchase.additions && step === "additions") {
          setSelectedAdditions(state.purchase.additions);
          markComplete();
        }
      } catch (error) {
        Sentry.captureException(error, {
          extra: {
            message:
              "Error retrieving and/or setting status when clicking back or forward while purchasing a license.",
            component: "PackageSelector",
          },
        });
      }
    };
    getAndSetState();
  }, []);

  // Function called when clicking on a given option. The function removes data from the global state or writes data to the global state
  const handleCardClick = (title) => {
    if (step === "additions") {
      const updatedAdditions = [...selectedAdditions];
      const index = updatedAdditions.findIndex((item) => item.key === title);
      if (index !== -1) {
        updatedAdditions.splice(index, 1);
      } else {
        updatedAdditions.push({
          key: title,
          value: priceCalculation(title),
        });
      }
      setSelectedAdditions(updatedAdditions);
      dispatch({
        type: "UPDATE_PURCHASE",
        payload: {
          additions: updatedAdditions,
        },
      });
    } else {
      if (title === selectedCard) {
        if (
          selectedCard === "1" ||
          selectedCard === "3" ||
          selectedCard === "6" ||
          selectedCard === "12"
        ) {
          dispatch({
            type: "UPDATE_PURCHASE",
            payload: {
              lengthLicense: {
                key: "",
                value: 0,
              },
            },
          });
        } else if (
          selectedCard === "Samodzielna" ||
          selectedCard === "Elastyczna"
        ) {
          dispatch({
            type: "UPDATE_PURCHASE",
            payload: {
              implementation: {
                key: "",
                value: 0,
              },
            },
          });
        } else if (
          selectedCard === "Brak" ||
          selectedCard === "Start" ||
          selectedCard === "Start Plus" ||
          selectedCard === "Start Max"
        ) {
          dispatch({
            type: "UPDATE_PURCHASE",
            payload: {
              size: {
                key: "",
                value: 0,
              },
            },
          });
        } else if (
          selectedCard === "Wsparcie 24/7" ||
          selectedCard === "Chip NFC"
        ) {
          dispatch({
            type: "UPDATE_PURCHASE",
            payload: {
              additions: [],
            },
          });
        }
        markUnComplete();
        setSelectedCard(null);
      } else {
        setSelectedCard(title);
        markComplete();
      }
    }
  };

  // Function to find value based on shortDescription
  const findValueFromShortDesc = (shortDescription, type) => {
    // Find the element that matches the shortDescription
    if (Array.isArray(productsData) && productsData.length > 0) {
      let foundElement = productsData.find(
        (element) => element.shortDescription === shortDescription
      );
      // Check if an element was found, return value
      if (foundElement) {
        if (type === "discount") {
          return foundElement.discount;
        } else if (type === "shortDescription") {
          return foundElement.shortDescription;
        } else if (type === "tableFrom") {
          return foundElement.tableFrom;
        } else if (type === "tableTo") {
          return foundElement.tableTo;
        } else {
          return foundElement.sum;
        }
      } else {
        // If not found, return null or an appropriate message
        return null;
      }
    } else {
      return null;
    }
  };

  const priceCalculation = (selectedCard) => {
    switch (selectedCard) {
      case "1":
        return findValueFromShortDesc("1");
      case "3":
        return findValueFromShortDesc("3");
      case "6":
        return findValueFromShortDesc("6");
      case "12":
        return findValueFromShortDesc("12");
      case "Samodzielna":
        return findValueFromShortDesc("Samodzielna");
      case "Elastyczna":
        return findValueFromShortDesc("Elastyczna");
      case "Brak":
        return findValueFromShortDesc("Brak");
      case "Start":
        return findValueFromShortDesc("Start");
      case "Start Plus":
        return findValueFromShortDesc("Start Plus");
      case "Start Max":
        return findValueFromShortDesc("Start Max");
      case "Wsparcie 24/7":
        return findValueFromShortDesc("Wsparcie 24/7");
      case "Chip NFC":
        return findValueFromShortDesc("Chip NFC");
      default:
        return 0;
    }
  };

  // Global state update when the local state of the selectedCard changes
  useEffect(() => {
    const updateGlobalState = async () => {
      try {
        if (
          selectedCard === "1" ||
          selectedCard === "3" ||
          selectedCard === "6" ||
          selectedCard === "12"
        ) {
          dispatch({
            type: "UPDATE_PURCHASE",
            payload: {
              lengthLicense: {
                key: selectedCard,
                value: priceCalculation(selectedCard),
              },
            },
          });
        } else if (
          selectedCard === "Samodzielna" ||
          selectedCard === "Elastyczna"
        ) {
          dispatch({
            type: "UPDATE_PURCHASE",
            payload: {
              implementation: {
                key: selectedCard,
                value: priceCalculation(selectedCard),
              },
            },
          });
        } else if (
          selectedCard === "Brak" ||
          selectedCard === "Start" ||
          selectedCard === "Start Plus" ||
          selectedCard === "Start Max"
        ) {
          dispatch({
            type: "UPDATE_PURCHASE",
            payload: {
              size: {
                key: selectedCard,
                value: priceCalculation(selectedCard),
              },
            },
          });
        }
      } catch (error) {
        Sentry.captureException(error, {
          extra: {
            message: "Error updating global state when local state changes.",
            component: "PackageSelector",
          },
        });
      }
    };
    updateGlobalState();
  }, [selectedCard]);

  /**
   * Checks if a specific addition is selected.
   *
   * @param {string} title - The title of the addition to check.
   * @returns {boolean} - Returns true if the addition is selected, false otherwise.
   */
  const isAdditionSelected = (title) => {
    return selectedAdditions.some((addition) => addition.key === title);
  };

  // A function that renders individual cards depending on the step
  const getStepContent = (step) => {
    switch (step) {
      case "lengthLicense":
        return (
          <div className="content__column">
            <Card type="non-functional" title="Licencja" />
            <div className="card__mini">
              <Card
                type="mini"
                title="1"
                sum={findValueFromShortDesc("1")}
                duration={findValueFromShortDesc("1", "shortDescription")}
                discount={findValueFromShortDesc("1", "discount")}
                isSelected={selectedCard === "1"}
                onClick={() => handleCardClick("1")}
              />
              <Card
                type="mini"
                title="3"
                sum={findValueFromShortDesc("3")}
                duration={findValueFromShortDesc("3", "shortDescription")}
                discount={findValueFromShortDesc("3", "discount")}
                isSelected={selectedCard === "3"}
                onClick={() => handleCardClick("3")}
              />
              <Card
                type="mini"
                title="6"
                sum={findValueFromShortDesc("6")}
                duration={findValueFromShortDesc("6", "shortDescription")}
                discount={findValueFromShortDesc("6", "discount")}
                isSelected={selectedCard === "6"}
                onClick={() => handleCardClick("6")}
              />
              <Card
                type="mini"
                title="12"
                sum={findValueFromShortDesc("12")}
                duration={findValueFromShortDesc("12", "shortDescription")}
                discount={findValueFromShortDesc("12", "discount")}
                isSelected={selectedCard === "12"}
                onClick={() => handleCardClick("12")}
              />
            </div>
          </div>
        );
      case "implementation":
        return (
          <div className="content__column">
            {" "}
            <Card
              title="Samodzielna"
              sum={findValueFromShortDesc("Samodzielna")}
              discount={findValueFromShortDesc("Samodzielna", "discount")}
              isSelected={selectedCard === "Samodzielna"}
              onClick={() => handleCardClick("Samodzielna")}
            />
            <Card
              title="Elastyczna"
              sum={findValueFromShortDesc("Elastyczna")}
              discount={findValueFromShortDesc("Elastyczna", "discount")}
              isSelected={selectedCard === "Elastyczna"}
              onClick={() => handleCardClick("Elastyczna")}
            />
          </div>
        );
      case "size":
        return (
          <div className="content__column">
            <Card
              title="Brak"
              sum={findValueFromShortDesc("Brak")}
              discount={findValueFromShortDesc("Brak", "discount")}
              tableFrom={findValueFromShortDesc("Brak", "discount")}
              tableTo={findValueFromShortDesc("Brak", "discount")}
              isSelected={selectedCard === "Brak"}
              onClick={() => handleCardClick("Brak")}
            />
            <Card
              title="Start"
              sum={findValueFromShortDesc("Start")}
              discount={findValueFromShortDesc("Start", "discount")}
              tableFrom={findValueFromShortDesc("Start", "tableFrom")}
              tableTo={findValueFromShortDesc("Start", "tableTo")}
              isSelected={selectedCard === "Start"}
              onClick={() => handleCardClick("Start")}
            />
            <Card
              title="Start Plus"
              sum={findValueFromShortDesc("Start Plus")}
              discount={findValueFromShortDesc("Start Plus", "discount")}
              tableFrom={findValueFromShortDesc("Start Plus", "tableFrom")}
              tableTo={findValueFromShortDesc("Start Plus", "tableTo")}
              isSelected={selectedCard === "Start Plus"}
              onClick={() => handleCardClick("Start Plus")}
            />
            <Card
              title="Start Max"
              sum={findValueFromShortDesc("Start Max")}
              discount={findValueFromShortDesc("Start Max", "discount")}
              tableFrom={findValueFromShortDesc("Start Max", "tableFrom")}
              tableTo={findValueFromShortDesc("Start Max", "tableTo")}
              isSelected={selectedCard === "Start Max"}
              onClick={() => handleCardClick("Start Max")}
            />
          </div>
        );
      case "additions":
        return (
          <div className="content__column">
            <Card
              title="Wsparcie 24/7"
              sum={findValueFromShortDesc("Wsparcie 24/7")}
              discount={findValueFromShortDesc("Wsparcie 24/7", "discount")}
              isSelected={isAdditionSelected("Wsparcie 24/7")}
              onClick={() => handleCardClick("Wsparcie 24/7")}
            />
            <Card
              title="Chip NFC"
              sum={findValueFromShortDesc("Chip NFC")}
              discount={findValueFromShortDesc("Chip NFC", "discount")}
              isSelected={isAdditionSelected("Chip NFC")}
              onClick={() => handleCardClick("Chip NFC")}
            />
          </div>
        );
      case "delivery":
        return (
          <>
            <DeliveryForm
              markComplete={markComplete}
              markUnComplete={markUnComplete}
            />
          </>
        );
      case "summary":
        return (
          <>
            <Card
              type="summary"
              step="summary"
              title="Podsumowanie"
              purchase={state.purchase}
            />
          </>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <div className="packageSelector">
      <div className="packageSelector__container">{getStepContent(step)}</div>
    </div>
  );
};

export default PackageSelector;

/***
 * AUDIT
 * 03.06.2024
 *
 * Loading support - +
 * Error handling - +
 *
 * Securing incoming data - +
 * Secure outgoing data - +
 *
 * API correctness - +
 * CSS correct - +
 * Return AccessToken - +
 *
 * Overall correctness - +
 *
 * PASS
 ***/
