import React from "react";
import DesktopButtons from "../../atoms/Dashboard/Button/DesktopButton";
import MobileButtons from "../../atoms/Dashboard/Button/MobileButton";
import InfoMessage from "../../atoms/General/InfoMessage";
import CredentialForm from "../molecules/CredentialForm";

/**
 * Renders the Credential Form Page component.
 * This component displays a form for creating a new PayU credential.
 *
 * @returns {JSX.Element} The rendered Credential Form Page component.
 */
const CredentialFormPage = () => {
  return (
    <div className="license">
      <div className="message--info">
        <InfoMessage additionalInfo="W tym miejscu możesz dodać nowe poświadczenie PayU postępując zgodnie z poniższymi instrukcjami!" />
        <div>
          <DesktopButtons />
          <MobileButtons />
        </div>
      </div>
      <h1 className="license__h1">UTWÓRZ NOWE POŚWIADCZENIE PAYU</h1>
      <CredentialForm />
    </div>
  );
};

export default CredentialFormPage;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 *
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
