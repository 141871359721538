import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as Sentry from "@sentry/react";
import AppStateContext from "../../../appState/AppStateContext";
import Message from "../../atoms/General/Message";
import Spinner from "../../atoms/General/Spinner";
import Pagination from "../../atoms/General/Pagination";
import handleError from "../../../utils/HandleError";
import Card from "../molecules/CategoryCard";
import styles from "./CategoryList.module.css";

/**
 * Renders a list of categories based on the selected company and location.
 *
 * @component
 * @param {Object} selected - The selected category.
 * @returns {JSX.Element} The rendered CategoryList component.
 */
const CategoryList = ({ selected }) => {
  const navigate = useNavigate();
  const [hasRenderedCompany, setHasRenderedCompany] = useState(false);
  const [hasRenderedLocation, setHasRenderedLocation] = useState(false);

  const { state, dispatch, cookieLoaded } = useContext(AppStateContext);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [companies, setCompanies] = useState([]);
  const [locations, setLocations] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [successOccurred, setSuccessOccurred] = useState(false);
  const [noRole, setNoRole] = useState(false);
  const [incompletenessOccurred, setIncompletenessOccurred] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [categoriesPerPage] = useState(3);

  const [visibleLocations, setVisibleLocations] = useState(false);


  useEffect(() => {
    if (cookieLoaded) {
      const fetchCompanies = async () => {
        setSuccessOccurred(false);
        setNoRole(false);
        setIncompletenessOccurred(false);
        setErrorOccurred(false);
        setIsLoading(true);
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/company/all/${state.userDetails.userSysID}`,
            {
              headers: {
                Authorization: `Bearer ${state.accessToken}`,
                "Refresh-Token": state.userDetails.refreshToken,
              },
              withCredentials: true,
            }
          );

          if (
            response.data &&
            response.data.newAccessToken &&
            response.data.newAccessToken !== state.newAccessToken
          ) {
            dispatch({
              type: "UPDATE_ACCESS_TOKEN",
              payload: response.data.newAccessToken,
            });
          }

          if (
            response.status === 200 &&
            Array.isArray(response.data.companies)
          ) {
            setCompanies(response.data.companies);
            setNoRole(false);
            setIncompletenessOccurred(false);
            setErrorOccurred(false);
          }
        } catch (error) {
          Sentry.captureException(error, {
            extra: {
              message: "Error retrieving all companies connected to this user",
              component: "CategoryList",
            },
          });
          handleError(
            error,
            navigate,
            setNoRole,
            setIncompletenessOccurred,
            setErrorOccurred
          );
        } finally {
          setIsLoading(false);
        }
      };

      fetchCompanies();
    }
  }, [cookieLoaded]);

  const fetchLocations = async () => {
    setSuccessOccurred(false);
    setNoRole(false);
    setIncompletenessOccurred(false);
    setErrorOccurred(false);
    setLocations([]);
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/location/all/${selectedCompany}`,
        {
          headers: {
            Authorization: `Bearer ${state.accessToken}`,
            "Refresh-Token": state.userDetails.refreshToken,
          },
          withCredentials: true,
        }
      );

      if (
        response.data &&
        response.data.newAccessToken &&
        response.data.newAccessToken !== state.newAccessToken
      ) {
        dispatch({
          type: "UPDATE_ACCESS_TOKEN",
          payload: response.data.newAccessToken,
        });
      }

      if (response.status === 200 && Array.isArray(response.data.locations)) {
        setLocations(response.data.locations);
        setNoRole(false);
        setIncompletenessOccurred(false);
        setErrorOccurred(false);
      }
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          message: "Error retrieving all location connected to this user",
          component: "CategoryList",
        },
      });
      handleError(
        error,
        navigate,
        setNoRole,
        setIncompletenessOccurred,
        setErrorOccurred
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (hasRenderedCompany) {
      handleNextClickCompany();
    } else {
      setHasRenderedCompany(true);
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (hasRenderedLocation) {
      handleNextClickLocation();
    } else {
      setHasRenderedLocation(true);
    }
  }, [selectedLocation]);

  const fetchCategories = async () => {
    setSuccessOccurred(false);
    setNoRole(false);
    setIncompletenessOccurred(false);
    setErrorOccurred(false);
    setCategories([]);
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/category/all/${selectedLocation}`,
        {
          headers: {
            Authorization: `Bearer ${state.accessToken}`,
            "Refresh-Token": state.userDetails.refreshToken,
          },
          withCredentials: true,
        }
      );

      if (
        response.data &&
        response.data.newAccessToken &&
        response.data.newAccessToken !== state.newAccessToken
      ) {
        dispatch({
          type: "UPDATE_ACCESS_TOKEN",
          payload: response.data.newAccessToken,
        });
      }

      if (response.status === 200 && Array.isArray(response.data.categories)) {
        setCategories(response.data.categories);
        setNoRole(false);
        setIncompletenessOccurred(false);
        setErrorOccurred(false);
      }
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          message: "Error retrieving all categories connected to location",
          component: "CategoryList",
        },
      });
      handleError(
        error,
        navigate,
        setNoRole,
        setIncompletenessOccurred,
        setErrorOccurred
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (categoryID) => {
    try {
      setSuccessOccurred(false);
      setNoRole(false);
      setIncompletenessOccurred(false);
      setErrorOccurred(false);
      setIsLoading(true);
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/category/${categoryID}`,
        {
          headers: {
            Authorization: `Bearer ${state.accessToken}`,
            "Refresh-Token": state.userDetails.refreshToken,
          },
          withCredentials: true,
        }
      );

      if (
        response.data &&
        response.data.newAccessToken &&
        response.data.newAccessToken !== state.newAccessToken
      ) {
        dispatch({
          type: "UPDATE_ACCESS_TOKEN",
          payload: response.data.newAccessToken,
        });
      }

      if (response.status === 200) {
        setSuccessOccurred(true);
        fetchCategories();
      }
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          message: "Error deleting category",
          component: "CategoryList",
        },
      });
      handleError(
        error,
        navigate,
        setNoRole,
        setIncompletenessOccurred,
        setErrorOccurred
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
    setVisibleLocations(true)
  };

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
    selected(event.target.value);
  };

  const handleNextClickCompany = () => {
    fetchLocations();
  };

  const handleNextClickLocation = () => {
    fetchCategories();
  };

  const indexOfLastCategory = currentPage * categoriesPerPage;
  const indexOfFirstCategory = indexOfLastCategory - categoriesPerPage;
  const currentCategories = categories.slice(
    indexOfFirstCategory,
    indexOfLastCategory
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <h1 className={styles.title}>LISTA TWOICH KATEGORII</h1>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className={styles.content}>
          <div className={styles.company}>
            <h3>Wybierz firmę: </h3>
            <form className={styles.form}>
              <label className={styles.label}>
                <select
                  className={styles.mySelect}
                  value={selectedCompany}
                  onChange={handleCompanyChange}
                >
                  <option value="">Wybierz firmę</option>{" "}
                  {companies.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.companyName} - {company.address}
                    </option>
                  ))}
                </select>
              </label>
            </form>
          </div>
          {visibleLocations && (<div className={styles.location}>
            <h3> Wybierz lokalizację:</h3>
            <form className={styles.form}>
              <label className={styles.label}>
                <select
                  className={styles.mySelect}
                  value={selectedLocation}
                  onChange={handleLocationChange}
                >
                  <option value="">Wybierz lokalizację</option>{" "}
                  {locations.map((location) => (
                    <option key={location.id} value={location.id}>
                      {location.locationName}
                    </option>
                  ))}
                </select>
              </label>
            </form>
          </div>)}
          <div className={styles.message}>
            <Message
              success={successOccurred}
              incompleteness={incompletenessOccurred}
              error={errorOccurred}
              noRole={noRole}
              successMessage="Kategoria została usunięta!"
              incompletenessMessage="Nie znaleziono potrzebnych danych na serwerze. Skontaktuj się z administratorem!"
              errorMessage="Wewnętrzny błąd serwera. Skontaktuj się z administratorem!"
              noRoleMessage="Brak udpowiednich uprawnień. Skontaktuj się z administratorem!"
            />
          </div>
          <div className={styles.card}>
            {currentCategories.map((category) => (
              <Card
                key={category.id}
                category={category}
                handleDelete={handleDelete}
              />
            ))}
          </div>
          <Pagination
            itemsPerPage={categoriesPerPage}
            totalItems={categories.length}
            paginate={paginate}
          />
        </div>
      )}
    </div>
  );
};

export default CategoryList;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 *
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
