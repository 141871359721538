import React, { useState } from "react";
import InfoMessage from "../../atoms/General/InfoMessage";
import MobileButtons from "../../atoms/Dashboard/Button/MobileButton";
import DesktopButtons from "../../atoms/Dashboard/Button/DesktopButton";
import ProfileItem from "../organisms/Usertem";
import styles from "./User.module.css";

/**
 * Profile component displays user profile information and controls for editing.
 * @returns {JSX.Element} The rendered Profile component.
 */
const Profile = () => {
  const [disabledBtn, setDisabledBtn] = useState(true);

  const toggleEditable = () => {
    if (disabledBtn) {
      setDisabledBtn(false);
    } else {
      setDisabledBtn(true);
    }
  };

  return (
    <div className={styles.user}>
      <div className={styles.user__btn}>
        <InfoMessage additionalInfo="W tym miejscu możesz zobaczyć swój profil. Jeśli chcesz możesz edytować profil naciskając wcześniej przycisk obdlokuj. Ponadto możesz zmienić hasło naciskając przycisk hasło." />
        <div>
          <DesktopButtons
            action="readProfile"
            toggleEditable={toggleEditable}
            isEditable={disabledBtn}
          />
          <MobileButtons
            action="readProfile"
            toggleEditable={toggleEditable}
            isEditable={disabledBtn}
          />
        </div>
      </div>
      <ProfileItem buttonAction={disabledBtn} />
    </div>
  );
};

export default Profile;

// - AUDIT
// - 25.06.2024
// - LOADING SUPPORT +
// - ERROR HANDLING +
// - SECURING INCOMING DATA +
// - SECURING OUTGOING DATA +
// - API CORRECTNESS +
// - CSS CORRECTNESS +
// - ACCESS TOKEN +
// - MOBILE 360PX +
// - BACKEND CONTROLER +
// - PASS
