import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import AppStateContext from "../../../../appState/AppStateContext";
import Message from "../../../atoms/General/Message";
import Spinner from "../../../atoms/General/Spinner";
import Pagination from "../../../atoms/General/Pagination";
import "./CompanyList.css";
import "../../../organisms/General/Form.css";
import "../../../style/Main.css";

/**
 * Renders a list of companies with various functionalities such as activation/deactivation and pagination.
 *
 * @returns {JSX.Element} The rendered CompanyList component.
 */
const CompanyList = () => {
  const navigate = useNavigate();

  // Retrieving the global state of the application, action and cookieLoaded
  const { state, dispatch, cookieLoaded } = useContext(AppStateContext);

  // Creating local application state
  const [companiesList, setCompaniesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [incompletenessOccurred, setIncompletenessOccurred] = useState(false);
  const [warningOccurred, setWarningOccurred] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [requestGet, setRequestGet] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [noRole, setNoRole] = useState(false);
  const itemsPerPage = 3;

  // A function that handles item deactivation
  const handleDeactivation = async (id, isActive) => {
    setLoadingSpinner(true);
    try {
      const endpoint = isActive
        ? `${process.env.REACT_APP_API_URL}/company/deactivation/${id}`
        : `${process.env.REACT_APP_API_URL}/company/activation/${id}`;

      const response = await axios.patch(
        endpoint,
        {},
        {
          headers: {
            Authorization: `Bearer ${state.accessToken}`,
            "Refresh-Token": state.userDetails.refreshToken,
          },
          withCredentials: true,
        }
      );
      if (
        response.data.newAccessToken &&
        response.data.newAccessToken !== state.newAccessToken
      ) {
        dispatch({
          type: "UPDATE_ACCESS_TOKEN",
          payload: response.data.newAccessToken,
        });
      }
      if (response.status === 200) {
        setLoadingSpinner(false);
        setRegistrationSuccess(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/automatic/logout");
      } else if (error.response && error.response.status === 403) {
        setNoRole(true);
      } else if (error.response && error.response.status === 404) {
        setLoadingSpinner(false);
        setIncompletenessOccurred(true);
      } else {
        setLoadingSpinner(false);
        setErrorOccurred(true);
      }
      Sentry.captureException(error, {
        extra: {
          message: "Error during activation and deactivation",
          component: "CompanyList",
        },
      });
    }
  };

  useEffect(() => {
    setLoadingSpinner(true);
    setRequestGet(true);
    if (cookieLoaded) {
      const fetchCompaniesList = async () => {
        try {
          if (companiesList.length === 0) {
            const response = await axios.get(
              `${process.env.REACT_APP_API_URL}/company/all/${state.userDetails.userSysID}`,
              {
                headers: {
                  Authorization: `Bearer ${state.accessToken}`,
                  "Refresh-Token": state.userDetails.refreshToken,
                },
                withCredentials: true,
              }
            );
            if (
              response.data.newAccessToken &&
              response.data.newAccessToken !== state.newAccessToken
            ) {
              dispatch({
                type: "UPDATE_ACCESS_TOKEN",
                payload: response.data.newAccessToken,
              });
            }
            if (response.status === 200) {
              const data = await response.data.companies;
              setCompaniesList(data);
              setLoading(false);
              setLoadingSpinner(false);
              setRequestGet(false);
            }
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            navigate("/automatic/logout");
          } else if (error.response && error.response.status === 403) {
            setLoadingSpinner(false);
            setLoading(false);
            setNoRole(true);
          } else if (error.response && error.response.status === 404) {
            setLoadingSpinner(false);
            setLoading(false);
            setIncompletenessOccurred(true);
          } else {
            setLoadingSpinner(false);
            setLoading(false);
            setErrorOccurred(true);
          }
          Sentry.captureException(error, {
            extra: {
              message: "Error retrieving all companies connected to this user",
              component: "CompanyList",
            },
          });
        }
      };

      fetchCompaniesList();
    }
  }, [cookieLoaded, state.accessToken, state.refreshToken]);

  const paginate = (pageNumber) => {
    if (!Array.isArray(companiesList)) return; // Sprawdzenie czy companiesList jest tablicą
    setCurrentPage(pageNumber);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Array.isArray(companiesList)
    ? companiesList.slice(indexOfFirstItem, indexOfLastItem)
    : [];

  // The condition is displayed during loading
  if (loading) {
    return (
      <div className="loading">
        <div className="loading__info">Ładowanie...</div>
        {loadingSpinner && (
          <div className="spinner">
            <Spinner />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="list">
      <h1>LISTA TWOICH FIRM</h1>
      {requestGet ? (
        <Message
          success={registrationSuccess}
          incompleteness={incompletenessOccurred}
          warning={warningOccurred}
          error={errorOccurred}
          noRole={noRole}
          successMessage="Twoje firmy zostały pobrane prawidłowo!"
          warningMessage="Przekazane dane są niekompletne. Skontaktuj się z administratorem!"
          incompletenessMessage="Nie znaleziono firm spełniających kryteria. Skontaktuj się z administratorem!"
          errorMessage="Twoje firmy nie zostały pobrane prawidłowo. Skontaktuj się z administratorem!"
          noRoleMessage="Brak odpowiedniej roli. Skontaktuj się z administratorem!"
        />
      ) : (
        <Message
          success={registrationSuccess}
          incompleteness={incompletenessOccurred}
          error={errorOccurred}
          noRole={noRole}
          successMessage="Firma została zaktualizowana poprawnie!"
          incompletenessMessage="Firma nie została zaktualizowana poprawnie. Skontaktuj się z administratorem!"
          errorMessage="Firma nie została zaktualizowana poprawnie. Skontaktuj się z administratorem!"
          noRoleMessage="Brak odpowiedniej roli. Skontaktuj się z administratorem!"
        />
      )}
      <ul className="list__container">
        {currentItems.map((item) => (
          <li className="list__item" key={item.id}>
            <div className="item__info">
              <div className={item.isActive ? "dot--green" : "dot--red"}></div>
              <h2>{item.companyName}</h2>
            </div>
            <div>
              <Link
                to={`read/${item.id}`}
                state={{ itemId: item.id }}
                key={item.id}
              >
                <button className="btn">Edytuj</button>
              </Link>
              <button
                className="list__btn btn"
                onClick={() => handleDeactivation(item.id, item.isActive)}
              >
                {item.isActive ? "Dezaktywuj" : "Aktywuj"}
              </button>
            </div>
          </li>
        ))}
      </ul>
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={companiesList.length}
        paginate={paginate}
      />
    </div>
  );
};

export default CompanyList;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
