import React from "react";
import logo from "../../../img/React App_files/logo_white.png";
import styles from "./Footer.module.css";

/**
 * Footer component.
 *
 * @returns {JSX.Element} The rendered Footer component.
 */
function Footer() {
  return (
    <footer className={styles.footer}>
      <img src={logo} alt="Logo" className={styles.logo} />
      <div className={styles.copyright}>
        <p>Poland</p>
        <p>©️ coding_by_michael 2024</p>
      </div>
    </footer>
  );
}

export default Footer;
