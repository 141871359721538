import React, { useState, useContext } from "react";
import axios from "axios";
import * as Sentry from "@sentry/react";
import DOMPurify from "dompurify";
import { useParams, useNavigate } from "react-router-dom";
import AppStateContext from "../../../appState/AppStateContext";
import handleError from "../../../utils/HandleError";
import Message from "../../atoms/General/Message";
import Spinner from "../../atoms/General/Spinner";
import styles from "./CategoryForm.module.css";

/**
 * Renders a form for creating a new category.
 *
 * @returns {JSX.Element} The CategoryForm component.
 */
const CategoryForm = () => {
  const { selectedLocation } = useParams();

  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppStateContext);
  const [namePL, setNamePL] = useState("");
  const [descriptionPL, setDescriptionPL] = useState("");
  const [nameEN, setNameEN] = useState("");
  const [descriptionEN, setDescriptionEN] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [noRole, setNoRole] = useState(false);
  const [incompletenessOccurred, setIncompletenessOccurred] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);

  const handleChange = (event, setter) => {
    const sanitizedValue = DOMPurify.sanitize(event.target.value);
    setter(sanitizedValue);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setNoRole(false);
      setIncompletenessOccurred(false);
      setErrorOccurred(false);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/category/create/${selectedLocation}`,
        {
          namePL,
          descriptionPL,
          nameEN,
          descriptionEN,
        },
        {
          headers: {
            Authorization: `Bearer ${state.accessToken}`,
            "Refresh-Token": state.userDetails.refreshToken,
          },
          withCredentials: true,
        }
      );

      if (
        response.data &&
        response.data.newAccessToken &&
        response.data.newAccessToken !== state.newAccessToken
      ) {
        dispatch({
          type: "UPDATE_ACCESS_TOKEN",
          payload: response.data.newAccessToken,
        });
      }
      if (response.status === 200) {
        setSuccess(true);
        setNoRole(false);
        setIncompletenessOccurred(false);
        setErrorOccurred(false);
      }
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          message: "Problem creating a new category",
          component: "CategoryForm",
        },
      });
      handleError(
        error,
        navigate,
        setNoRole,
        setIncompletenessOccurred,
        setErrorOccurred
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className={styles.message}>
        <Message
          success={success}
          incompleteness={incompletenessOccurred}
          error={errorOccurred}
          noRole={noRole}
          successMessage="Kategoria została utworzon pomyślnie."
          incompletenessMessage="Nie znaleziono potrzebnych danych na serwerze. Skontaktuj się z administratorem!"
          errorMessage="Wewnętrzny błąd serwera. Skontaktuj się z administratorem!"
          noRoleMessage="Brak udpowiednich uprawnień. Skontaktuj się z administratorem!"
        />
      </div>

      {isLoading ? (
        <Spinner />
      ) : (
        <div className={styles.locationForm}>
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.columns}>
              <div className={styles.column}>
                <label>
                  Nazwa PL:
                  <input
                    type="text"
                    value={namePL}
                    onChange={(e) => handleChange(e, setNamePL)}
                  />
                </label>
                <label>
                  Opis PL:
                  <input
                    type="text"
                    value={descriptionPL}
                    onChange={(e) => handleChange(e, setDescriptionPL)}
                  />
                </label>
              </div>
              <div className={styles.column}>
                <label>
                  Nazwa EN:
                  <input
                    type="text"
                    value={nameEN}
                    onChange={(e) => handleChange(e, setNameEN)}
                  />
                </label>
                <label>
                  Opis EN:
                  <input
                    type="text"
                    value={descriptionEN}
                    onChange={(e) => handleChange(e, setDescriptionEN)}
                  />
                </label>
              </div>
            </div>
            <div className={styles.submitBtn}>
              <button type="submit">Utwórz</button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default CategoryForm;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 *
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
