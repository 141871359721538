import React, { useState } from "react";
import InfoMessage from "../../atoms/General/InfoMessage";
import MobileButtons from "../../atoms/Dashboard/Button/MobileButton";
import DesktopButtons from "../../atoms/Dashboard/Button/DesktopButton";
import MenuList from "../organisms/MenuList";

/**
 * Represents the Menu component.
 * @component
 */
const Menu = () => {
    const [selectedLocation, setSelectedLocation] = useState("");
    const [btnDisabled, setBtnDisabled] = useState(true);

    /**
     * Handles the selection of a location.
     * @param {string} selected - The selected location.
     */
    const handleSelected = (selected) => {
        setSelectedLocation(selected);
        setBtnDisabled(false);
    };

    return (
        <div className="license">
            <div className="license__btn">
                <InfoMessage additionalInfo="Wystarczy że wybierzesz firmę oraz lokalizację a będzie można zobaczyć wszystkie kategorie. Każdą kategorie można edytować klikająć w przycisk szczegóły. Nową kategorie można utworzyć gdy zostanie wybrana firma i lokalizacja oraz kliknięty przycisk nowa." />
                <div>
                    <DesktopButtons
                        action="newCategory"
                        selectedCompany={selectedLocation}
                        btnStatus={btnDisabled}
                    />
                    <MobileButtons
                        action="newCategory"
                        selectedCompany={selectedLocation}
                        btnStatus={btnDisabled}
                    />
                </div>
            </div>
            <MenuList selected={handleSelected} />
        </div>
    );
};

export default Menu;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/