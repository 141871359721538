import React from "react";
import RegisterForm from "../../organisms/Home/RegisterForm";

/**
 * Renders the Register component.
 * @returns {JSX.Element} The rendered Register component.
 */
const Register = () => (
  <>
    <RegisterForm />
  </>
);

export default Register;

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
