import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineLogout } from "react-icons/ai";
import { CiEdit, CiSettings } from "react-icons/ci";
import { RxPerson } from "react-icons/rx";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import ProfileButton from "./ProfileButton";
import analyzeString from "../../../../utils/AnalyzeString";
import "./ProfileOptions.css";

/**
 * Renders the profile options component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.isOpen - Flag indicating whether the profile options are open.
 * @param {Object} props.userDetails - The user details object.
 * @returns {JSX.Element|null} The profile options component.
 */
const ProfileOptions = ({ isOpen, userDetails }) =>
  isOpen && (
    <div className="profileOption">
      <div className="profileOption__container">
        <div className="profileOption__personal">
          {userDetails.profileImageURL && (
            <img
              className="profileOption__avatar"
              src={userDetails.profileImageURL}
              alt=""
            />
          )}
          <div className="profileOption__details">
            <span className="profileOption__details--name">
              {userDetails.firstName} {userDetails.lastName}
            </span>
            <span className="profileOption__details--roles">
              {analyzeString(userDetails.roles)}
            </span>
          </div>
        </div>
        <Link to="/automatic/logout">
          <AiOutlineLogout className="profileOption__logout" />
        </Link>
      </div>
      <ProfileButton
        icon={<CiSettings className="profileOption__person" />}
        text="Ustawienia"
      />
      <ProfileButton
        icon={<CiEdit className="profileOption__profile" />}
        text="Edytuj Profil"
      />
      <ProfileButton
        icon={<RxPerson className="profileOption__profile" />}
        text="Przeglądaj Profil"
      />
      <ProfileButton
        icon={<LiaFileInvoiceSolid className="profileOption__profile" />}
        text="Faktury"
      />

      <Link className="profileOption__button" to="/automatic/logout">
        <ProfileButton
          icon={<AiOutlineLogout className="profileOption__profile" />}
          text="Wyloguj się"
        />
      </Link>
    </div>
  );

export default ProfileOptions;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
