/**
 * @file NavigationWrapper.js
 * @description Wrapper component for navigation that decides whether to render the Navigation component based on the current URL path and the user's login state.
 */

import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import AppStateContext from "../appState/AppStateContext";
import Navigation from "../components/molecules/Home/Navigation";

/**
 * NavigationWrapper - Wrapper component for navigation.
 *
 * Renders the Navigation component only if the current URL path does not start with "/profile"
 * and the user is not logged in.
 *
 * @returns {JSX.Element|null} The Navigation component or null.
 */
const NavigationWrapper = () => {
  const { state } = useContext(AppStateContext);
  const location = useLocation();

  const shouldShowNavigation =
    !location.pathname.startsWith("/profile") && !state.userDetails.loggedIn;

  return shouldShowNavigation ? <Navigation /> : null;
};

export default NavigationWrapper;
