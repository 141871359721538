import React from "react";
import styles from "./Summary.module.css";

/**
 * Renders a summary component with information about a profile.
 *
 * @param {Object} props - The component props.
 * @param {string} props.descriptionPL - The description in Polish.
 * @param {string} props.descriptionEN - The description in English.
 * @param {string} props.address - The address.
 * @param {string} props.streetNumber - The street number.
 * @param {string} props.localNumber - The local number.
 * @param {string} props.city - The city.
 * @param {string} props.zipCode - The zip code.
 * @param {string} props.language - The language.
 * @param {Array} props.openingHoursPL - The opening hours in Polish.
 * @param {Array} props.openingHoursEN - The opening hours in English.
 * @returns {JSX.Element} The rendered summary component.
 */
const Summary = ({
  descriptionPL,
  descriptionEN,
  address,
  streetNumber,
  localNumber,
  city,
  zipCode,
  language,
  openingHoursPL,
  openingHoursEN,

}) => {
  
  return (
    <div className={styles.summaryContainer}>

      <div className={`${styles.column} ${styles.about}`}>
        <h3> {language === "en" ? "Abous Us" : "O nas"}</h3>
        <div className={styles.desc}>
          {language === "en" ? descriptionEN : descriptionPL}
        </div>
      </div>

      <div className={`${styles.column} ${styles.address}`}>
        <h3> {language === "en" ? "Address" : "Adres"}</h3>
        <div>
          {address} {streetNumber}/{localNumber}
        </div>
        <div>
          {zipCode} {city}
        </div>
      </div>
      
    </div>
  );
};

export default Summary;
