import React, { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import AppStateContext from "../../../appState/AppStateContext";
import "./AutomaticLogout.css";

/**
 * Component for automatic logout functionality.
 *
 * @returns {JSX.Element} The JSX element representing the AutomaticLogout component.
 */
const AutomaticLogout = () => {
  // Execution of an action in the application state
  const { dispatch, setCookieLoaded } = useContext(AppStateContext);

  // Deleting cookies
  useEffect(() => {
    [
      "refreshToken",
      "roles",
      "userSysID",
      "loggedIn",
      "firstName",
      "lastName",
      "profileImageURL",
    ].forEach((cookie) => {
      document.cookie = `${cookie}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });

    // Deleting application status
    try {
      setCookieLoaded(false);
      dispatch({ type: "LOGOUT" });
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          message: "Failed to dispatch logout or redirect:",
          component: "AutmaticLogout",
        },
      });
    }
  }, []);

  return (
    <div className="logout__automatic">
      Nastąpiło wylogowanie użytkownika. Możesz zalogować się powtórnie!
    </div>
  );
};

export default AutomaticLogout;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
