import React, { useState, useContext } from "react";
import axios from "axios";
import AppStateContext from "../../../../appState/AppStateContext";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import * as Sentry from "@sentry/react";
import {
  validateNotEmpty,
  validateNIP,
  validateREGON,
  validateZipCode,
} from "../../../../utils/Validation";
import Message from "../../../atoms/General/Message";
import Spinner from "../../../atoms/General/Spinner";
import InfoMessage from "../../../atoms/General/InfoMessage";
import DesktopButtons from "../../../atoms/Dashboard/Button/DesktopButton";
import MobileButtons from "../../../atoms/Dashboard/Button/MobileButton";
import "../../../organisms/General/Form.css";
import "./CompanyForm.css";

/**
 * Represents a form for creating a new company.
 *
 * @component
 */
const CompanyForm = () => {
  const navigate = useNavigate();

  // Creating local state or using state saved in the context
  const { state, dispatch } = useContext(AppStateContext);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    nip: "",
    regon: "",
    address: "",
    streetNumber: "",
    localNumber: "",
    city: "",
    zipCode: "",
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    nip: "",
    regon: "",
    address: "",
    streetNumber: "",
    localNumber: "",
    city: "",
    zipCode: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [incompletenessOccurred, setIncompletenessOccurred] = useState(false);
  const [noRole, setNoRole] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);

  // A function that handles changing the application state
  const handleChange = (name, value) => {
    // Sanitize the input value
    const sanitizedValue = DOMPurify.sanitize(value);

    setUserData((prevState) => ({
      ...prevState,
      [name]: sanitizedValue,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  // Validation of form fields
  const validateForm = () => {
    const newErrors = {};
    if (!validateNotEmpty(userData.firstName))
      newErrors.firstName = "Pole nie powinno być puste.";
    if (!validateNotEmpty(userData.lastName))
      newErrors.lastName = "Pole nie powinno być puste.";
    if (!validateNotEmpty(userData.companyName))
      newErrors.companyName = "Pole nie powinno być puste.";
    if (!validateNotEmpty(userData.nip))
      newErrors.nip = "Pole nie powinno być puste.";
    if (!validateNIP(userData.nip)) newErrors.nip = "Nieprawidłowy numer NIP.";
    if (!validateNotEmpty(userData.regon))
      newErrors.regon = "Pole nie powinno być puste.";
    if (!validateREGON(userData.regon))
      newErrors.regon = "Nieprawidłowy numer REGON.";
    if (!validateNotEmpty(userData.address))
      newErrors.address = "Pole nie powinno być puste.";
    if (!validateNotEmpty(userData.streetNumber))
      newErrors.streetNumber = "Pole nie powinno być puste.";
    if (!validateNotEmpty(userData.localNumber))
      newErrors.localNumber = "Pole nie powinno być puste.";
    if (!validateNotEmpty(userData.city))
      newErrors.city = "Pole nie powinno być puste.";
    if (!validateNotEmpty(userData.zipCode))
      newErrors.zipCode = "Pole nie powinno być puste.";
    if (!validateZipCode(userData.zipCode))
      newErrors.zipCode = "Nieprawidłowy kod pocztowy.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // A function that starts sending data to the server
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    submitData();
  };

  // A function that supports sending shares to the server to set up a new company
  const submitData = async () => {
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/company/create/${state.userDetails.userSysID}`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${state.accessToken}`,
            "Refresh-Token": state.userDetails.refreshToken,
          },
          withCredentials: true,
        }
      );
      if (
        response.data.newAccessToken &&
        response.data.newAccessToken !== state.newAccessToken
      ) {
        dispatch({
          type: "UPDATE_ACCESS_TOKEN",
          payload: response.data.newAccessToken,
        });
      }
      if (response.status === 200) {
        setRegistrationSuccess(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/automatic/logout");
      } else if (error.response && error.response.status === 403) {
        setNoRole(true);
      } else if (error.response && error.response.status === 404) {
        setIncompletenessOccurred(true);
      } else {
        setErrorOccurred(true);
      }
      Sentry.captureException(error, {
        extra: {
          message: "Error while creating the company",
          component: "CompanyForm",
        },
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="company">
      <div className="message--info">
        <InfoMessage additionalInfo="W tym miejscu możesz stworzyć firmę. Pamiętaj że należy uzupełnić wszystkie pola!" />
        <div>
          <DesktopButtons />
          <MobileButtons />
        </div>
      </div>
      <div className="company__tiltle">
        <h1>UTWÓRZ NOWĄ FIRMĘ</h1>
      </div>
      {!registrationSuccess &&
        !errorOccurred &&
        !incompletenessOccurred &&
        !noRole && (
          <div className="background--white">
            <form onSubmit={handleSubmit} className="company__form">
              <div className="form__create">
                <label>
                  Imię:
                  <input
                    value={userData.firstName}
                    type="text"
                    onChange={(e) => handleChange("firstName", e.target.value)}
                  />
                  {errors.firstName && (
                    <div className="error">{errors.firstName}</div>
                  )}
                </label>
                <label>
                  Nazwisko:
                  <input
                    value={userData.lastName}
                    type="text"
                    onChange={(e) => handleChange("lastName", e.target.value)}
                  />
                  {errors.lastName && (
                    <div className="error">{errors.lastName}</div>
                  )}
                </label>
                <label>
                  Firma:
                  <input
                    value={userData.companyName}
                    type="text"
                    onChange={(e) =>
                      handleChange("companyName", e.target.value)
                    }
                  />
                  {errors.companyName && (
                    <div className="error">{errors.companyName}</div>
                  )}
                </label>
                <label>
                  NIP:
                  <input
                    value={userData.nip}
                    type="text"
                    onChange={(e) => handleChange("nip", e.target.value)}
                  />
                  {errors.nip && <div className="error">{errors.nip}</div>}
                </label>
                <label>
                  REGON:
                  <input
                    value={userData.regon}
                    type="text"
                    onChange={(e) => handleChange("regon", e.target.value)}
                  />
                  {errors.regon && <div className="error">{errors.regon}</div>}
                </label>
                <label>
                  Adres:
                  <input
                    value={userData.address}
                    type="text"
                    onChange={(e) => handleChange("address", e.target.value)}
                  />
                  {errors.address && (
                    <div className="error">{errors.address}</div>
                  )}
                </label>
                <label>
                  Numer ulicy:
                  <input
                    value={userData.streetNumber}
                    type="text"
                    onChange={(e) =>
                      handleChange("streetNumber", e.target.value)
                    }
                  />
                  {errors.streetNumber && (
                    <div className="error">{errors.streetNumber}</div>
                  )}
                </label>
                <label>
                  Numer lokalu:
                  <input
                    value={userData.localNumber}
                    type="text"
                    onChange={(e) =>
                      handleChange("localNumber", e.target.value)
                    }
                  />
                  {errors.localNumber && (
                    <div className="error">{errors.localNumber}</div>
                  )}
                </label>
                <label>
                  Miasto:
                  <input
                    value={userData.city}
                    type="text"
                    onChange={(e) => handleChange("city", e.target.value)}
                  />
                  {errors.city && <div className="error">{errors.city}</div>}
                </label>
                <label>
                  Kod pocztowy:
                  <input
                    value={userData.zipCode}
                    type="text"
                    onChange={(e) => handleChange("zipCode", e.target.value)}
                  />
                  {errors.zipCode && (
                    <div className="error">{errors.zipCode}</div>
                  )}
                </label>
              </div>

              <button
                className="btn btn--new"
                type="submit"
                disabled={isSubmitting}
              >
                Utwórz firmę
              </button>
            </form>
          </div>
        )}
      <div className="message__container">
        <Message
          success={registrationSuccess}
          incompleteness={incompletenessOccurred}
          error={errorOccurred}
          noRole={noRole}
          successMessage="Firma utworzona poprawnie!"
          incompletenessMessage="Formularz niekompletny. Wszystkie pola powinny zostać uzupełnione!"
          errorMessage="Firma nie została utworzona. Skontaktuj się z administratorem!"
          noRoleMessage="Brak odpowiedniej roli. Skontaktuj się z administratorem!"
        />
      </div>
      {isSubmitting && (
        <div className="spinner">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default CompanyForm;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
