import React, { useContext, useState, useEffect } from "react";
import AppStateContext from "../../../appState/AppStateContext";
import { IoIosNotificationsOutline } from "react-icons/io";
import ProfileHeader from "../../molecules/Dashboard/Header/ProfileHeader";
import ProfileOptions from "../../molecules/Dashboard/Header/ProfileOptions";
import "./Header.css";

/**
 * Renders the header component.
 * @returns {JSX.Element} The header component.
 */
const Header = () => {
  const { state } = useContext(AppStateContext);
  const [isOpen, setIsOpen] = useState(false);

  /**
   * Toggles the profile options menu.
   */
  const toggleProfileOptions = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    /**
     * Closes the profile options menu when clicking outside.
     * @param {MouseEvent} event - The click event.
     */
    const handleClickOutside = (event) => {
      if (isOpen && !event.target.closest(".user__profile")) {
        setIsOpen(false);
      }
    };

    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="header">
      <div className="header__container">
        <div className="user__profile">
          <IoIosNotificationsOutline className="icon__profile--notification" />
          <ProfileHeader
            userDetails={state.userDetails}
            toggleProfileOptions={toggleProfileOptions}
          />
          <ProfileOptions isOpen={isOpen} userDetails={state.userDetails} />
        </div>
      </div>
    </div>
  );
};

export default Header;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
