import React from "react";
import "./ProfileButton.css";

/**
 * Renders a profile button component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.icon - The icon to be displayed.
 * @param {string} props.text - The text to be displayed.
 * @returns {JSX.Element} The rendered ProfileButton component.
 */
const ProfileButton = ({ icon, text }) => (
  <div className="profileButton">
    <span>{icon}</span>
    <span>{text}</span>
  </div>
);

export default ProfileButton;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
