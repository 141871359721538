import React from "react";
import { Link } from "react-router-dom";
import styles from "./LocationCard.module.css";

/**
 * Renders a card component for a location.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.location - The location object.
 * @returns {JSX.Element} The rendered card component.
 */
const Card = ({ location }) => (
  <div className={styles.card}>
    <h2>{location.locationName}</h2>
    <div>
      <Link to={`${location.id}`}>
        <button className="btn">Szczegóły</button>
      </Link>
    </div>
  </div>
);

export default Card;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 *
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
