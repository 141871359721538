import React from "react";
import InfoMessage from "../../atoms/General/InfoMessage";
import MobileButtons from "../../atoms/Dashboard/Button/MobileButton";
import DesktopButtons from "../../atoms/Dashboard/Button/DesktopButton";
import CredentialList from "../organisms/CredentialList";

/**
 * Renders the Credential component.
 * This component displays a list of credentials and provides options to view details of a specific credential or create a new credential.
 *
 * @returns {JSX.Element} The rendered Credential component.
 */
const Credential = () => {
  return (
    <div className="license">
      <div className="license__btn">
        <InfoMessage additionalInfo="W tym miejscu możesz zobaczyć wszystkie swoje poświadczenia PayU. Jeśli chcesz możesz zobaczyć szczegóły danego poświadczenia. Gdy chcesz utworzyć nowe poświadczenie kliknij przycisk 'Nowy'." />
        <div>
          <DesktopButtons action="newCredential" />
          <MobileButtons action="newCredential" />
        </div>
      </div>
      <CredentialList />
    </div>
  );
};

export default Credential;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 *
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/