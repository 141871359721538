/**
 * Handles errors based on the error response status.
 *
 * @param {Error} error - The error object.
 * @param {function} navigate - The navigate function from React Router.
 * @param {function} setNoRole - The state setter function for no role.
 * @param {function} setIncompletenessOccurred - The state setter function for incompleteness occurred.
 * @param {function} setErrorOccurred - The state setter function for error occurred.
 */
const handleError = (
  error,
  navigate,
  setNoRole,
  setIncompletenessOccurred,
  setErrorOccurred
) => {
  if (error.response) {
    switch (error.response.status) {
      case 401:
        navigate("/automatic/logout");
        break;
      case 403:
        setNoRole(true);
        break;
      case 404:
        setIncompletenessOccurred(true);
        break;
      default:
        setErrorOccurred(true);
        break;
    }
  } else {
    setErrorOccurred(true);
  }
};

export default handleError;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 *
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
