import React from "react";
import { useNavigate } from "react-router-dom";
import "../../style/Main.css";

/**
 * Represents a back button component.
 * @component
 */
const BackButton = () => {
  const navigate = useNavigate();

  /**
   * Navigates back to the previous page.
   */
  const goBack = () => {
    navigate(-1);
  };

  return (
    <button className="btn" onClick={goBack}>
      Powrót
    </button>
  );
};

export default BackButton;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
