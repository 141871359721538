import React, { useState } from "react";
import InfoMessage from "../../atoms/General/InfoMessage";
import MobileButtons from "../../atoms/Dashboard/Button/MobileButton";
import DesktopButtons from "../../atoms/Dashboard/Button/DesktopButton";
import LocationList from "../organisms/LocationList";

/**
 * Represents the Location component.
 * This component displays a list of locations and allows the user to select a company and create a new location.
 */
const Location = () => {
  const [selectedCompany, setSelectedCompany] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);

  /**
   * Handles the selection of a company.
   * @param {string} selected - The selected company.
   */
  const handleSelected = (selected) => {
    setSelectedCompany(selected);
    setBtnDisabled(false);
  };

  return (
    <div className="license">
      <div className="license__btn">
        <InfoMessage additionalInfo="W tym miejscu możesz zobaczyć wszystkie swoje lokalizacje. Jeśli chcesz możesz zobaczyć szczegóły danej lokalizacji. Gdy chcesz utworzyć nową lokalizację musisz wybrać firmę do której będzie ona należeć." />
        <div>
          <DesktopButtons
            action="newLocation"
            selectedCompany={selectedCompany}
            btnStatus={btnDisabled}
          />
          <MobileButtons
            action="newLocation"
            selectedCompany={selectedCompany}
            btnStatus={btnDisabled}
          />
        </div>
      </div>
      <LocationList selected={handleSelected} />
    </div>
  );
};

export default Location;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 *
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
