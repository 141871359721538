import React, { useState } from "react";
import InfoMessage from "../../atoms/General/InfoMessage";
import MobileButtons from "../../atoms/Dashboard/Button/MobileButton";
import DesktopButtons from "../../atoms/Dashboard/Button/DesktopButton";
import CategoryItem from "../organisms/CategoryItem";

/**
 * Renders the Category Item Page component.
 * This component displays the details of a category and allows editing of related data.
 *
 * @returns {JSX.Element} The rendered Category Item Page component.
 */
const CategoryItemPage = () => {
  const [disabledBtn, setDisabledBtn] = useState(true);

  const toggleEditable = () => {
    if (disabledBtn) {
      setDisabledBtn(false);
    } else {
      setDisabledBtn(true);
    }
  };
  return (
    <div className="license">
      <div className="license__btn">
        <InfoMessage additionalInfo="W tym miejscu możesz zobaczyć szczegóły kategorii oraz edytować dane z nią związane." />
        <div>
          <DesktopButtons
            action="updateCategory"
            toggleEditable={toggleEditable}
            isEditable={disabledBtn}
          />
          <MobileButtons
            action="updateCategory"
            toggleEditable={toggleEditable}
            isEditable={disabledBtn}
          />
        </div>
      </div>
      <CategoryItem
        buttonAction={disabledBtn}
        toggleEditable={toggleEditable}
      />
    </div>
  );
};

export default CategoryItemPage;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 *
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
