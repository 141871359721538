import React, { useReducer, useEffect, useState } from "react";
import AppStateContext from "./AppStateContext";
import initialState from "./initialState";
import reducer from "./reducer";

const AppStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [cookieLoaded, setCookieLoaded] = useState(false);

  useEffect(() => {
    const getCookie = async (name) => {
      return new Promise((resolve, reject) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
          resolve(parts.pop().split(";").shift());
        } else {
          reject(new Error("Cookie not found"));
        }
      });
    };

    const initializeUserDetails = async () => {
      try {
        const refreshToken = await getCookie("refreshToken");
        const userSysID = await getCookie("userSysID");
        const roles = await getCookie("roles");
        const loggedIn = await getCookie("loggedIn");
        const firstName = await getCookie("firstName");
        const lastName = await getCookie("lastName");
        const profileImageURL = await getCookie("profileImageURL");

        if (
          refreshToken &&
          userSysID &&
          roles &&
          loggedIn &&
          firstName &&
          lastName
        ) {
          dispatch({
            type: "UPDATE_USER",
            payload: {
              refreshToken,
              userSysID,
              roles,
              loggedIn,
              firstName,
              lastName,
              profileImageURL,
            },
          });
        }
        setCookieLoaded(true); // Set the flag indicating that cookies are loaded
      } catch (error) {
        console.error("Error initializing user details:", error);
      }
    };

    initializeUserDetails();
  }, []);

  useEffect(() => {
    const setCookie = (name, value, days) => {
      let expires = "";
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie =
        name + "=" + (value || "") + expires + "; path=/; SameSite=Lax";
    };

    if (state.userDetails.refreshToken) {
      setCookie("refreshToken", state.userDetails.refreshToken, 30);
      setCookie("userSysID", state.userDetails.userSysID, 30);
      setCookie("roles", state.userDetails.roles, 30);
      setCookie("loggedIn", state.userDetails.loggedIn, 30);
      setCookie("firstName", state.userDetails.firstName, 30);
      setCookie("lastName", state.userDetails.lastName, 30);
      setCookie("profileImageURL", state.userDetails.profileImageURL, 30);

      setCookieLoaded(true);
    }
  }, [state.userDetails]);


  return (
    <AppStateContext.Provider value={{ state, dispatch, cookieLoaded, setCookieLoaded }}>
      {children}
    </AppStateContext.Provider>
  );
};

export default AppStateProvider;
