import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { useParams, useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import handleError from "../../../utils/HandleError";
import AppStateContext from "../../../appState/AppStateContext";
import Message from "../../atoms/General/Message";
import Spinner from "../../atoms/General/Spinner";
import styles from "./LocationItem.module.css";

const LocationItem = ({ buttonAction, toggleEditable }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state, dispatch, cookieLoaded } = useContext(AppStateContext);

  const [locationDetails, setLocationDetails] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [credentials, setCredentials] = useState([]);
  const [licenses, setLicenses] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [file, setFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const [noRole, setNoRole] = useState(false);
  const [successOccurred, setSuccessOccurred] = useState(false);
  const [incompletenessOccurred, setIncompletenessOccurred] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (cookieLoaded) {
      const fetchLocationDetails = async () => {
        setIsLoading(true);
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/location/${id}`,
            {
              headers: {
                Authorization: `Bearer ${state.accessToken}`,
                "Refresh-Token": state.userDetails.refreshToken,
              },
              withCredentials: true,
            }
          );

          if (
            response.data &&
            response.data.newAccessToken &&
            response.data.newAccessToken !== state.newAccessToken
          ) {
            dispatch({
              type: "UPDATE_ACCESS_TOKEN",
              payload: response.data.newAccessToken,
            });
          }

          if (response.status === 200) {
            setLocationDetails(response.data.location);
            setFormValues(response.data.location);
            setInitialValues(response.data.location);
          }
        } catch (error) {
          Sentry.captureException(error, {
            extra: {
              message: "Error retrieving location.",
              component: "LocationItem",
            },
          });
          handleError(
            error,
            navigate,
            setNoRole,
            setIncompletenessOccurred,
            setErrorOccurred
          );
        } finally {
          setIsLoading(false);
        }
      };
      fetchLocationDetails();
    }
  }, [
    id,
    cookieLoaded,
    state.accessToken,
    state.userDetails.refreshToken,
    dispatch,
    navigate,
  ]);

  useEffect(() => {
    if (formValues.sysContractId){
      fetchContract(formValues.sysContractId);
    }
  }, [formValues]);

  const fetchContract = async (sysContractId) => { 
    if (cookieLoaded) {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/contract/all/used/${state.userDetails.userSysID}/${sysContractId}`,
          {
            headers: {
              Authorization: `Bearer ${state.accessToken}`,
              "Refresh-Token": state.userDetails.refreshToken,
            },
            withCredentials: true,
          },
        );

        if (
          response.data &&
          response.data.newAccessToken &&
          response.data.newAccessToken !== state.newAccessToken
        ) {
          dispatch({
            type: "UPDATE_ACCESS_TOKEN",
            payload: response.data.newAccessToken,
          });
        }

        if (response.status === 200 && Array.isArray(response.data.contracts)) {
          setLicenses(response.data.contracts);
          setNoRole(false);
          setIncompletenessOccurred(false);
          setErrorOccurred(false);
        }
      } catch (error) {
        Sentry.captureException(error, {
          extra: {
            message: "Error retrieving all credentials connected to this user",
            component: "LocationItem",
          },
        });
        handleError(
          error,
          navigate,
          setNoRole,
          setIncompletenessOccurred,
          setErrorOccurred
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (cookieLoaded) {
      const fetchPayUCredentials = async () => {
        setIsLoading(true);
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/credential/all/${state.userDetails.userSysID}`,
            {
              headers: {
                Authorization: `Bearer ${state.accessToken}`,
                "Refresh-Token": state.userDetails.refreshToken,
              },
              withCredentials: true,
            }
          );

          if (
            response.data &&
            response.data.newAccessToken &&
            response.data.newAccessToken !== state.newAccessToken
          ) {
            dispatch({
              type: "UPDATE_ACCESS_TOKEN",
              payload: response.data.newAccessToken,
            });
          }

          if (
            response.status === 200 &&
            Array.isArray(response.data.credentials)
          ) {
            setCredentials(response.data.credentials);
            setNoRole(false);
            setIncompletenessOccurred(false);
            setErrorOccurred(false);
          }
        } catch (error) {
          Sentry.captureException(error, {
            extra: {
              message:
                "Error retrieving all credentials connected to this user",
              component: "LocationItem",
            },
          });
          handleError(
            error,
            navigate,
            setNoRole,
            setIncompletenessOccurred,
            setErrorOccurred
          );
        } finally {
          setIsLoading(false);
        }
      };

      fetchPayUCredentials();
    }
  }, [
    cookieLoaded,
    state.accessToken,
    state.userDetails.refreshToken,
    state.userDetails.userSysID,
    dispatch,
    navigate,
  ]);

  const handleAvatarSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      setErrorMessage("Proszę wybrać plik.");
      return;
    }

    const formData = new FormData();
    formData.append("profileImage", file);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/aws/create/${formValues.id}/location`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${state.accessToken}`,
            "Refresh-Token": state.userDetails.refreshToken,
          },
          withCredentials: true,
        }
      );

      if (
        response.data &&
        response.data.newAccessToken &&
        response.data.newAccessToken !== state.newAccessToken
      ) {
        dispatch({
          type: "UPDATE_ACCESS_TOKEN",
          payload: response.data.newAccessToken,
        });
      }

      if (response.status === 200) {
        setSuccessOccurred(true);
        setNoRole(false);
        setIncompletenessOccurred(false);
        setErrorOccurred(false);
      }
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          message: "Error sending avatar profile",
          component: "ProfileItem",
        },
      });
      handleError(
        error,
        navigate,
        setNoRole,
        setIncompletenessOccurred,
        setErrorOccurred
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      alert("Nie wybrano pliku.");
      return;
    }

    const fileSizeLimit = 500 * 1024; // 500 KB
    const allowedTypes = ["image/jpeg", "image/png"];

    if (!allowedTypes.includes(selectedFile.type)) {
      setErrorMessage("Plik musi być w formacie JPG lub PNG.");
      return;
    }

    if (selectedFile.size > fileSizeLimit) {
      setErrorMessage("Plik nie może być większy niż 500 KB.");
      return;
    }

    setFile(selectedFile);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: DOMPurify.sanitize(value),
    });
  };

  const getChangedValues = () => {
    const changedValues = {};
    for (const key in formValues) {
      if (formValues[key] !== initialValues[key]) {
        changedValues[key] = formValues[key];
      }
    }
    return changedValues;
  };

  const handleSubmit = async () => {
    const changedValues = getChangedValues();
    if (Object.keys(changedValues).length === 0) {
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/location/${id}`,
        changedValues,
        {
          headers: {
            Authorization: `Bearer ${state.accessToken}`,
            "Refresh-Token": state.userDetails.refreshToken,
          },
          withCredentials: true,
        }
      );

      if (
        response.data &&
        response.data.newAccessToken &&
        response.data.newAccessToken !== state.newAccessToken
      ) {
        dispatch({
          type: "UPDATE_ACCESS_TOKEN",
          payload: response.data.newAccessToken,
        });
      }

      if (response.status === 200) {
        setSuccessOccurred(true);
      }
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          message: "Error updating location",
          component: "LocationItem",
        },
      });
      handleError(
        error,
        navigate,
        setNoRole,
        setIncompletenessOccurred,
        setErrorOccurred
      );
    } finally {
      toggleEditable();
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className={styles.avatarContainer}>
        <img
          className={styles.avatar}
          src={formValues.profileImageURL}
          alt=""
        />
        <form>
          <label className={styles.avatarLabel}>Wybierz logo:</label>
          <input
            className={styles.myInput}
            type="file"
            onChange={handleFileChange}
            disabled={buttonAction}
          />
          <p className={styles.errorMessage}>{errorMessage}</p>
          <div className={styles.avatarBtn}>
            <button onClick={handleAvatarSubmit} disabled={buttonAction}>
              Prześlij
            </button>
          </div>
        </form>
      </div>
      <Message
        success={successOccurred}
        incompleteness={incompletenessOccurred}
        error={errorOccurred}
        noRole={noRole}
        successMessage="Lokalizacja została zaktualizowana!"
        incompletenessMessage="Nie znaleziono potrzebnych danych na serwerze. Skontaktuj się z administratorem!"
        errorMessage="Wewnętrzny błąd serwera. Skontaktuj się z administratorem!"
        noRoleMessage="Brak udpowiednich uprawnień. Skontaktuj się z administratorem!"
      />
      {isLoading && <Spinner />}
      <div className={styles.locationForm}>
        {locationDetails && (
          <form className={styles.form}>
            <div className={styles.columns}>
              <div className={styles.column}>
                <div>
                  <label>ID</label>
                  <input
                    type="text"
                    value={formValues.id || ""}
                    readOnly
                    className={styles.myInput}
                  />
                </div>

                <div>
                  <label>Nazwa lokalizacji:</label>
                  <input
                    type="text"
                    value={formValues.locationName || ""}
                    name="locationName"
                    onChange={handleInputChange}
                    disabled={buttonAction}
                    className={styles.myInput}
                  />
                </div>

                <div>
                  <label>Numer domu:</label>
                  <input
                    type="text"
                    value={formValues.streetNumber || ""}
                    name="streetNumber"
                    onChange={handleInputChange}
                    disabled={buttonAction}
                    className={styles.myInput}
                  />
                </div>
                <div>
                  <label>Miasto:</label>
                  <input
                    type="text"
                    value={formValues.city || ""}
                    name="city"
                    onChange={handleInputChange}
                    disabled={buttonAction}
                    className={styles.myInput}
                  />
                </div>

                <label className={styles.label}>
                  <b>Poświadczenie PayU:</b>
                </label>

                <select
                  className={styles.mySelect}
                  value={formValues.sysPayuId || ""}
                  name="sysPayuId"
                  onChange={handleInputChange}
                  disabled={buttonAction}
                >
                  <option value="">Wybierz poświadczenie PayU</option>
                  {credentials.map((credential) => (
                    <option key={credential.id} value={credential.id}>
                      {credential.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="styles.column">
                <div>
                  <label>Lokalizacja aktywna:</label>
                  <input
                    type="text"
                    value={formValues.isActive ? "Tak" : "Nie"}
                    readOnly
                    className={styles.myInput}
                  />
                </div>
                <div>
                  <label>Adres:</label>
                  <input
                    type="text"
                    value={formValues.address || ""}
                    name="address"
                    onChange={handleInputChange}
                    disabled={buttonAction}
                    className={styles.myInput}
                  />
                </div>

                <div>
                  <label>Numer mieszkania:</label>
                  <input
                    type="text"
                    value={formValues.localNumber || ""}
                    name="localNumber"
                    onChange={handleInputChange}
                    disabled={buttonAction}
                    className={styles.myInput}
                  />
                </div>

                <div>
                  <label>Kod pocztowy:</label>
                  <input
                    type="text"
                    value={formValues.zipCode || ""}
                    name="zipCode"
                    onChange={handleInputChange}
                    disabled={buttonAction}
                    className={styles.myInput}
                  />
                </div>
                <label className={styles.label}>
                  <b>Licencja:</b>
                </label>

                <select
                  className={styles.mySelect}
                  value={formValues.sysContractId || ""}
                  name="sysContractId"
                  onChange={handleInputChange}
                  disabled={buttonAction}
                >
                  <option value="">Wybierz licencje</option>
                  {licenses.map((license) => (
                    <option key={license.id} value={license.id}>
                      {license.id} - {license.licenseName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className={styles.btn}>
              <button
                type="button"
                onClick={handleSubmit}
                disabled={buttonAction}
              >
                Aktualizuj
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default LocationItem;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 *
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
