import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { CiMenuKebab } from "react-icons/ci";
import RefreshButton from "../../General/RefreshButton";
import BackButton from "../../General/BackButton";
import "./MobileButton.css";
import "../../../style/Main.css";

/**
 * Renders a mobile button component with a pop-up menu.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.action - The action type for the button.
 * @param {function} props.modalActivation - The function to activate the modal.
 * @param {function} props.modalRenew - The function to renew the modal.
 * @param {boolean} props.modal - The modal state.
 * @param {boolean} props.isEditable - The editable state.
 * @param {function} props.toggleEditable - The function to toggle the editable state.
 * @param {boolean} props.isActive - The active state.
 * @returns {JSX.Element} The rendered MobileButtons component.
 */
const MobileButtons = ({
  action,
  modalActivation,
  modalRenew,
  modal,
  isEditable,
  toggleEditable,
  isActive,
  selectedCompany,
  btnStatus,
}) => {
  // Creating local application state
  const [showMenu, setShowMenu] = useState(false);

  // Calling useEffect depending on showMenu to open a pop-up menu, close a pop-up menu, and handle close-on-click anywhere in the view
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showMenu && !event.target.closest(".context__menu")) {
        setShowMenu(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showMenu]);
  const toggleMenu = (event) => {
    event.stopPropagation();
    setShowMenu((prev) => !prev);
  };

  return (
    <div className="actionMobileButton">
      <div className="actionMobileButton__container">
        <CiMenuKebab onClick={toggleMenu} className="icon icon__background" />
        {showMenu && (
          <div className="actionMobileButton__contextMenu">
            {action === "newCompany" ? (
              <div className="actionMobileButton__contextItemMenu">
                <Link to="create">
                  <button className="btn btn--mobile">Nowa firma</button>
                </Link>
              </div>
            ) : null}
            {action === "newLicense" ? (
              <div className="btn--mobile actionMobileButton__contextItemMenu">
                <Link to="create">
                  <button className="btn btn--mobile">Nowa</button>
                </Link>
              </div>
            ) : null}
            {action === "editCompany" ? (
              <div>
                <button className="btn btn--edit" onClick={toggleEditable}>
                  {isEditable ? "Zablokuj" : "Odblokuj"}
                </button>
              </div>
            ) : null}
            <div className="btn--mobile">
              {action === "license" ? (
                <button
                  className="btn btn--mobile"
                  disabled={isActive}
                  onClick={modalActivation}
                >
                  Aktywuj
                </button>
              ) : null}
            </div>
            <div className="btn--mobile">
              {action === "license" ? (
                <button className="btn" onClick={modalRenew}>
                  Opłać
                </button>
              ) : null}
            </div>
            <div className="btn--mobile">
              {action === "newLocation" && (
                <Link to={`create/${selectedCompany}`}>
                  <button className="btn" disabled={btnStatus}>
                    Nowa
                  </button>
                </Link>
              )}
            </div>
            <div className="btn--mobile">
              {action === "updateCategory" && (
                <Link to={`item`}>
                  <button className="btn" disabled={btnStatus}>
                    Nowa Kategoria
                  </button>
                </Link>
              )}
            </div>
            <div className="btn--mobile">
              {action === "updateCategory" ? (
                <button className="btn" onClick={toggleEditable}>
                  {isEditable ? "Odblokuj" : "Zablokuj"}
                </button>
              ) : null}
            </div>
            <div className="btn--mobile">
              {action === "updateLocation" ? (
                <button className="btn" onClick={toggleEditable}>
                  {isEditable ? "Odblokuj" : "Zablokuj"}
                </button>
              ) : null}
            </div>
            <div className="btn--mobile">
              {action === "newCategory" && (
                <Link to={`create/${selectedCompany}`}>
                  <button className="btn" disabled={btnStatus}>
                    Nowa
                  </button>
                </Link>
              )}
            </div>
            <div className="btn--mobile">
              {action === "updateCredential" ? (
                <button className="btn" onClick={toggleEditable}>
                  {isEditable ? "Odblokuj" : "Zablokuj"}
                </button>
              ) : null}
            </div>
            <div className="btn--mobile">
              {action === "newCredential" && (
                <Link to={`create`}>
                  <button className="btn" disabled={btnStatus}>
                    Nowa
                  </button>
                </Link>
              )}
            </div>
            <div className="btn--mobile">
              {action === "readProfile" && (
                <Link to={`password`}>
                  <button className="btn" disabled={btnStatus}>
                    Hasło
                  </button>
                </Link>
              )}
            </div>
            <div className="btn--mobile">
              {action === "readProfile" ? (
                <button className="btn" onClick={toggleEditable}>
                  {isEditable ? "Odblokuj" : "Zablokuj"}
                </button>
              ) : null}
            </div>
            <div className="btn--mobile">
              {action === "newEmployee" ? (
                <Link to={`create`}>
                  <button className="btn">Nowy</button>
                </Link>
              ) : null}
            </div>
            <div className="btn--mobile">
            {action === "updateLocalUser" ? (
          <button className="btn" onClick={toggleEditable}>
            {isEditable ? "Odblokuj" : "Zablokuj"}
          </button>
        ) : null}
        </div>
            <div className="btn--mobile">
              <RefreshButton />
            </div>
            <div>
              <BackButton />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileButtons;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER+
 *
 * PASS
 ***/
