import React from "react";
import { useParams } from "react-router-dom";
import LocationProfile from "../organisms/LocationProfile";

/**
 * Renders the profile page.
 *
 * @returns {JSX.Element} The profile page component.
 */
const Profile = () => {
  const { locationID, tableNumber } = useParams();
  return (
    <div>
      <LocationProfile locationID={locationID} tableNumber={tableNumber} />
    </div>
  );
};

export default Profile;
