import React, { useState, useEffect, useContext } from "react";
import * as Sentry from "@sentry/react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import AppStateContext from "../../../../appState/AppStateContext";
import StepFirst from "./Content/StepFirst";
import StepSecond from "./Content/StepSecond";
import StepThird from "./Content/StepThird";
import StepFourth from "./Content/StepFourth";
import StepFifth from "./Content/StepFifth";
import StepSixth from "./Content/StepSixth";
import Spinner from "../../../atoms/General/Spinner";
import Message from "../../../atoms/General/Message";
import "./HorizontalStepper.css";

/**
 * HorizontalStepper component represents a stepper with multiple steps in a horizontal layout.
 * It allows the user to navigate through the steps, mark them as complete or incomplete,
 * and generate a URL for payment.
 *
 * @returns {JSX.Element} The rendered HorizontalStepper component.
 */
const HorizontalStepper = () => {
  // React router dom
  const navigate = useNavigate();

  // Getting a global state
  const { state, dispatch } = useContext(AppStateContext);

  // Setting a local state
  const [activeStep, setActiveStep] = useState(0);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [URLToPay, setURLToPay] = useState("");
  const [stepCompleted, setStepCompleted] = useState(new Array(6).fill(false));
  const [productsData, setProductsData] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingSpinner, setLoadingSpinner] = useState(true);
  const [noRole, setNoRole] = useState(false);
  const [warningOccurred, setWarningOccurred] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);

  // Fetching product data
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/products`,
          {
            headers: {
              Authorization: `Bearer ${state.accessToken}`,
              "Refresh-Token": state.userDetails.refreshToken,
            },
            withCredentials: true,
          }
        );

        if (
          response.data.accessToken &&
          response.data.accessToken !== state.accessToken
        ) {
          dispatch({
            type: "UPDATE_ACCESS_TOKEN",
            payload: response.data.newAccessToken,
          });
        }

        if (
          response.status === 200 &&
          Array.isArray(response.data.products) &&
          response.data.products.length > 0
        ) {
          setLoading(false);
          setLoadingSpinner(false);
          setProductsData(response.data.products);
          setNoRole(false);
          setWarningOccurred(false);
          setErrorOccurred(false);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate("/automatic/logout");
        } else if (error.response && error.response.status === 403) {
          setLoadingSpinner(false);
          setLoading(false);
          setNoRole(true);
        } else if (error.response && error.response.status === 404) {
          setLoadingSpinner(false);
          setLoading(false);
          setWarningOccurred(true);
        } else {
          setLoadingSpinner(false);
          setLoading(false);
          setErrorOccurred(true);
        }

        Sentry.captureException(error, {
          extra: { message: "Getting product details",component: "HorizontalStepper" },
        });
      }
    };
    fetchProducts();
  }, []);

  // Function responsible for moving to the next step
  const handleNext = () => {
    if (activeStep < 5 && stepCompleted[activeStep]) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  // Function responsible for going back
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Function responsible for marking a step as complete
  const markStepComplete = (index) => {
    const newStepCompleted = [...stepCompleted];
    newStepCompleted[index] = true;
    setStepCompleted(newStepCompleted);
  };

  // Function responsible for marking a step as incomplete
  const markStepUnComplete = (index) => {
    const newStepCompleted = [...stepCompleted];
    newStepCompleted[index] = false;
    setStepCompleted(newStepCompleted);
  };

  // Function to generate URL to pay
  const generateURLToPay = (url) => {
    setURLToPay(url);
    setDisabledBtn(true);
  };

  // Function responsible for rendering the view depending on the step
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <StepFirst
            productsData={productsData}
            markComplete={() => markStepComplete(step)}
            markUnComplete={() => markStepUnComplete(step)}
          />
        );
      case 1:
        return (
          <StepSecond
            productsData={productsData}
            markComplete={() => markStepComplete(step)}
            markUnComplete={() => markStepUnComplete(step)}
          />
        );
      case 2:
        return (
          <StepThird
            productsData={productsData}
            markComplete={() => markStepComplete(step)}
            markUnComplete={() => markStepUnComplete(step)}
          />
        );
      case 3:
        return (
          <StepFourth
            productsData={productsData}
            markComplete={() => markStepComplete(step)}
            markUnComplete={() => markStepUnComplete(step)}
          />
        );
      case 4:
        return (
          <StepFifth
            productsData={productsData}
            markComplete={() => markStepComplete(step)}
            markUnComplete={() => markStepUnComplete(step)}
          />
        );
      case 5:
        return (
          <StepSixth
            productsData={productsData}
            markComplete={() => markStepComplete(step)}
            markUnComplete={() => markStepUnComplete(step)}
            generateURLToPay={generateURLToPay}
          />
        );
      default:
        return "Unknown step";
    }
  };

  // Spinner view while loading data
  if (loading) {
    return (
      <div className="loading">
        <div className="loading__info">Ładowanie...</div>
        {loadingSpinner && (
          <div className="spinner">
            <Spinner />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="horizontal__container">
      {!errorOccurred && !warningOccurred && !noRole && (
        <div className="horizontal__stepper">
          <div className="steps">
            {[1, 2, 3, 4, 5, 6].map((step, index) => (
              <div
                key={index}
                className={`step ${activeStep === index ? "active" : ""} ${
                  stepCompleted[index] ? "completed" : ""
                }`}
              >
                <div className="step-number">{step}</div>
              </div>
            ))}
          </div>
          <div>{getStepContent(activeStep)}</div>
          <div className="actions">
            {activeStep !== 0 && (
              <button
                className="btn"
                onClick={handleBack}
                disabled={disabledBtn}
              >
                Wstecz
              </button>
            )}
            {activeStep !== 5 ? (
              <button
                className="btn"
                onClick={handleNext}
                disabled={!stepCompleted[activeStep]}
              >
                Dalej
              </button>
            ) : (
              <Link to={URLToPay} target="_blank">
                <button className="btn" disabled={!stepCompleted[activeStep]}>
                  Opłać
                </button>
              </Link>
            )}
          </div>
        </div>
      )}
      <div className="message__container">
        <Message
          noRole={noRole}
          warning={warningOccurred}
          error={errorOccurred}
          noRoleMessage="Brak odpowiedniej roli. Skontaktuj się z administratorem!"
          warningMessage="Produkty nie zostały pobrane prawidłowo. Skontaktuj się z administratorem!"
          errorMessage="Połączenie z serwerem zakończone błędem. Skontaktuj się z administratorem!"
        />
      </div>
    </div>
  );
};

export default HorizontalStepper;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/