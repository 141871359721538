import React from "react";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import "./ToggleButtons.css";

/**
 * Renders the toggle buttons component.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.isClose - Indicates whether the toggle button is closed.
 * @param {function} props.setIsOpen - Callback function to set the isOpen state.
 * @param {function} props.handleOpenDesktop - Callback function to handle opening on desktop.
 * @returns {JSX.Element} The toggle buttons component.
 */
const ToggleButtons = ({ isClose, setIsOpen, handleOpenDesktop }) => {
  return (
    <div>
      <span className="toggleButton__mobile" onClick={() => setIsOpen(true)}>
        <AiOutlineMenuUnfold className="icon" />
      </span>
      {isClose && (
        <span className="toggleButton__desktop" onClick={handleOpenDesktop}>
          <AiOutlineMenuUnfold className="icon" />
        </span>
      )}
    </div>
  );
};

export default ToggleButtons;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 *
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER  +
 *
 * PASS
 ***/
