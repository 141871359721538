import React, { useContext, useState } from "react";
import axios from "axios";
import * as Sentry from "@sentry/react";
import DOMPurify from "dompurify";
import AppStateContext from "../../../appState/AppStateContext";
import { useNavigate } from "react-router-dom";
import Message from "../../atoms/General/Message";
import Spinner from "../../atoms/General/Spinner";
import "../General/Form.css";

/**
 * Login form component.
 * @returns {JSX.Element} The rendered login form.
 */
const LoginForm = () => {
  const navigate = useNavigate();

  const { dispatch } = useContext(AppStateContext);

  // State variables
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [incompletenessOccurred, setIncompletenessOccurred] = useState(false);
  const [warningOccurred, setWarningOccurred] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);

  /**
   * Handle input change.
   * @param {string} name - The name of the input field.
   * @param {string} value - The value of the input field.
   */
  const handleChange = (name, value) => {
    // Sanitize the value using DOMPurify
    const sanitizedValue = DOMPurify.sanitize(value);

    // Set the sanitized value in state
    setUserData((prevState) => ({
      ...prevState,
      [name]: sanitizedValue,
    }));
  };

  /**
   * Handle form submission.
   * @param {Event} e - The form submission event.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    submitData();
  };

  /**
   * Submit user data.
   */
  const submitData = async () => {
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        userData
      );

      if (response.status === 200) {
        dispatch({
          type: "UPDATE_USER",
          payload: {
            refreshToken: response.data.refreshToken,
            roles: response.data.roles,
            userSysID: response.data.userSysID,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            profileImageURL: response.data.profileImageURL,
            loggedIn: true,
          },
        });
        dispatch({
          type: "UPDATE_ACCESS_TOKEN",
          payload: response.data.accessToken,
        });
        navigate("/dashboard");
        setRegistrationSuccess(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setWarningOccurred(true);
      } else if (error.response && error.response.status === 404) {
        setIncompletenessOccurred(true);
      } else {
        setErrorOccurred(true);
      }
      Sentry.captureException(error, {
        extra: {
          message: "Error logging in",
          component: "LoginForm",
        },
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="form__container">
      <h2>LOGOWANIE</h2>
      {!registrationSuccess &&
        !errorOccurred &&
        !incompletenessOccurred &&
        !warningOccurred && (
          <form onSubmit={handleSubmit}>
            <label>
              Adres e-mail:
              <input
                value={userData.email}
                type="email"
                onChange={(e) => handleChange("email", e.target.value)}
              />
            </label>
            <label>
              Hasło:
              <input
                value={userData.password}
                type="password"
                onChange={(e) => handleChange("password", e.target.value)}
              />
            </label>
            <button type="submit" disabled={isSubmitting}>
              Zaloguj się
            </button>
          </form>
        )}
      <Message
        success={registrationSuccess}
        incompleteness={incompletenessOccurred}
        warning={warningOccurred}
        error={errorOccurred}
        successMessage="Logowanie udane!"
        incompletenessMessage="Niepoprawny adres e-mail lub hasło!"
        warningMessage="Konto powinno zostać aktywowane przed pierwszym logowaniem!"
        errorMessage="Wystąpił błąd podczas logowania. Proszę skontaktować się z administratorem!"
      />
      {isSubmitting && (
        <div className="spinner">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default LoginForm;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
