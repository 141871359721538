import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import * as Sentry from "@sentry/react";
import AppStateContext from "../../../../../appState/AppStateContext";
import Message from "../../../../atoms/General/Message";
import Spinner from "../../../../atoms/General/Spinner";
import "./Step.css";

/**
 * StepSeven component that renders the order summary, displaying whether the transaction was successful or not.
 *
 * @component
 * @example
 * return (
 *   <StepSeven />
 * )
 */
const StepSeven = () => {
  // React router dom
  const navigate = useNavigate();
  const { transactionID } = useParams();

  // Global state
  const { state, dispatch } = useContext(AppStateContext);

  // Local state
  const [statusSuccess, setStatusSuccess] = useState(false);
  const [incompletenessOccurred, setIncompletenessOccurred] = useState(false);
  const [noRole, setNoRole] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  /**
   * useEffect hook to retrieve the transaction status.
   *
   * @async
   * @function fetchStatus
   */
  useEffect(() => {
    setLoadingSpinner(true);
    const fetchStatus = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/pay/status/${transactionID}`,
          {
            headers: {
              Authorization: `Bearer ${state.accessToken}`,
              "Refresh-Token": state.userDetails.refreshToken,
            },
            withCredentials: true,
          }
        );

        if (
          response.data.accessToken &&
          response.data.accessToken !== state.accessToken
        ) {
          dispatch({
            type: "UPDATE_ACCESS_TOKEN",
            payload: response.data.newAccessToken,
          });
        }

        if (response.status === 200 && response.data.status === "success") {
          setStatusSuccess(true);
          setLoading(false);
          setLoadingSpinner(false);
        } else {
          setLoading(false);
          setLoadingSpinner(false);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate("/automatic/logout");
        } else if (error.response && error.response.status === 403) {
          setLoadingSpinner(false);
          setLoading(false);
          setNoRole(true);
        } else if (error.response && error.response.status === 404) {
          setLoadingSpinner(false);
          setLoading(false);
          setIncompletenessOccurred(true);
        } else {
          setLoadingSpinner(false);
          setLoading(false);
          setErrorOccurred(true);
        }

        Sentry.captureException(error, {
          extra: {
            message:
              "Checking the status of your transaction after making a purchase",
            component: "StepSeven",
          },
        });
      }
    };
    fetchStatus();
  }, []);

  return (
    <div className="finish">
      <div className="finish__message">
        <Message
          noRole={noRole}
          success={statusSuccess}
          incompleteness={incompletenessOccurred}
          error={errorOccurred}
          noRoleMessage="Brak odpowiedniej roli. Skontaktuj się z administratorem!"
          successMessage="Transakcja zakończona pomyślnie!"
          incompletenessMessage="Nie znaleziono ID transakcji. Skontaktuj się z administratorem!"
          errorMessage="Transakcja zakończona niepowodzeniem. Skontaktuj się z administratorem!"
        />
      </div>
      {!errorOccurred && !incompletenessOccurred && statusSuccess ? (
        <div>
          Transakcja zakończona pomyślnie. Dziękujemy za zakup subskrypcji,
          szczegóły otrzymasz za pośrednictwem poczty e-mail.
        </div>
      ) : (
        <div>
          Transakcja nie została zakończona pomyślnie. Prosimy powtórzyć zakup
          subskrypcji lub skontaktować się z administratorem systemu.
        </div>
      )}
      {loading && (
        <div className="loading">
          <div className="loading__info">Ładowanie...</div>
          {loadingSpinner && (
            <div className="spinner">
              <Spinner />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default StepSeven;

/***
 * AUDIT
 * 16.05.2024
 *
 * Loading support - +
 * Error handling - +
 *
 * Securing incoming data - +
 * Secure outgoing data - +
 *
 * API correctness - +
 * CSS correct - +
 * Return AccessToken - +
 *
 * Overall correctness - +
 *
 * PASS
 ***/
