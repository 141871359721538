import React, { useState } from "react";
import axios from "axios";
import * as Sentry from "@sentry/react";
import DOMPurify from "dompurify";
import {
  validateEmail,
  validatePassword,
  validateNotEmpty,
} from "../../../utils/Validation";
import Message from "../../atoms/General/Message";
import Spinner from "../../atoms/General/Spinner";
import "../General/Form.css";

/**
 * Register form component.
 * @returns {JSX.Element} The rendered register form.
 */
const RegisterForm = () => {
  // Creating local application state
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    statute: true,
    marketing: false,
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [incompletenessOccurred, setIncompletenessOccurred] = useState(false);
  const [warningOccurred, setWarningOccurred] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);

  /**
   * Handle input change.
   * @param {string} name - The name of the input field.
   * @param {string | boolean} value - The value of the input field.
   */
  const handleChange = (name, value) => {
    // Sanitize the value
    const sanitizedValue = DOMPurify.sanitize(value);

    setUserData((prevState) => ({
      ...prevState,
      [name]: sanitizedValue,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  /**
   * Validate form fields.
   * @returns {boolean} Indicates if the form is valid.
   */
  const validateForm = () => {
    const newErrors = {};
    if (!validateNotEmpty(userData.firstName))
      newErrors.firstName = "Field should not be empty.";
    if (!validateNotEmpty(userData.lastName))
      newErrors.lastName = "Field should not be empty.";
    if (!validateEmail(userData.email))
      newErrors.email = "Invalid email format.";
    if (!validatePassword(userData.password))
      newErrors.password =
        "Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one digit, and one special character.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  /**
   * Handle form submission.
   * @param {Event} e - The form submission event.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    submitData();
  };

  /**
   * Submit user registration data.
   */
  const submitData = async () => {
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/register`,
        userData
      );
      if (response.status === 201) {
        setRegistrationSuccess(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setWarningOccurred(true);
      } else if (error.response && error.response.status === 404) {
        setIncompletenessOccurred(true);
      } else {
        setErrorOccurred(true);
      }
      Sentry.captureException(error, {
        extra: {
          message: "Error during registration",
          component: "RegisterForm",
        },
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="form__container">
      <h2>ZAREJESTRUJ</h2>
      {!registrationSuccess &&
        !errorOccurred &&
        !incompletenessOccurred &&
        !warningOccurred && (
          <form onSubmit={handleSubmit}>
            <label>
              Imię:
              <input
                value={userData.firstName}
                type="text"
                onChange={(e) => handleChange("firstName", e.target.value)}
              />
              {errors.firstName && (
                <div className="error">{errors.firstName}</div>
              )}
            </label>
            <label>
              Nazwisko:
              <input
                value={userData.lastName}
                type="text"
                onChange={(e) => handleChange("lastName", e.target.value)}
              />
              {errors.lastName && (
                <div className="error">{errors.lastName}</div>
              )}
            </label>
            <label>
              Adres e-mail:
              <input
                value={userData.email}
                type="email"
                onChange={(e) => handleChange("email", e.target.value)}
              />
              {errors.email && <div className="error">{errors.email}</div>}
            </label>
            <label>
              Hasło:
              <input
                value={userData.password}
                type="password"
                onChange={(e) => handleChange("password", e.target.value)}
              />
              {errors.password && (
                <div className="error">{errors.password}</div>
              )}
            </label>
            <label className="checkbox">
              <input
                type="checkbox"
                name="statute"
                checked={userData.statute}
                onChange={(e) => handleChange(e.target.name, e.target.checked)}
                required
              />
              Akceptuję zasady i warunki.
            </label>
            <label className="checkbox">
              <input
                type="checkbox"
                name="marketing"
                checked={userData.marketing}
                onChange={(e) => handleChange(e.target.name, e.target.checked)}
              />
              Chcę otrzymywać informacje marketingowe.
            </label>
            <button type="submit" disabled={isSubmitting}>
              Utwórz konto
            </button>
          </form>
        )}
      <Message
        success={registrationSuccess}
        incompleteness={incompletenessOccurred}
        warning={warningOccurred}
        error={errorOccurred}
        successMessage="Rejestracja udana!"
        incompletenessMessage="Niepełna rejestracja. Nie wszystkie wymagane pola zostały wypełnione!"
        warningMessage="Adres e-mail jest już używany. Proszę użyć innego adresu e-mail!"
        errorMessage="Wystąpił błąd podczas rejestracji. Proszę skontaktować się z administratorem!"
      />
      {isSubmitting && (
        <div className="spinner">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default RegisterForm;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
