/**
 * Initial state object for the application.
 * This object defines the initial values for different parts of the application state,
 * including access token, user details, and settings.
 *
 * @typedef {Object} InitialState
 * @property {string} accessToken - The access token used for authentication.
 * @property {string|Object} userDetails - Details of the logged-in user.
 * @property {string|Object} settings - Application settings.
 */

/**
 * Initial state object for the application.
 *
 * @type {InitialState}
 */
const initialState = {
  accessToken: "", // Initial access token is empty
  userDetails: "", // Initial user details are empty
  settings: "", // Initial settings are empty
  purchase: {
    delivery: {},
    lengthLicense: {
      key: "",
      value: 0,
    },
    implementation: {
      key: "",
      value: 0,
    },
    size: {
      key: "",
      value: 0,
    },
    additions: [],
    sum: {}
  }, // Initial purchase
  language: "pl"
};

export default initialState;
