import React from "react";
import Card from "../molecules/Card";
import styles from "./LocationCategory.module.css";

/**
 * Renders a list of location categories with their associated menu items.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.location - The location object containing categories and menu items.
 * @param {Array} props.location.categories - The array of category objects.
 * @param {string} props.location.categories[].id - The unique identifier for the category.
 * @param {string} props.location.categories[].nameEN - The English name of the category.
 * @param {string} props.location.categories[].namePL - The Polish name of the category.
 * @param {string} props.location.categories[].descriptionEN - The English description of the category.
 * @param {string} props.location.categories[].descriptionPL - The Polish description of the category.
 * @param {Function} props.openModal - The function to open a modal.
 * @param {string} props.language - The current language ("en" or "pl").
 * @returns {JSX.Element} The rendered LocationCategories component.
 */
const LocationCategories = ({ location, openModal, language }) => {
  return (
    <div className={styles.content}>
      {location.categories &&
        location.categories.map((category) => (
          <div key={category.id} id={category.id} className={styles.category}>
            <h1>{language === "en" ? category.nameEN : category.namePL}</h1>
            <p>
              {language === "en"
                ? category.desriptionEN
                : category.descriptionPL}
            </p>
            <div className={styles.cardContainer}>
              {category.menuItems &&
                category.menuItems.map((menuItem) => (
                  <Card
                    key={menuItem.id}
                    menuItem={menuItem}
                    openModal={openModal}
                    language={language}
                  />
                ))}
            </div>
          </div>
        ))}
    </div>
  );
};

export default LocationCategories;
