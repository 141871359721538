import React from "react";
import PackageSelector from "../Card/PackageSelector";
import "./Step.css";


/**
 * StepFirst component displays the first step of a stepper in the dashboard.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.productsData - The data for the products.
 * @param {Function} props.markComplete - The function to mark the step as complete.
 * @param {Function} props.markUnComplete - The function to mark the step as incomplete.
 * @returns {JSX.Element} The rendered StepFirst component.
 */
const StepFirst = ({ productsData, markComplete, markUnComplete }) => {
  return (
    <div className="content">
      <h2 className="content__h2">DŁUGOŚĆ LICENCJI</h2>
      <p className="content__p">
        Wybierz długość subskrypcji. Pamiętaj że po okresie obowiązywania
        subskrypcji będziesz musiał ją przedłużyć.
      </p>
      <PackageSelector
        productsData={productsData}
        step="lengthLicense"
        markComplete={markComplete}
        markUnComplete={markUnComplete}
      />
    </div>
  );
};

export default StepFirst;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/