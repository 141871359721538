import initialState from "./initialState";

/**
 * Reducer function responsible for updating the application state based on dispatched actions.
 * This function takes the current state and an action as parameters, and returns the new state
 * based on the type of action dispatched.
 *
 * @callback Reducer
 * @param {Object} state - The current state of the application.
 * @param {Object} action - The action dispatched to update the state.
 * @param {string} action.type - The type of action dispatched.
 * @param {*} [action.payload] - The payload containing data associated with the action (optional).
 * @returns {Object} The new state of the application after applying the action.
 */

/**
 * Reducer function for managing application state updates.
 *
 * @type {Reducer}
 * @param {Object} state - The current state of the application.
 * @param {Object} action - The action dispatched to update the state.
 * @param {string} action.type - The type of action dispatched.
 * @param {*} [action.payload] - The payload containing data associated with the action (optional).
 * @returns {Object} The new state of the application after applying the action.
 */
const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_ACCESS_TOKEN":
      // Update access token in the state
      return {
        ...state,
        accessToken: action.payload,
      };
    case "UPDATE_USER":
      // Update user details in the state
      return {
        ...state,
        userDetails: action.payload,
      };
    case "LOGOUT":
      // Clear user details and access token from the state on logout
      return {
        ...state,
        userDetails: "",
        accessToken: "",
      };
    case "UPDATE_SETTINGS":
      // Update application settings in the state
      return {
        ...state,
        settings: action.payload,
      };
    case "UPDATE_PURCHASE":
      // Update purchase in the state
      return {
        ...state,
        purchase: {
          ...state.purchase,
          ...action.payload,
        },
      };
    case "UPDATE_LANGUAGE":
      // Update language in the state
      return {
        ...state,
        language: action.payload,
      };
    default:
      // Return current state if action type is not recognized
      return state;
  }
};

export default reducer;
