import React from "react";
import "./Message.css";

/**
 * Renders a registration message component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.success - Indicates if the success message should be displayed.
 * @param {boolean} props.unSuccess - Indicates if the unsuccessful message should be displayed.
 * @param {boolean} props.incompleteness - Indicates if the incompleteness message should be displayed.
 * @param {boolean} props.warning - Indicates if the warning message should be displayed.
 * @param {boolean} props.error - Indicates if the error message should be displayed.
 * @param {boolean} props.noRole - Indicates if the no role message should be displayed.
 * @param {boolean} props.noJWT - Indicates if the no JWT message should be displayed.
 * @param {string} props.successMessage - The success message to be displayed.
 * @param {string} props.unSuccessMessage - The unsuccessful message to be displayed.
 * @param {string} props.incompletenessMessage - The incompleteness message to be displayed.
 * @param {string} props.warningMessage - The warning message to be displayed.
 * @param {string} props.errorMessage - The error message to be displayed.
 * @param {string} props.noRoleMessage - The no role message to be displayed.
 * @param {string} props.noJWTMessage - The no JWT message to be displayed.
 * @returns {JSX.Element} The rendered RegistrationMessage component.
 */
const RegistrationMessage = ({
  success,
  unSuccess,
  incompleteness,
  warning,
  error,
  noRole,
  noJWT,
  successMessage,
  unSuccessMessage,
  incompletenessMessage,
  warningMessage,
  errorMessage,
  noRoleMessage,
  noJWTMessage,
}) => {
  return (
    <div className="error__message">
      {success && <div className="message--success">{successMessage}</div>}
      {unSuccess && <div className="message--success">{unSuccessMessage}</div>}
      {incompleteness && (
        <div className="message--warning">{incompletenessMessage}</div>
      )}
      {warning && <div className="message--warning">{warningMessage}</div>}
      {error && <div className="message--error">{errorMessage}</div>}
      {noRole && <div className="message--warning">{noRoleMessage}</div>}
      {noJWT && <div className="message--warning">{noJWTMessage}</div>}
    </div>
  );
};

export default RegistrationMessage;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
