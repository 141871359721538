import React from "react";
import { Link } from "react-router-dom";
import styles from "./CredentialCard.module.css";

/**
 * Renders a card component for displaying a credential.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.credential - The credential object to be displayed.
 * @returns {JSX.Element} The rendered Card component.
 */
const Card = ({ credential, handleDelete }) => (
  <div className={styles.card}>
    <h2>{credential.name}</h2>
    <div className={styles.linkContainer}>
      <Link to={`${credential.id}`}>
        <button className={styles.btn}>Szczegóły</button>
      </Link>
      <Link>
        <button
          className={styles.btn}
          onClick={() => handleDelete(credential.id)}
        >
          Usuń
        </button>
      </Link>
    </div>
  </div>
);

export default Card;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 *
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
