import React, { useState, useContext } from "react";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AppStateContext from "../../../../../appState/AppStateContext";
import PackageSelector from "../Card/PackageSelector";
import Spinner from "../../../../atoms/General/Spinner";
import Message from "../../../../atoms/General/Message";
import "./Step.css";

/**
 * Represents the StepSixth component.
 * @param {Object} props - The component props.
 * @param {Function} props.markComplete - Function to mark the step as complete.
 * @param {Function} props.markUnComplete - Function to mark the step as incomplete.
 * @param {Function} props.generateURLToPay - Function to generate the payment URL.
 * @returns {JSX.Element} The StepSixth component.
 */
const StepSixth = ({ markComplete, markUnComplete, generateURLToPay }) => {
  const navigate = useNavigate();
  // Global state
  const { state, dispatch } = useContext(AppStateContext);
  // Global state
  const [disableButton, setDisableButton] = useState(false);
  const [cardClass, setCardClass] = useState(
    "btn__summary--deactive btn__summary"
  );
  const [loading, setLoading] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [noRole, setNoRole] = useState(false);
  const [warningOccurred, setWarningOccurred] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);

  /**
   * Disables the button and changes its style
   */
  const handleBtn = () => {
    setCardClass("btn__summary--disabled");
    setDisableButton(true);
  };

  /**
   * Transforms the amount from PLN to grosz
   * @param {string} amountPln - Amount in PLN
   * @returns {number} Amount in grosz
   */
  const transformAmount = (amountPln) => {
    amountPln = amountPln.toString().replace(/\s|zł/g, "");
    if (isNaN(amountPln)) {
      return;
    }
    return parseInt(amountPln) * 100;
  };

  /**
   * Creates a description for the purchase
   * @returns {string} Purchase description
   */
  const createDescription = () => {
    let description =
      "Zakup: subskrypcja - " + state.purchase.lengthLicense.key;
    if (state.purchase.implementation.key !== "Samodzielna") {
      description +=
        ", implementacja " + state.purchase.implementation.key.toLowerCase();
    }
    if (state.purchase.size.key !== "Brak") {
      description +=
        ", zestaw aktywacyjny " + state.purchase.size.key.toLowerCase();
    }
    if (state.purchase.additions.length !== 0) {
      description += ", pakiet dodatkowy ";
      state.purchase.additions.forEach((addition, index) => {
        description += addition.key.toLowerCase();
        if (index < state.purchase.additions.length - 1) {
          description += ", ";
        }
      });
    }
    description += ".";
    return description;
  };

  /**
   * Handles the completion of the step, including payment URL generation and state update
   * @async
   */
  const handleComplete = async () => {
    try {
      setLoading(true);
      setLoadingSpinner(true);
      let additionsName = state.purchase.additions
        .map((item) => item.key)
        .join(", ");

      const purchaseData = {
        userId: state.userDetails.userSysID,
        products: [
          {
            name: `Subskrypcja: '${state.purchase.lengthLicense.key}'`,
            unitPrice: transformAmount(state.purchase.lengthLicense.value),
            quantity: "1",
          },
          {
            name: `Implementacja: '${state.purchase.implementation.key}'`,
            unitPrice: transformAmount(state.purchase.implementation.value),
            quantity: "1",
          },
          {
            name: `Zestaw aktywacyjny: '${state.purchase.size.key}'`,
            unitPrice: transformAmount(state.purchase.size.value),
            quantity: "1",
          },
          {
            name: `Pakiet dodatkowy: '${additionsName}'`,
            unitPrice: transformAmount(state.purchase.sum.additionsFee),
            quantity: "1",
          },
        ],
        totalAmount: transformAmount(state.purchase.sum.totalFees),
        description: createDescription(),
        currencyCode: "PLN",
        operation: "newContract",
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/pay`,
        purchaseData,
        {
          headers: {
            Authorization: `Bearer ${state.accessToken}`,
            "Refresh-Token": state.userDetails.refreshToken,
          },
          withCredentials: true,
        }
      );

      if (
        response.data.accessToken &&
        response.data.accessToken !== state.accessToken
      ) {
        dispatch({
          type: "UPDATE_ACCESS_TOKEN",
          payload: response.data.newAccessToken,
        });
      }

      if (
        response.status === 200 &&
        typeof response.data.orderResponse === "object" &&
        response.data.orderResponse !== null &&
        !Array.isArray(response.data.orderResponse)
      ) {
        try {
          const responseCreateSet = await axios.post(
            `${process.env.REACT_APP_API_URL}/set/create/${response.data.orderResponse.extOrderId}`,
            state.purchase.delivery,
            {
              headers: {
                Authorization: `Bearer ${state.accessToken}`,
                "Refresh-Token": state.userDetails.refreshToken,
              },
              withCredentials: true,
            }
          );
          if (responseCreateSet.status === 200) {
            generateURLToPay(response.data.orderResponse.redirectUri);
            markComplete();
            handleBtn();
            setLoadingSpinner(false);
            setLoading(false);
            setNoRole(false);
            setWarningOccurred(false);
            setErrorOccurred(false);
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            navigate("/automatic/logout");
          } else if (error.response && error.response.status === 403) {
            setLoadingSpinner(false);
            setLoading(false);
            setNoRole(true);
          } else if (error.response && error.response.status === 404) {
            setLoadingSpinner(false);
            setLoading(false);
            setWarningOccurred(true);
          } else {
            setLoadingSpinner(false);
            setLoading(false);
            setErrorOccurred(true);
          }
          Sentry.captureException(error, {
            extra: { message: "Creating a set",component: "StepSixth" },
          });
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/automatic/logout");
      } else if (error.response && error.response.status === 403) {
        setLoadingSpinner(false);
        setLoading(false);
        setNoRole(true);
      } else if (error.response && error.response.status === 404) {
        setLoadingSpinner(false);
        setLoading(false);
        setWarningOccurred(true);
      } else {
        setLoadingSpinner(false);
        setLoading(false);
        setErrorOccurred(true);
      }
      Sentry.captureException(error, {
        extra: { message: "Creating a payment link",component: "StepSixth" },
      });
    }
  };

  return (
    <div>
      <div className="content">
        <h2 className="content__h2">PODSUMOWANIE</h2>
        <p className="content__p">Poniżej podsumowanie Twojego zamówinia.</p>
        <PackageSelector
          step="summary"
          markComplete={markComplete}
          markUnComplete={markUnComplete}
          purchase={state.purchase}
        />
        <Message
          noRole={noRole}
          warning={warningOccurred}
          error={errorOccurred}
          noRoleMessage="Brak odpowiedniej roli. Skontaktuj się z administratorem!"
          warningMessage="Połączenie z PayU zakończone niepowodzeniem. Problem z wygenerowaniem linku do płatności. Skontaktuj się z administratorem!"
          errorMessage="Połączenie z serwerem zakończone niepowodzeniem. Problem z wygenerowaniem linku do płatności. Skontaktuj się z administratorem!"
        />
        {loading ? (
          <div className="loading">
            {loadingSpinner && (
              <div className="spinner">
                <Spinner />
              </div>
            )}
          </div>
        ) : (
          <button
            className={cardClass}
            onClick={handleComplete}
            disabled={disableButton}
          >
            Potwierdzam poprawność danych
          </button>
        )}
      </div>
    </div>
  );
};

export default StepSixth;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
