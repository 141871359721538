import React, { useState, useContext } from "react";
import axios from "axios";
import * as Sentry from "@sentry/react";
import DOMPurify from "dompurify";
import { useParams, useNavigate } from "react-router-dom";
import AppStateContext from "../../../appState/AppStateContext";
import handleError from "../../../utils/HandleError";
import Message from "../../atoms/General/Message";
import Spinner from "../../atoms/General/Spinner";
import styles from "./EmployeeForm.module.css";

/**
 * EmployeeForm component for creating a new local user.
 *
 * @returns {JSX.Element} The EmployeeForm component.
 */
const EmployeeForm = () => {
  const { selectedLocation } = useParams();

  const navigate = useNavigate();

  const { state, dispatch } = useContext(AppStateContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [roles, setRoles] = useState("");
  const [description, setDescription] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [success, setSuccess] = useState(false);
  const [noRole, setNoRole] = useState(false);
  const [incompletenessOccurred, setIncompletenessOccurred] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);

  const handleChange = (event, setter) => {
    const sanitizedValue = DOMPurify.sanitize(event.target.value);
    setter(sanitizedValue);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setNoRole(false);
      setIncompletenessOccurred(false);
      setErrorOccurred(false);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/create/${selectedLocation}`,
        {
          email,
          password,
          roles,
          description,
        },
        {
          headers: {
            Authorization: `Bearer ${state.accessToken}`,
            "Refresh-Token": state.userDetails.refreshToken,
          },
          withCredentials: true,
        }
      );

      if (
        response.data &&
        response.data.newAccessToken &&
        response.data.newAccessToken !== state.newAccessToken
      ) {
        dispatch({
          type: "UPDATE_ACCESS_TOKEN",
          payload: response.data.newAccessToken,
        });
      }
      if (response.status === 200) {
        setSuccess(true);
        setNoRole(false);
        setIncompletenessOccurred(false);
        setErrorOccurred(false);
      }
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          message: "Problem creating a new local user",
          component: "EmployeeForm",
        },
      });
      handleError(
        error,
        navigate,
        setNoRole,
        setIncompletenessOccurred,
        setErrorOccurred
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className={styles.message}>
        <Message
          success={success}
          incompleteness={incompletenessOccurred}
          error={errorOccurred}
          noRole={noRole}
          successMessage="Użytkownik lokalny została utworzon pomyślnie."
          incompletenessMessage="Nie znaleziono potrzebnych danych na serwerze. Skontaktuj się z administratorem!"
          errorMessage="Wewnętrzny błąd serwera. Skontaktuj się z administratorem!"
          noRoleMessage="Brak udpowiednich uprawnień. Skontaktuj się z administratorem!"
        />
      </div>

      {isLoading ? (
        <Spinner />
      ) : (
        <div className={styles.employeeForm}>
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.columns}>
              <div className={styles.column}>
                <label>
                  Email:
                  <input
                    className={styles.myInput}
                    type="text"
                    value={email}
                    onChange={(e) => handleChange(e, setEmail)}
                  />
                </label>
                <label>
                  Rola:
                  <select
                    className={styles.mySelect}
                    value={roles}
                    onChange={(e) => handleChange(e, setRoles)}
                  >
                    <option value="manager">Manager</option>
                    <option value="operator">Operator</option>
                  </select>
                </label>
              </div>
              <div className={styles.column}>
                <label>
                  Hasło:
                  <input
                    className={styles.myInput}
                    type="text"
                    value={password}
                    onChange={(e) => handleChange(e, setPassword)}
                  />
                </label>
                <label>
                  Opis:
                  <input
                    className={styles.myInput}
                    type="text"
                    value={description}
                    onChange={(e) => handleChange(e, setDescription)}
                  />
                </label>
              </div>
            </div>
            <div className={styles.submitBtn}>
              <button type="submit">Utwórz</button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default EmployeeForm;

// - AUDIT
// - 25.06.2024
// - LOADING SUPPORT +
// - ERROR HANDLING +
// - SECURING INCOMING DATA +
// - SECURING OUTGOING DATA +
// - API CORRECTNESS +
// - CSS CORRECTNESS +
// - ACCESS TOKEN +
// - MOBILE 360PX +
// - BACKEND CONTROLER +
// - PASS
