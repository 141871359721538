import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import * as Sentry from "@sentry/react";
import AppStateContext from "../../../appState/AppStateContext";
import styles from "./SummaryDashboardOrder.module.css";

const POLLING_INTERVAL = 5000; // 5 seconds

const Order = () => {
  const { state, dispatch, cookieLoaded } = useContext(AppStateContext);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchOrders = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/order/customers/3282e3e4-3e8f-4dfa-a3f1-678260d61f72`,
        {
          headers: {
            Authorization: `Bearer ${state.accessToken}`,
            "Refresh-Token": state.userDetails.refreshToken,
          },
          withCredentials: true,
        }
      );

      if (
        response.data &&
        response.data.newAccessToken &&
        response.data.newAccessToken !== state.newAccessToken
      ) {
        dispatch({
          type: "UPDATE_ACCESS_TOKEN",
          payload: response.data.newAccessToken,
        });
      }

      if (response.status === 200 && Array.isArray(response.data.orders)) {
        setOrders(response.data.orders);
      }
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          message: "Error retrieving all orders connected to location",
          component: "SummaryDashboardOrder",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (cookieLoaded) {
      fetchOrders();
      const intervalId = setInterval(fetchOrders, POLLING_INTERVAL);

      // Cleanup interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [cookieLoaded]);

  const updateOrderState = async (orderId, newState) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/order/single/${orderId}`,
        { state: newState },
        {
          headers: {
            Authorization: `Bearer ${state.accessToken}`,
            "Refresh-Token": state.userDetails.refreshToken,
          },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        fetchOrders();
      }
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          message: "Error updating order state",
          component: "SummaryDashboardOrder",
        },
      });
    }
  };

  const getNextState = (currentState) => {
    const stateOrder = ["new", "in progress", "complete"];
    const currentIndex = stateOrder.indexOf(currentState);
    return currentIndex < stateOrder.length - 1
      ? stateOrder[currentIndex + 1]
      : null;
  };

  const getPreviousState = (currentState) => {
    const stateOrder = ["new", "in progress", "complete"];
    const currentIndex = stateOrder.indexOf(currentState);
    return currentIndex > 0 ? stateOrder[currentIndex - 1] : null;
  };

  const renderOrdersByState = (state) => {
    const filteredOrders = orders.filter((order) => order.state === state);

    if (filteredOrders.length === 0) {
      return <p className={styles.noOrdersText}>No orders in this state.</p>;
    }

    return filteredOrders.map((order) => (
      <div key={order.id} className={styles.orderItem}>
        <div className={styles.orderDetails}>
          {order.products.map((product, index) => (
            <div key={index}>
              <p><b>{product.name}</b> - Quantity: {product.quantity}</p>
            </div>
          ))}
          <p>Table: {order.tableNumber}</p>
          <p>Comment: {order.description}</p>
        </div>
        <div className={styles.buttons}>
          {getPreviousState(state) && (
            <button
              onClick={() =>
                updateOrderState(order.id, getPreviousState(state))
              }
              className={styles.prevButton}
              aria-label="Move to previous state"
            >
              {"<"}
            </button>
          )}
          {getNextState(state) && (
            <button
              onClick={() =>
                updateOrderState(order.id, getNextState(state))
              }
              className={styles.nextButton}
              aria-label="Move to next state"
            >
              {">"}
            </button>
          )}
        </div>
      </div>
    ));
  };

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles.loader}>Loading...</div>
      ) : (
        <>
          <div className={styles.column}>
            <h2>New Orders</h2>
            {renderOrdersByState("new")}
          </div>
          <div className={styles.column}>
            <h2>Orders in Progress</h2>
            {renderOrdersByState("in progress")}
          </div>
          <div className={styles.column}>
            <h2>Completed Orders</h2>
            {renderOrdersByState("complete")}
          </div>
        </>
      )}
    </div>
  );
};

export default Order;




// Dashboard zamówień
// Dashboard dostepny dla operatora (wyświetla zamówienia przypisane do lokalizacji), dla managera i administratora możliwość wyboru firmy i lokalizacji
// Trzy kolumny z zamówieniami: Nowe, W trakcie, Zrealizowane
// Przycisk do zmiany stanu zamówienia (Nowe <-> W trakcie <-> Zrealizowane)
// Wyświetlane informacje produkt, nr stolika, komentarz
// Możliwość przenoszenia za pomocą przeciągnij i upuść


// Przeniesc do stanu lokalizacje danego operatora i pobierać ją dynamicznie
// Przesłać na serwer i uspójnić palody (description dla zakładanych zamówień dodawany automatycznie w formacie 2222||opis)
// Spinner, obsługa błędów, komunikaty
// Kolejność może data zamówienia
// Reset zamówień complete po zakonczonym dniu pracy 
// Widok dla managera i administratora, operatora
// Cały flow - przeniesienie na serwer sprawdzenia backend
// Strona po udanym złożeniu zamówienia
// automatyczne odswiezanie
