import React from "react";
import { Link } from "react-router-dom";
import RefreshButton from "../../General/RefreshButton";
import BackButton from "../../General/BackButton";
import "./DesktopButton.css";
import "../../../style/Main.css";

/**
 * Renders a set of buttons for the desktop version of the dashboard.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.action - The action associated with the buttons.
 * @param {function} props.modalActivation - The function to activate the modal.
 * @param {function} props.modalRenew - The function to renew the modal.
 * @param {boolean} props.isEditable - Indicates whether the component is editable.
 * @param {function} props.toggleEditable - The function to toggle the editable state.
 * @param {boolean} props.isActive - Indicates whether the component is active.
 * @returns {JSX.Element} The rendered component.
 */
const DesktopButtons = ({
  action,
  modalActivation,
  modalRenew,
  isEditable,
  toggleEditable,
  isActive,
  selectedCompany,
  btnStatus,
}) => {
  return (
    <div className="actionDesktopButton">
      <div className="actionDesktopButton__container">
        {action === "newCompany" ? (
          <Link to="create">
            <button className="btn">Nowa</button>
          </Link>
        ) : null}
        {action === "license" ? (
          // <Link to="create">
          <button className="btn" disabled={isActive} onClick={modalActivation}>
            Aktywuj
          </button>
        ) : // </Link>
        null}
        {action === "license" ? (
          // <Link to="create">
          <button className="btn" disabled={!isActive} onClick={modalRenew}>
            Opłać
          </button>
        ) : // </Link>
        null}
        {action === "newLicense" ? (
          <Link to="create">
            <button className="btn">Nowa</button>
          </Link>
        ) : null}
        {action === "editCompany" ? (
          <button className="btn" onClick={toggleEditable}>
            {isEditable ? "Zablokuj" : "Odblokuj"}
          </button>
        ) : null}
        {action === "newLocation" && (
          <Link to={`create/${selectedCompany}`}>
            <button className="btn" disabled={btnStatus}>
              Nowa
            </button>
          </Link>
        )}
        {action === "updateCategory" && (
          <Link to={`item`}>
            <button className="btn" disabled={btnStatus}>
              Nowa Kategoria
            </button>
          </Link>
        )}
        {action === "updateCategory" ? (
          <button className="btn" onClick={toggleEditable}>
            {isEditable ? "Odblokuj" : "Zablokuj"}
          </button>
        ) : null}
        {action === "updateLocation" && (
          <Link to={`employee`}>
            <button className="btn">Pracownik</button>
          </Link>
        )}
        {action === "updateLocation" ? (
          <button className="btn" onClick={toggleEditable}>
            {isEditable ? "Odblokuj" : "Zablokuj"}
          </button>
        ) : null}
        {action === "newCategory" && (
          <Link to={`create/${selectedCompany}`}>
            <button className="btn" disabled={btnStatus}>
              Nowa
            </button>
          </Link>
        )}
        {action === "updateCredential" ? (
          <button className="btn" onClick={toggleEditable}>
            {isEditable ? "Odblokuj" : "Zablokuj"}
          </button>
        ) : null}
        {action === "newCredential" && (
          <Link to={`create`}>
            <button className="btn" disabled={btnStatus}>
              Nowa
            </button>
          </Link>
        )}
        {action === "readProfile" && (
          <Link to={`password`}>
            <button className="btn" disabled={btnStatus}>
              Hasło
            </button>
          </Link>
        )}
        {action === "readProfile" ? (
          <button className="btn" onClick={toggleEditable}>
            {isEditable ? "Odblokuj" : "Zablokuj"}
          </button>
        ) : null}
        {action === "newEmployee" ? (
          <Link to={`create`}>
            <button className="btn">
              Nowy
            </button>
          </Link>
        ) : null}
        {action === "updateLocalUser" ? (
          <button className="btn" onClick={toggleEditable}>
            {isEditable ? "Odblokuj" : "Zablokuj"}
          </button>
        ) : null}
        <RefreshButton />
        <BackButton />
      </div>
    </div>
  );
};

export default DesktopButtons;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER+
 *
 * PASS
 ***/
