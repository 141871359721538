import React from "react";
import DesktopButtons from "../../atoms/Dashboard/Button/DesktopButton";
import MobileButtons from "../../atoms/Dashboard/Button/MobileButton";
import InfoMessage from "../../atoms/General/InfoMessage";
import EmployeeForm from "../molecules/EmployeeForm";
import styles from "./Employee.module.css";

const LocationFormPage = () => {
  return (
    <div className={styles.employee}>
      <div className={styles.employee__btn}>
        <InfoMessage additionalInfo="W tym miejscu możesz dodać nowego użytkownika." />
        <div>
          <DesktopButtons />
          <MobileButtons />
        </div>
      </div>
      <h1 className="license__h1">UTWÓRZ NOWEGO PRACOWNIKA</h1>
      <EmployeeForm />
    </div>
  );
};

export default LocationFormPage;

// - AUDIT
// - 25.06.2024
// - LOADING SUPPORT +
// - ERROR HANDLING +
// - SECURING INCOMING DATA +
// - SECURING OUTGOING DATA +
// - API CORRECTNESS +
// - CSS CORRECTNESS +
// - ACCESS TOKEN +
// - MOBILE 360PX +
// - BACKEND CONTROLER +
// - PASS`
