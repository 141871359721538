import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavigationWrapper from "./utils/NavigationWrapper";
import Home from "./components/pages/Home/Home";
import Register from "./components/pages/Home/Register";
import Login from "./components/pages/Home/Login";
import Logout from "./components/pages/Dashboard/Logout";
import Dashbord from "./components/pages/Dashboard/Dashborad";
import Company from "./components/pages/Dashboard/Company";
import CompanyForm from "./components/organisms/Dashboard/Company/CompanyForm";
import CompanyDetails from "./components/organisms/Dashboard/Company/CompanyItem";
import AutomaticLogout from "./components/molecules/General/AutomaticLogout";
import License from "./components/pages/Dashboard/License";
import LicenseForm from "./components/organisms/Dashboard/License/LicenseForm";
import StepSeven from "./components/molecules/Dashboard/Stepper/Content/StepSeven";
import LicenseDetails from "./components/organisms/Dashboard/License/LicenseItem";
import Location from "./components/location/pages/Location";
import LocationNew from "./components/location/pages/LocationFormPage";
import LocationItemPage from "./components/location/pages/LocationItemPage";
import Category from "./components/category/pages/Category";
import CategoryItemPage from "./components/category/pages/CategoryItemPage";
import CategoryNew from "./components/category/pages/CategoryFormPage";
import MenuItemPageForCategory from "./components/category/pages/MenuItemPageForCategory";
import MenuFormPageForCategory from "./components/category/pages/MenuFormPageForCategory";
import Menu from "./components/menu/pages/Menu";
import MenuItemPage from "./components/menu/pages/MenuItemPage";
import MenuFormPage from "./components/menu/pages/MenuFormPage";
import Credential from "./components/credential/pages/Credential";
import CredentialItemPage from "./components/credential/pages/CredentialItemPage";
import CredentialFormPage from "./components/credential/pages/CredentialFormPage";
import User from "./components/user/pages/User";
import ChangeUserPassword from "./components/user/pages/ChangeUserPassword";
import Employee from "./components/employee/pages/Employee";
import EmployeeFormPage from "./components/employee/pages/EmployeeFormPage";
import EmployeeItemPage from "./components/employee/pages/EmployeeItemPage";
import Profile from "./components/profile/pages/Profile";
import Order from "./components/order/pages/Order";


const App = () => {
  return (
    <BrowserRouter>
      <NavigationWrapper />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/automatic/logout" element={<AutomaticLogout />} />
        <Route path="/dashboard" element={<Dashbord />}>
          <Route path="logout" element={<Logout />} />
          <Route path="company" element={<Company />} />
          <Route path="company/create" element={<CompanyForm />} />
          <Route path="company/read/:id" element={<CompanyDetails />} />
          <Route path="license" element={<License />} />
          <Route path="license/create" element={<LicenseForm />} />
          {/* <Route path="license/create/finish/:transactionID" element={<StepSeven />} /> */}
          <Route
            path="license/create/finish/:transactionID/:contractID/:operation"
            element={<StepSeven />}
          />
          <Route
            path="license/read/:id/:operation"
            element={<LicenseDetails />}
          />
          <Route path="location" element={<Location />} />
          <Route
            path="location/create/:selectedCompany"
            element={<LocationNew />}
          />
          <Route path="location/:id" element={<LocationItemPage />} />
          <Route path="category" element={<Category />} />
          <Route path="category/:id" element={<CategoryItemPage />} />
          <Route
            path="category/:id/:item"
            element={<MenuItemPageForCategory />}
          />
          <Route
            path="category/create/:selectedLocation"
            element={<CategoryNew />}
          />
          <Route
            path="category/:selectedCategory/item"
            element={<MenuFormPageForCategory />}
          />
          <Route path="menu" element={<Menu />} />
          <Route path="menu/:id" element={<MenuItemPage />} />
          <Route
            path="menu/create/:selectedCategory"
            element={<MenuFormPage />}
          />
          <Route
            path="category/:selectedCategory/:selectedMenu"
            element={<MenuItemPage />}
          />
          <Route path="credential" element={<Credential />} />
          <Route path="credential/:id" element={<CredentialItemPage />} />
          <Route path="credential/create" element={<CredentialFormPage />} />
          <Route path="user" element={<User />} />
          <Route path="user/password" element={<ChangeUserPassword />} />
          <Route
            path="location/:selectedLocation/employee"
            element={<Employee />}
          />
          <Route
            path="location/:selectedLocation/employee/create"
            element={<EmployeeFormPage />}
          />
          <Route
            path="location/:selectedLocation/employee/:selectedUser"
            element={<EmployeeItemPage />}
          />
          <Route path="order" element={<Order />} />
        </Route>

        <Route path="profile/:locationID/:tableNumber" element={<Profile />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
