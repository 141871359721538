import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as Sentry from "@sentry/react";
import AppStateContext from "../../../appState/AppStateContext";
import Message from "../../atoms/General/Message";
import Spinner from "../../atoms/General/Spinner";
import Pagination from "../../atoms/General/Pagination";
import handleError from "../../../utils/HandleError";
import Card from "../molecules/MenuCard";
import styles from "./MenuList.module.css";

/**
 * Renders a list of menu items based on the selected company, location, and category.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.selected - The selected menu item.
 * @returns {JSX.Element} The rendered MenuListItems component.
 */
const MenuListItems = ({ selected }) => {
  const navigate = useNavigate();

  const [hasRenderedCompany, setHasRenderedCompany] = useState(false);
  const [hasRenderedLocation, setHasRenderedLocation] = useState(false);
  const [hasRenderedCategory, setHasRenderedCategory] = useState(false);

  const { state, dispatch, cookieLoaded } = useContext(AppStateContext);

  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  const [companies, setCompanies] = useState([]);
  const [locations, setLocations] = useState([]);
  const [categories, setCategories] = useState([]);
  const [menuItems, setMenuItems] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [noRole, setNoRole] = useState(false);
  const [successOccurred, setSuccessOccurred] = useState(false);
  const [incompletenessOccurred, setIncompletenessOccurred] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [menuItemsPerPage] = useState(3);

  const [visibleLocations, setVisibleLocations] = useState(false);
  const [visibleCategory, setVisibleCategory] = useState(false);

  useEffect(() => {
    if (cookieLoaded) {
      const fetchCompanies = async () => {
        setSuccessOccurred(false);
        setNoRole(false);
        setIncompletenessOccurred(false);
        setErrorOccurred(false);
        setIsLoading(true);
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/company/all/${state.userDetails.userSysID}`,
            {
              headers: {
                Authorization: `Bearer ${state.accessToken}`,
                "Refresh-Token": state.userDetails.refreshToken,
              },
              withCredentials: true,
            }
          );

          if (
            response.data &&
            response.data.newAccessToken &&
            response.data.newAccessToken !== state.newAccessToken
          ) {
            dispatch({
              type: "UPDATE_ACCESS_TOKEN",
              payload: response.data.newAccessToken,
            });
          }

          if (
            response.status === 200 &&
            Array.isArray(response.data.companies)
          ) {
            setCompanies(response.data.companies);
            setNoRole(false);
            setIncompletenessOccurred(false);
            setErrorOccurred(false);
          }
        } catch (error) {
          Sentry.captureException(error, {
            extra: {
              message: "Error retrieving all companies connected to this user",
              component: "CategoryList",
            },
          });
          handleError(
            error,
            navigate,
            setNoRole,
            setIncompletenessOccurred,
            setErrorOccurred
          );
        } finally {
          setIsLoading(false);
        }
      };

      fetchCompanies();
    }
  }, [cookieLoaded]);

  const fetchLocations = async () => {
    setSuccessOccurred(false);
    setNoRole(false);
    setIncompletenessOccurred(false);
    setErrorOccurred(false);
    setLocations([]);
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/location/all/${selectedCompany}`,
        {
          headers: {
            Authorization: `Bearer ${state.accessToken}`,
            "Refresh-Token": state.userDetails.refreshToken,
          },
          withCredentials: true,
        }
      );

      if (
        response.data &&
        response.data.newAccessToken &&
        response.data.newAccessToken !== state.newAccessToken
      ) {
        dispatch({
          type: "UPDATE_ACCESS_TOKEN",
          payload: response.data.newAccessToken,
        });
      }

      if (response.status === 200 && Array.isArray(response.data.locations)) {
        setLocations(response.data.locations);
        setNoRole(false);
        setIncompletenessOccurred(false);
        setErrorOccurred(false);
      }
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          message: "Error retrieving all location connected to this user",
          component: "MenuItemList",
        },
      });
      handleError(
        error,
        navigate,
        setNoRole,
        setIncompletenessOccurred,
        setErrorOccurred
      );
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCategories = async () => {
    setSuccessOccurred(false);
    setNoRole(false);
    setIncompletenessOccurred(false);
    setErrorOccurred(false);
    setCategories([]);
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/category/all/${selectedLocation}`,
        {
          headers: {
            Authorization: `Bearer ${state.accessToken}`,
            "Refresh-Token": state.userDetails.refreshToken,
          },
          withCredentials: true,
        }
      );

      if (
        response.data &&
        response.data.newAccessToken &&
        response.data.newAccessToken !== state.newAccessToken
      ) {
        dispatch({
          type: "UPDATE_ACCESS_TOKEN",
          payload: response.data.newAccessToken,
        });
      }

      if (response.status === 200 && Array.isArray(response.data.categories)) {
        setCategories(response.data.categories);
        setNoRole(false);
        setIncompletenessOccurred(false);
        setErrorOccurred(false);
      }
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          message: "Error retrieving all categories connected to location",
          component: "MenuItemList",
        },
      });
      handleError(
        error,
        navigate,
        setNoRole,
        setIncompletenessOccurred,
        setErrorOccurred
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (hasRenderedCompany) {
      handleNextClickCompany();
    } else {
      setHasRenderedCompany(true);
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (hasRenderedLocation) {
      handleNextClickLocation();
    } else {
      setHasRenderedLocation(true);
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (hasRenderedCategory) {
      handleNextClickCategory();
    } else {
      setHasRenderedCategory(true);
    }
  }, [selectedCategory]);

  const fetchMenuItems = async () => {
    setSuccessOccurred(false);
    setNoRole(false);
    setIncompletenessOccurred(false);
    setErrorOccurred(false);
    setMenuItems([]);
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/menu/all/${selectedCategory}`,
        {
          headers: {
            Authorization: `Bearer ${state.accessToken}`,
            "Refresh-Token": state.userDetails.refreshToken,
          },
          withCredentials: true,
        }
      );

      if (
        response.data &&
        response.data.newAccessToken &&
        response.data.newAccessToken !== state.newAccessToken
      ) {
        dispatch({
          type: "UPDATE_ACCESS_TOKEN",
          payload: response.data.newAccessToken,
        });
      }

      if (response.status === 200 && Array.isArray(response.data.menu)) {
        setMenuItems(response.data.menu);
        setNoRole(false);
        setIncompletenessOccurred(false);
        setErrorOccurred(false);
      }
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          message: "Error retrieving all menu items connected to category",
          component: "MenuItemList",
        },
      });
      handleError(
        error,
        navigate,
        setNoRole,
        setIncompletenessOccurred,
        setErrorOccurred
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (menuItemID) => {
    try {
      setSuccessOccurred(false);
      setNoRole(false);
      setIncompletenessOccurred(false);
      setErrorOccurred(false);
      setIsLoading(true);
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/menu/${menuItemID}`,
        {
          headers: {
            Authorization: `Bearer ${state.accessToken}`,
            "Refresh-Token": state.userDetails.refreshToken,
          },
          withCredentials: true,
        }
      );

      if (
        response.data &&
        response.data.newAccessToken &&
        response.data.newAccessToken !== state.newAccessToken
      ) {
        dispatch({
          type: "UPDATE_ACCESS_TOKEN",
          payload: response.data.newAccessToken,
        });
      }

      if (response.status === 200) {
        setSuccessOccurred(true);
        fetchMenuItems();
      }
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          message: "Error deleting category",
          component: "CategoryList",
        },
      });
      handleError(
        error,
        navigate,
        setNoRole,
        setIncompletenessOccurred,
        setErrorOccurred
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
    setVisibleLocations(true);
  };

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
    setVisibleCategory(true);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    selected(event.target.value);
  };

  const handleNextClickCompany = () => {
    fetchLocations();
  };

  const handleNextClickLocation = () => {
    fetchCategories();
  };

  const handleNextClickCategory = () => {
    fetchMenuItems();
  };

  const indexOfLastMenuItem = currentPage * menuItemsPerPage;
  const indexOfFirstMenuItem = indexOfLastMenuItem - menuItemsPerPage;
  const currentMenuItems = menuItems.slice(
    indexOfFirstMenuItem,
    indexOfLastMenuItem
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <h1 className={styles.title}>MENU</h1>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className={styles.content}>
          <div className={styles.company}>
            <h3>Wybierz firmę: </h3>
            <form className={styles.form}>
              <label className={styles.label}>
                <select
                  className={styles.mySelect}
                  value={selectedCompany}
                  onChange={handleCompanyChange}
                >
                  <option value="">Wybierz firmę</option>{" "}
                  {companies.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.companyName} - {company.address}
                    </option>
                  ))}
                </select>
              </label>
            </form>
          </div>
          {visibleLocations && (
            <div className={styles.location}>
              <h3> Wybierz lokalizację:</h3>
              <form className={styles.form}>
                <label className={styles.label}>
                  <select
                    className={styles.mySelect}
                    value={selectedLocation}
                    onChange={handleLocationChange}
                  >
                    <option value="">Wybierz lokalizację</option>{" "}
                    {locations.map((location) => (
                      <option key={location.id} value={location.id}>
                        {location.locationName}
                      </option>
                    ))}
                  </select>
                </label>
              </form>
            </div>
          )}
          {visibleCategory && (
            <div className={styles.location}>
              <h3> Wybierz kategorię:</h3>
              <form className={styles.form}>
                <label className={styles.label}>
                  <select
                    className={styles.mySelect}
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                  >
                    <option value="">Wybierz kategorię</option>{" "}
                    {categories.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.namePL}
                      </option>
                    ))}
                  </select>
                </label>
              </form>
            </div>
          )}
          <div className={styles.message}>
            <Message
              success={successOccurred}
              incompleteness={incompletenessOccurred}
              error={errorOccurred}
              noRole={noRole}
              successMessage="Kategoria została usunięta!"
              incompletenessMessage="Nie znaleziono potrzebnych danych na serwerze. Skontaktuj się z administratorem!"
              errorMessage="Wewnętrzny błąd serwera. Skontaktuj się z administratorem!"
              noRoleMessage="Brak udpowiednich uprawnień. Skontaktuj się z administratorem!"
            />
          </div>
          <div className={styles.card}>
            {currentMenuItems.map((item) => (
              <Card key={item.id} menuItem={item} handleDelete={handleDelete} />
            ))}
          </div>
          <Pagination
            itemsPerPage={menuItemsPerPage}
            totalItems={menuItems.length}
            paginate={paginate}
          />
        </div>
      )}
    </div>
  );
};

export default MenuListItems;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/