import React, { useState, useContext } from "react";
import axios from "axios";
import * as Sentry from "@sentry/react";
import DOMPurify, { version } from "dompurify";
import { useParams, useNavigate } from "react-router-dom";
import AppStateContext from "../../../appState/AppStateContext";
import handleError from "../../../utils/HandleError";
import Message from "../../atoms/General/Message";
import Spinner from "../../atoms/General/Spinner";
import styles from "./MenuFormForCategory.module.css";

/**
 * MenuForm component represents a form for creating a new item of menu.
 *
 * @returns {JSX.Element} The rendered MenuForm component.
 */
const MenuFormForCategory = () => {
  const { selectedCategory } = useParams();

  const navigate = useNavigate();

  const { state, dispatch } = useContext(AppStateContext);

  const [namePL, setNamePL] = useState("");
  const [nameEN, setNameEN] = useState("");
  const [descriptionPL, setDescriptionPL] = useState("");
  const [descriptionEN, setDescriptionEN] = useState("");
  const [ingredientsPL, setIngredientsPL] = useState("");
  const [ingredientsEN, setIngredientsEN] = useState("");
  const [price, setPrice] = useState("");
  const [size, setSize] = useState("");
  const [spiciness, setSpiciness] = useState(1);
  const [novelty, setNovelty] = useState(false);
  const [vege, setVege] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [success, setSuccess] = useState(false);
  const [noRole, setNoRole] = useState(false);
  const [incompletenessOccurred, setIncompletenessOccurred] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);

  const handleChange = (event, setter) => {
    const sanitizedValue = DOMPurify.sanitize(event.target.value);
    setter(sanitizedValue);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setNoRole(false);
      setIncompletenessOccurred(false);
      setErrorOccurred(false);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/menu/create/${selectedCategory}`,
        {
          namePL,
          descriptionPL,
          nameEN,
          descriptionEN,
          ingredientsPL,
          ingredientsEN,
          price,
          size,
          spiciness,
          vege,
          novelty,
        },
        {
          headers: {
            Authorization: `Bearer ${state.accessToken}`,
            "Refresh-Token": state.userDetails.refreshToken,
          },
          withCredentials: true,
        }
      );

      if (
        response.data &&
        response.data.newAccessToken &&
        response.data.newAccessToken !== state.newAccessToken
      ) {
        dispatch({
          type: "UPDATE_ACCESS_TOKEN",
          payload: response.data.newAccessToken,
        });
      }
      if (response.status === 200) {
        setSuccess(true);
        setNoRole(false);
        setIncompletenessOccurred(false);
        setErrorOccurred(false);
      }
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          message: "Problem creating a new item of menu",
          component: "MenuForm",
        },
      });
      handleError(
        error,
        navigate,
        setNoRole,
        setIncompletenessOccurred,
        setErrorOccurred
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className={styles.message}>
        <Message
          success={success}
          incompleteness={incompletenessOccurred}
          error={errorOccurred}
          noRole={noRole}
          successMessage="Element menu został utworzony pomyślnie."
          incompletenessMessage="Nie znaleziono potrzebnych danych na serwerze. Skontaktuj się z administratorem!"
          errorMessage="Wewnętrzny błąd serwera. Skontaktuj się z administratorem!"
          noRoleMessage="Brak udpowiednich uprawnień. Skontaktuj się z administratorem!"
        />
      </div>

      {isLoading ? (
        <Spinner />
      ) : (
        <div className={styles.locationForm}>
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.columns}>
              <div className={styles.column}>
                <label>
                  Nazwa PL:
                  <input
                    type="text"
                    value={namePL}
                    onChange={(e) => handleChange(e, setNamePL)}
                    className={styles.myInput}
                  />
                </label>
                <label>
                  Opis PL:
                  <input
                    type="text"
                    value={descriptionPL}
                    onChange={(e) => handleChange(e, setDescriptionPL)}
                    className={styles.myInput}
                  />
                </label>
                <label>
                  Skłądniki PL:
                  <input
                    type="text"
                    value={ingredientsPL}
                    onChange={(e) => handleChange(e, setIngredientsPL)}
                    className={styles.myInput}
                  />
                </label>
                <label>
                  Cena:
                  <input
                    type="text"
                    value={price}
                    onChange={(e) => handleChange(e, setPrice)}
                    className={styles.myInput}
                  />
                </label>
                <label>
                  Vege:
                  <select
                    name="vege"
                    value={vege}
                    onChange={(e) => handleChange(e, setVege)}
                    className={styles.mySelect}
                  >
                    <option value="true">Tak</option>
                    <option value="false">Nie</option>
                  </select>
                </label>
              </div>
              <div className={styles.column}>
                <label>
                  Nazwa EN:
                  <input
                    type="text"
                    value={nameEN}
                    onChange={(e) => handleChange(e, setNameEN)}
                    className={styles.myInput}
                  />
                </label>
                <label>
                  Opis EN:
                  <input
                    type="text"
                    value={descriptionEN}
                    onChange={(e) => handleChange(e, setDescriptionEN)}
                    className={styles.myInput}
                  />
                </label>
                <label>
                  Składniki EN:
                  <input
                    type="text"
                    value={ingredientsEN}
                    onChange={(e) => handleChange(e, setIngredientsEN)}
                    className={styles.myInput}
                  />
                </label>
                <label>
                  Poziom ostrości:
                  <select
                    name="spiciness"
                    value={spiciness}
                    onChange={(e) => handleChange(e, setSpiciness)}
                    className={styles.mySelect}
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </label>
                <label>
                  Nowość:
                  <select
                    name="novelty"
                    value={novelty}
                    onChange={(e) => handleChange(e, setNovelty)}
                    className={styles.mySelect}
                  >
                    <option value="true">Tak</option>
                    <option value="false">Nie</option>
                  </select>
                </label>
              </div>
            </div>
            <div className={styles.submitBtn}>
              <button type="submit">Utwórz</button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default MenuFormForCategory;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/