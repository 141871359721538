import React from "react";
import "./ProfileHeader.css";

/**
 * Renders the profile header component.
 *
 * @param {Object} userDetails - The user details object.
 * @param {Function} toggleProfileOptions - The function to toggle profile options.
 * @returns {JSX.Element} The profile header component.
 */
const ProfileHeader = ({ userDetails, toggleProfileOptions }) => (
  <div className="profileHeader" onClick={toggleProfileOptions}>
    {userDetails.profileImageURL && (
      <img
        className="profileHeader__avatar"
        src={userDetails.profileImageURL}
        alt=""
      />
    )}
    <div className="profileHeader__name">
      {userDetails.firstName} {userDetails.lastName}
    </div>
  </div>
);

export default ProfileHeader;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
