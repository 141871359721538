import React from "react";
import PackageSelector from "../Card/PackageSelector";
import "./Step.css";

/**
 * StepThird component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.productsData - The data for the products.
 * @param {Function} props.markComplete - The function to mark the step as complete.
 * @param {Function} props.markUnComplete - The function to mark the step as incomplete.
 * @returns {JSX.Element} The StepThird component.
 */
const StepThird = ({ productsData, markComplete, markUnComplete }) => {
  return (
    <div className="content">
      <h2 className="content__h2">WIELKOŚĆ LOKALIZACJI</h2>
      <p className="content__p">
        Wybierz z poniższych opcji ilość posiadanych stolików.
      </p>
      <PackageSelector
        productsData={productsData}
        step="size"
        markComplete={markComplete}
        markUnComplete={markUnComplete}
      />
    </div>
  );
};

export default StepThird;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
