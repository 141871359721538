import React, { useState } from "react";
import styles from "./CartMobileBox.module.css";

const CartMobileBox = ({ totalOrderItems, order, openModal, closeModal }) => {
  const [open, setOpen] = useState(false);
  /**
   * Toggles the visibility of the order.
   */
  const toggleOrderVisibility = () => {
    closeModal();
    setOpen(false);
    if (!open) {
      openModal();
      setOpen(true);
    } else {
      closeModal();
      setOpen(false);
    }
  };
  return (
    <div>
      {totalOrderItems > 0 && (
        <div
          onClick={toggleOrderVisibility}
          className={styles.cartMobileContainer}
        >
          <span className={styles.cartItemCountMobile}>{totalOrderItems}</span>
          <span className={styles.cartButtonMobile}>Zobacz zamówienie</span>
          <span className={styles.cartTotalPriceMobile}>
            PLN {order.totalAmount}
          </span>
        </div>
      )}
    </div>
  );
};

export default CartMobileBox;
