import React from "react";
import "./LicenseFormItem.css";

const LicenseFormItem = ({ licenseData, locations }) => {
  return (
    <div className="singleLicense__box">
      <form className="singleLicense__container">
        <div className="singleLicense__field">
          <label htmlFor="id">
            ID Licencji
            <input
              type="text"
              id="id"
              name="id"
              value={licenseData.id}
              disabled={true}
            />
          </label>

          <label htmlFor="subscription">
            Nazwa licencji:
            <input
              type="text"
              id="name"
              name="name"
              value={licenseData.licenseName}
              disabled={true}
            />
          </label>

          <label htmlFor="subscription">
            Czas subskrypcji:
            <input
              type="text"
              id="subscription"
              name="subscription"
              value={licenseData.subscription}
              disabled={true}
            />
          </label>
          <label htmlFor="implementation">
            Sposób implementacji:
            <input
              type="text"
              id="implementation"
              name="implementation"
              value={licenseData.implementation}
              disabled={true}
            />
          </label>

          <label htmlFor="activationPackage">
            Pakiet aktywacyjny:
            <input
              type="text"
              id="activationPackage"
              name="activationPackage"
              value={licenseData.activationPackage}
              disabled={true}
            />
          </label>
          <label htmlFor="additionalPackages">
            Pakiety dodatkowe:
            <input
              type="text"
              id="additionalPackages"
              name="additionalPackages"
              value={licenseData.additionalPackages}
              disabled={true}
            />
          </label>
          <label htmlFor="additionalPackages">
            Status:
            <input
              type="text"
              id="state"
              name="state"
              value={licenseData.state}
              disabled={true}
            />
          </label>

          <label htmlFor="periodSince">
            Ważna od:
            <input
              type="text"
              id="periodSince"
              name="periodSince"
              value={licenseData.periodSince}
              disabled={true}
            />
          </label>
          <label htmlFor="periodTo">
            Ważna do:
            <input
              type="text"
              id="periodTo"
              name="periodTo"
              value={licenseData.periodTo}
              disabled={true}
            />
          </label>
        </div>
      </form>
    </div> 
  );
};

export default LicenseFormItem;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * 
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER  +
 *
 * PASS
 ***/ 
