import React from "react";
import "./Overlay.css";

/**
 * Overlay component.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.isOpen - Flag indicating whether the overlay is open or not.
 * @param {Function} props.setIsOpen - Function to set the value of isOpen.
 * @returns {JSX.Element} The overlay component.
 */
const Overlay = ({ isOpen, setIsOpen }) => {
  return (
    <div
      className="overlay"
      style={{ display: isOpen ? "block" : "none" }}
      onClick={() => setIsOpen(false)}
    />
  );
};

export default Overlay;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 *
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER  +
 *
 * PASS
 ***/
