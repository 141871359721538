import React, { useState, useContext, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { NavLink } from "react-router-dom";
import AppStateContext from "../../../appState/AppStateContext";
import "./Navigation.css";

/**
 * Renders a navigation component with responsive menu functionality.
 * @component
 * @returns {JSX.Element} The Navigation component.
 */
const Navigation = () => {
  /**
   * Accessing global state using the useContext hook.
   * @type {{ state: { userDetails: { loggedIn: boolean } } }}
   */
  const { state } = useContext(AppStateContext);

  /**
   * State to manage the open/close status of the navigation menu.
   * @type {[boolean, Function]}
   */
  const [isOpen, setIsOpen] = useState(false);

  /**
   * Determines if the current viewport is mobile size (max-width of 768px).
   * @type {boolean}
   */
  const isMobile = useMediaQuery({ maxWidth: 768 });

  /**
   * Toggles the open state of the navigation menu.
   * Prevents click events from bubbling up to prevent unintentional interactions.
   * @param {React.MouseEvent} e - The event object.
   */
  const toggleMenu = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  /**
   * Closes the navigation menu.
   */
  const closeMenu = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  /**
   * Sets up a click event listener that closes the navigation menu when clicking outside of the menu elements.
   * Cleans up the event listener on component unmount or when the isOpen state changes.
   */
  useEffect(() => {
    const closeOnOutsideClick = (e) => {
      if (
        isOpen &&
        !e.target.closest(".nav__container") &&
        !e.target.closest(".hamburger")
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", closeOnOutsideClick);

    return () => {
      document.removeEventListener("click", closeOnOutsideClick);
    };
  }, [isOpen]);

  /**
   * Renders navigation links, which are responsive to the user's authentication status.
   * Closes the menu upon clicking a link.
   */
  const navLinks = (
    <>
      <li>
        <NavLink to="/" exact onClick={closeMenu}>
          Home
        </NavLink>
      </li>
      {!state.userDetails.loggedIn && (
        <>
          <li>
            <NavLink to="/register" onClick={closeMenu}>
              Rejestracja
            </NavLink>
          </li>
          <li>
            <NavLink to="/login" onClick={closeMenu}>
              Logowanie
            </NavLink>
          </li>
        </>
      )}
      {state.userDetails.loggedIn && (
        <>
          <li>
            <NavLink to="/dashboard" onClick={closeMenu}>
              Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink to="/logout" onClick={closeMenu}>
              Wylogowanie
            </NavLink>
          </li>
        </>
      )}
    </>
  );

  return (
    <div className="nav-wrapper">
      {isMobile && (
        <div className="hamburger" onClick={toggleMenu}>
          <span className="hamburger-icon">&#9776;</span>
        </div>
      )}
      <ul
        className={`nav__container ${isOpen && isMobile ? "open" : ""}`}
        onClick={(e) => e.stopPropagation()}
      >
        {navLinks}
      </ul>
      {isOpen && isMobile && (
        <div className="overlay" onClick={closeMenu}></div>
      )}
    </div>
  );
};

export default Navigation;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
