import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as Sentry from "@sentry/react";
import AppStateContext from "../../../appState/AppStateContext";
import Message from "../../atoms/General/Message";
import Spinner from "../../atoms/General/Spinner";
import Pagination from "../../atoms/General/Pagination";
import handleError from "../../../utils/HandleError";
import Card from "../molecules/CredentialCard";
import styles from "./CredentialList.module.css";

/**
 * Renders a list of credentials for a user.
 * @returns {JSX.Element} The rendered LocationList component.
 */
const CredentialList = () => {
  const navigate = useNavigate();

  const { state, dispatch, cookieLoaded } = useContext(AppStateContext);

  const [credentials, setCredentials] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [successOccurred, setSuccessOccurred] = useState(false);
  const [noRole, setNoRole] = useState(false);
  const [incompletenessOccurred, setIncompletenessOccurred] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [credentialsPerPage] = useState(3);

  const fetchCredentials = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/credential/all/${state.userDetails.userSysID}`,
        {
          headers: {
            Authorization: `Bearer ${state.accessToken}`,
            "Refresh-Token": state.userDetails.refreshToken,
          },
          withCredentials: true,
        }
      );

      if (
        response.data &&
        response.data.newAccessToken &&
        response.data.newAccessToken !== state.newAccessToken
      ) {
        dispatch({
          type: "UPDATE_ACCESS_TOKEN",
          payload: response.data.newAccessToken,
        });
      }

      if (response.status === 200 && Array.isArray(response.data.credentials)) {
        setCredentials(response.data.credentials);
        setNoRole(false);
        setIncompletenessOccurred(false);
        setErrorOccurred(false);
      }
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          message: "Error retrieving all credentials connected to this user",
          component: "CredentialList",
        },
      });
      handleError(
        error,
        navigate,
        setNoRole,
        setIncompletenessOccurred,
        setErrorOccurred
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (cookieLoaded) {
      fetchCredentials();
    }
  }, [cookieLoaded]);

  const handleDelete = async (credentialID) => {
    try {
      setSuccessOccurred(false);
      setNoRole(false);
      setIncompletenessOccurred(false);
      setErrorOccurred(false);
      setIsLoading(true);
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/credential/${credentialID}`,
        {
          headers: {
            Authorization: `Bearer ${state.accessToken}`,
            "Refresh-Token": state.userDetails.refreshToken,
          },
          withCredentials: true,
        }
      );

      if (
        response.data &&
        response.data.newAccessToken &&
        response.data.newAccessToken !== state.newAccessToken
      ) {
        dispatch({
          type: "UPDATE_ACCESS_TOKEN",
          payload: response.data.newAccessToken,
        });
      }

      if (response.status === 200) {
        fetchCredentials();
        setSuccessOccurred(true);
      }
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          message: "Error deleting credential",
          component: "CredentialList",
        },
      });
      handleError(
        error,
        navigate,
        setNoRole,
        setIncompletenessOccurred,
        setErrorOccurred
      );
    } finally {
      setIsLoading(false);
    }
  };

  const indexOfLastCredential = currentPage * credentialsPerPage;
  const indexOfFirstCredential = indexOfLastCredential - credentialsPerPage;
  const currentCredentials = credentials.slice(
    indexOfFirstCredential,
    indexOfLastCredential
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <h1 className={styles.title}>LISTA TWOICH POŚWIADCZEŃ PAYU</h1>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className={styles.message}>
            <Message
              success={successOccurred}
              incompleteness={incompletenessOccurred}
              error={errorOccurred}
              noRole={noRole}
              successMessage="Poświadczenie zostało usunięty!"
              incompletenessMessage="Nie znaleziono potrzebnych danych na serwerze. Skontaktuj się z administratorem!"
              errorMessage="Wewnętrzny błąd serwera. Skontaktuj się z administratorem!"
              noRoleMessage="Brak udpowiednich uprawnień. Skontaktuj się z administratorem!"
            />
          </div>
          <div className={styles.card}>
            {currentCredentials.map((credential) => (
              <Card
                key={credential.id}
                credential={credential}
                handleDelete={handleDelete}
              />
            ))}
          </div>
          <Pagination
            itemsPerPage={credentialsPerPage}
            totalItems={credentials.length}
            paginate={paginate}
          />
        </>
      )}
    </div>
  );
};

export default CredentialList;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 *
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
