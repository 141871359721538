import React, { useState, useEffect, useRef, memo, useContext } from "react";
import axios from "axios";
import DOMPurify from "dompurify";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router-dom";
import AppStateContext from "../../../appState/AppStateContext";
import CartMobileBox from "../molecules/CartMobileBox";
import Message from "../../atoms/General/Message";
import Spinner from "../../atoms/General/Spinner";
import OrderSummary from "./OrderSummary";
import SingleMenuItemModal from "./SingleMenuItemModal";
import LocationCategories from "./LocationCategories";
import Header from "../molecules/Header";
import Summary from "./Summary";
import Footer from "./Footer";
import logo from "../../../img/wolt-removebg-preview.png";
import styles from "./LocationProfile.module.css";

/**
 * Represents the LocationProfile component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.locationID - The ID of the location.
 *
 * @returns {JSX.Element} The LocationProfile component.
 */
const LocationProfile = ({ locationID, tableNumber }) => {
  const [location, setLocation] = useState(null);
  const [expandedCategoryId, setExpandedCategoryId] = useState(null);
  const [incompletenessOccurred, setIncompletenessOccurred] = useState(false);
  const [warningOccurred, setWarningOccurred] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [incompletenessOccurredSummary, setIncompletenessOccurredSummary] =
    useState(false);
  const [errorOccurredSummary, setErrorOccurredSummary] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSummary, setIsLoadingSummary] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [quantity, setQuantity] = useState(0);
  const [order, setOrder] = useState({
    clientName: "",
    clientSurname: "",
    products: [],
    totalAmount: 0,
    description: "",
    currencyCode: "PLN",
  });
  const [confirm, setConfirm] = useState(false);
  const [URLToPay, setURLToPay] = useState("");

  const [isOrderVisible, setIsOrderVisible] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [validationContract, setValidationContract] = useState(false);

  const modalRef = useRef(null);
  const orderSummaryRef = useRef(null);

  const navigate = useNavigate();

  const { state, dispatch } = useContext(AppStateContext);

  /**
   * Updates the language in the profile.
   *
   * @param {string} language - The new language to be updated.
   * @returns {void}
   */
  const handleLanguage = (language) => {
    dispatch({
      type: "UPDATE_LANGUAGE",
      payload: language,
    });
  };

  /**
   * Sends a payment request to the server.
   *
   * @async
   * @function sendPaymentRequest
   * @returns {Promise<void>} A Promise that resolves when the payment request is sent.
   */
  const sendPaymentRequest = async () => {
    setIncompletenessOccurredSummary(false);
    setErrorOccurredSummary(false);
    setIsLoadingSummary(true);

    const apiUrl = `${process.env.REACT_APP_API_URL}/order/pay/${locationID}`;

    const orderForPayU = {
      ...order,
      totalAmount: (order.totalAmount * 100).toString(),
      products: order.products.map(({ id, totalPrice, ...rest }) => ({
        ...rest,
        unitPrice: (rest.unitPrice * 100).toString(),
        quantity: rest.quantity.toString(),
      })),
      tableNumber
    };

    try {
      const response = await axios.post(apiUrl, JSON.stringify(orderForPayU), {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        setConfirm(true);
        setURLToPay(response.data.orderResponse.redirectUri);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setIncompletenessOccurredSummary(true);
      } else {
        setErrorOccurredSummary(true);
      }
      Sentry.captureException(error, {
        extra: {
          message: "Error send payment request",
          component: "LocationProfile",
        },
      });
    } finally {
      setIsLoadingSummary(false);
    }
  };

  const checkContractValidity = async () => {
    setIsLoading(true);
    setIncompletenessOccurred(false);
    setWarningOccurred(false);
    setErrorOccurred(false);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/order/check/${locationID}/${tableNumber}`
      );
      if (response.status === 200) {
        setValidationContract(true);
        fetchLocationDetails();
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setIncompletenessOccurred(true);
      } else if (error.response && error.response.status === 403) {
        setWarningOccurred(true);
      } else {
        setErrorOccurred(true);
      }
      Sentry.captureException(error, {
        extra: {
          message: "Error checking contract validity.",
          component: "LocationProfile",
        },
      });
    }
  };

  /**
   * Fetches location details from the API based on the provided location ID.
   *
   * @async
   * @function fetchLocationDetails
   * @returns {Promise<void>} - A promise that resolves when the location details are fetched.
   */
  const fetchLocationDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/profile/${locationID}`
      );
      if (response.status === 200) {
        setLocation(response.data.location);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setIncompletenessOccurred(true);
      } else {
        setErrorOccurred(true);
      }
      Sentry.captureException(error, {
        extra: {
          message: "Error retrieving location.",
          component: "LocationProfile",
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkContractValidity();
  }, [locationID, navigate]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isModalOpen &&
        modalRef.current &&
        !modalRef.current.contains(event.target)
      ) {
        closeModal();
      }

      if (
        isOrderVisible &&
        orderSummaryRef.current &&
        !orderSummaryRef.current.contains(event.target)
      ) {
        closeModalSecond();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isModalOpen, isOrderVisible]);

  /**
   * Opens a modal with the selected menu item.
   *
   * @param {Object} menuItem - The selected menu item.
   */
  const openModal = (menuItem) => {
    setSelectedMenuItem(menuItem);
    setQuantity(
      order.products.find((item) => item.id === menuItem.id)?.quantity || 0
    );
    setModalOpen(true);
  };

  /**
   * Opens the second modal and sets the visibility of the order.
   */
  const openModalSecond = () => {
    setIsOrderVisible(true);
  };

  /**
   * Closes the modal.
   */
  const closeModal = () => setModalOpen(false);

  /**
   * Closes the second modal and hides the order visibility.
   */
  const closeModalSecond = () => setIsOrderVisible(false);

  /**
   * Toggles the category based on the provided categoryId.
   * If the categoryId is currently expanded, it collapses it.
   * If the categoryId is currently collapsed, it expands it.
   *
   * @param {string} categoryId - The ID of the category to toggle.
   * @returns {void}
   */
  const toggleCategory = (categoryId) =>
    setExpandedCategoryId(
      expandedCategoryId === categoryId ? null : categoryId
    );

  /**
   * Increments the quantity and updates the order.
   * @returns {number} The new quantity value.
   */
  const incrementQuantity = () =>
    setQuantity((prevQuantity) => {
      const newQuantity = prevQuantity + 1;
      updateOrder(selectedMenuItem, newQuantity);
      return newQuantity;
    });

  /**
   * Decrements the quantity and updates the order for the selected menu item.
   */
  const decrementQuantity = () =>
    setQuantity((prevQuantity) => {
      const newQuantity = prevQuantity > 0 ? prevQuantity - 1 : 0;
      updateOrder(selectedMenuItem, newQuantity);
      return newQuantity;
    });

  /**
   * Rounds a number to two decimal places.
   *
   * @param {number} num - The number to be rounded.
   * @returns {number} - The rounded number.
   */
  const roundToTwoDecimals = (num) => Math.round(num * 100) / 100;

  /**
   * Updates the order with the given menuItem and newQuantity.
   *
   * @param {Object} menuItem - The menu item to update.
   * @param {number} newQuantity - The new quantity of the menu item.
   * @returns {Object} - The updated order.
   */
  const updateOrder = (menuItem, newQuantity) => {
    setOrder((prevOrder) => {
      const existingItemIndex = prevOrder.products.findIndex(
        (item) => item.id === menuItem.id
      );

      let updatedOrder = { ...prevOrder };

      if (existingItemIndex >= 0) {
        const existingItem = updatedOrder.products[existingItemIndex];
        existingItem.quantity = newQuantity;
        existingItem.totalPrice = roundToTwoDecimals(
          existingItem.quantity * existingItem.unitPrice
        );

        if (newQuantity === 0) {
          updatedOrder.products = updatedOrder.products.filter(
            (item) => item.id !== menuItem.id
          );
        }
      } else if (newQuantity > 0) {
        updatedOrder.products = [
          ...updatedOrder.products,
          {
            id: menuItem.id,
            name: state.language === "en" ? menuItem.nameEN : menuItem.namePL,
            unitPrice: roundToTwoDecimals(menuItem.unitPrice),
            quantity: newQuantity,
            totalPrice: roundToTwoDecimals(menuItem.unitPrice * newQuantity),
          },
        ];
      }

      updatedOrder.totalAmount = roundToTwoDecimals(
        updatedOrder.products.reduce(
          (total, item) => total + item.totalPrice,
          0
        )
      );

      setConfirm(false);
      setDisabledBtn(false);

      return updatedOrder;
    });
  };

  /**
   * Adds the selected menu item to the order and closes the modal.
   */
  const addToOrder = () => {
    updateOrder(selectedMenuItem, quantity);
    closeModal();
  };

  /**
   * Removes an item from the order.
   *
   * @param {string} itemId - The ID of the item to be removed.
   * @returns {void}
   */
  const removeFromOrder = (itemId) => {
    setOrder((prevOrder) => {
      const updatedItems = prevOrder.products.filter(
        (item) => item.id !== itemId
      );
      const updatedTotalAmount = roundToTwoDecimals(
        updatedItems.reduce((total, item) => total + item.totalPrice, 0)
      );

      return {
        ...prevOrder,
        products: updatedItems,
        totalAmount: updatedTotalAmount,
      };
    });
  };

  /**
   * Handles the change in quantity for a specific item in the order.
   *
   * @param {string} itemId - The ID of the item to update the quantity for.
   * @param {number} newQuantity - The new quantity value for the item.
   * @returns {void}
   */
  const handleQuantityChange = (itemId, newQuantity) => {
    updateOrder(
      order.products.find((item) => item.id === itemId),
      newQuantity
    );
  };

  /**
   * Handles the disabled confirm state.
   */
  const handleDisabledConfirm = () => {
    setConfirm(false);
    setDisabledBtn(false);
  };

  /**
   * Handles disabling the button.
   */
  const handleDisabledBtn = () => {
    setDisabledBtn(true);
  };

  const totalOrderItems = order.products.reduce(
    (total, item) => total + item.quantity,
    0
  );

  /**
   * Handles the input change event.
   *
   * @param {Event} e - The input change event.
   * @returns {void}
   */
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = DOMPurify.sanitize(value);
    setOrder((prevOrder) => ({
      ...prevOrder,
      [name]: sanitizedValue,
    }));
  };

  return (
    <div className={styles.boxtest}>
      <div className={styles.mobileView}>
        <CartMobileBox
          totalOrderItems={totalOrderItems}
          order={order}
          openModal={openModalSecond}
          closeModal={closeModalSecond}
        />
      </div>

      {location && (
        <Header
          location={location}
          totalOrderItems={totalOrderItems}
          order={order}
          openModal={openModalSecond}
          closeModal={closeModalSecond}
          handleLanguage={handleLanguage}
          language={state.language}
        />
      )}

      <div className={styles.container}>
        <div>
          {(errorOccurred || incompletenessOccurred || warningOccurred) && (
            <div className={styles.messageContainer}>
              <img src={logo} alt="Logo" className={styles.stickyLogo} />
              <Message
                warning={warningOccurred}
                incompleteness={incompletenessOccurred}
                error={errorOccurred}
                warningMessage="Nie masz dostępu do tej lokalizacji. Skontaktuj się z obsługą!"
                incompletenessMessage="Nie znaleziono potrzebnych danych na serwerze. Skontaktuj się z administratorem!"
                errorMessage="Wewnętrzny błąd serwera. Skontaktuj się z administratorem!"
              />
            </div>
          )}
          {isLoading && (
            <div className={styles.messageContainer}>
              {" "}
              <img src={logo} alt="Logo" className={styles.stickyLogo} />
              <Spinner />
            </div>
          )}
        </div>

        {location && (
          <>
            <LocationCategories
              location={location}
              expandedCategoryId={expandedCategoryId}
              toggleCategory={toggleCategory}
              openModal={openModal}
              roundToTwoDecimals={roundToTwoDecimals}
              language={state.language}
            />
          </>
        )}
        {selectedMenuItem && (
          <SingleMenuItemModal
            isOpen={isModalOpen}
            onClose={closeModal}
            ref={modalRef}
            quantity={quantity}
            onQuantityChange={(newQuantity) => {
              setQuantity(newQuantity);
              updateOrder(selectedMenuItem, newQuantity);
            }}
            incrementQuantity={incrementQuantity}
            decrementQuantity={decrementQuantity}
            menuItem={selectedMenuItem}
            roundToTwoDecimals={roundToTwoDecimals}
            addToOrder={addToOrder}
            language={state.language}
          />
        )}

        {isOrderVisible && (
          <OrderSummary
            order={order}
            ref={orderSummaryRef}
            handleQuantityChange={handleQuantityChange}
            removeFromOrder={removeFromOrder}
            handleInputChange={handleInputChange}
            sendPaymentRequest={sendPaymentRequest}
            confirm={confirm}
            URLToPay={URLToPay}
            isVisible={isOrderVisible}
            handleDisabledConfirm={handleDisabledConfirm}
            disabledBtn={disabledBtn}
            handleDisabledBtn={handleDisabledBtn}
            language={state.language}
            incompletenessOccurredSummary={incompletenessOccurredSummary}
            errorOccurredSummary={errorOccurredSummary}
            isLoadingSummary={isLoadingSummary}
            openModal={openModalSecond}
            closeModal={closeModalSecond}
          />
        )}
      </div>
      {location && (
        <Summary
          descriptionPL={location.descriptionPL}
          descriptionEN={location.descriptionEN}
          address={location.address}
          language={state.language}
          streetNumber={location.streetNumber}
          localNumber={location.localNumber}
          openingHoursPL={location.openingHoursPL}
          openingHoursEN={location.openingHoursEN}
          zipCode={location.zipCode}
          city={location.city}
        />
      )}

      <Footer />
    </div>
  );
};

export default memo(LocationProfile);
