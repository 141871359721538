import React from "react";
import { Link } from "react-router-dom";
import styles from "./EmployeeCard.module.css";

/**
 * Renders a card component for displaying employee information.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.user - The user object containing employee information.
 * @returns {JSX.Element} The rendered Card component.
 */
const Card = ({ user }) => (
  <div className={styles.card}>
    <p>{user.email}</p>
    <div>
      <Link to={`${user.id}`}>
        <button className="btn">Szczegóły</button>
      </Link>
    </div>
  </div>
);

export default Card;

// - AUDIT
// - 25.06.2024
// - LOADING SUPPORT +
// - ERROR HANDLING +
// - SECURING INCOMING DATA +
// - SECURING OUTGOING DATA +
// - API CORRECTNESS +
// - CSS CORRECTNESS +
// - ACCESS TOKEN +
// - MOBILE 360PX +
// - BACKEND CONTROLER +
// - PASS
