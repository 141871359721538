import React from "react";
import CompanyList from "../../organisms/Dashboard/Company/CompanyList";
import InfoMessage from "../../atoms/General/InfoMessage";
import "../../organisms/Dashboard/Company/CompanyForm.css";
import "./Company.css";
import "../../organisms/General/Form.css";
import "../../style/Main.css";
import "../../organisms/General/Form.css";
import MobileButtons from "../../atoms/Dashboard/Button/MobileButton";
import DesktopButtons from "../../atoms/Dashboard/Button/DesktopButton";

/**
 * Represents the Company component.
 * This component displays a list of companies and provides options to view, modify, deactivate, or reactivate a company.
 *
 * @returns {JSX.Element} The rendered Company component.
 */
const Company = () => {
  return (
    <div className="company">
      <div className="company__btn">
        <InfoMessage additionalInfo="W tym miejscu znajdują się wszystkie Twoje firmy. Jeśli chcesz możesz zobaczyć lub zmodyfikować szczegóły danej firmy. Aby to zrobić kliknij edytuj. Możesz też dezaktywować firmę lub powtórnie ją aktywować." />
        <div>
          <DesktopButtons action="newCompany" />
          <MobileButtons action="newCompany" />
        </div>
      </div>
      <CompanyList />
    </div>
  );
};

export default Company;

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
