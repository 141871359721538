import React from "react";

/**
 * Renders the Home component.
 *
 * @returns {JSX.Element} The rendered Home component.
 */
const Home = () => {
  return (
    <>
      <div className="form__container">
        <h2>HOME</h2>
      </div>
    </>
  );
};

export default Home;

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
