import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { useParams, useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import handleError from "../../../utils/HandleError";
import AppStateContext from "../../../appState/AppStateContext";
import Message from "../../atoms/General/Message";
import Spinner from "../../atoms/General/Spinner";
import styles from "./EmployeeItem.module.css";

/**
 * Renders a form for editing employee details.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.buttonAction - The action to perform when a button is clicked.
 * @returns {JSX.Element} The rendered EmployeeItem component.
 */
const EmployeeItem = ({ buttonAction }) => {
  const navigate = useNavigate();
  const { selectedUser } = useParams();
  const { state, dispatch, cookieLoaded } = useContext(AppStateContext);

  const [userDetails, setUserDetails] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [noRole, setNoRole] = useState(false);
  const [successOccurred, setSuccessOccurred] = useState(false);
  const [incompletenessOccurred, setIncompletenessOccurred] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);

  useEffect(() => {
    if (cookieLoaded) {
      const fetchEmployeeDetails = async () => {
        setIsLoading(true);
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/user/local/${selectedUser}`,
            {
              headers: {
                Authorization: `Bearer ${state.accessToken}`,
                "Refresh-Token": state.userDetails.refreshToken,
              },
              withCredentials: true,
            }
          );

          if (
            response.data?.newAccessToken &&
            response.data.newAccessToken !== state.accessToken
          ) {
            dispatch({
              type: "UPDATE_ACCESS_TOKEN",
              payload: response.data.newAccessToken,
            });
          }

          if (response.status === 200) {
            setUserDetails(response.data.user);
            setFormValues(response.data.user);
            setInitialValues(response.data.user);
          }
        } catch (error) {
          Sentry.captureException(error, {
            extra: {
              message: "Error retrieving location.",
              component: "EmployeeItem",
            },
          });
          handleError(
            error,
            navigate,
            setNoRole,
            setIncompletenessOccurred,
            setErrorOccurred
          );
        } finally {
          setIsLoading(false);
        }
      };

      fetchEmployeeDetails();
    }
  }, [
    selectedUser,
    cookieLoaded,
    state.accessToken,
    state.userDetails.refreshToken,
    dispatch,
    navigate,
  ]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: DOMPurify.sanitize(value) });
  };

  const getChangedValues = () => {
    const changedValues = {};
    Object.keys(formValues).forEach((key) => {
      if (formValues[key] !== initialValues[key]) {
        changedValues[key] = formValues[key];
      }
    });
    return changedValues;
  };

  const handleSubmit = async () => {
    const changedValues = getChangedValues();
    if (!Object.keys(changedValues).length) return;

    try {
      setIsLoading(true);
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/user/local/${selectedUser}`,
        changedValues,
        {
          headers: {
            Authorization: `Bearer ${state.accessToken}`,
            "Refresh-Token": state.userDetails.refreshToken,
          },
          withCredentials: true,
        }
      );

      if (
        response.data?.newAccessToken &&
        response.data.newAccessToken !== state.accessToken
      ) {
        dispatch({
          type: "UPDATE_ACCESS_TOKEN",
          payload: response.data.newAccessToken,
        });
      }

      if (response.status === 200) {
        setSuccessOccurred(true);
      }
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          message: "Error updating local user",
          component: "EmployeeItem",
        },
      });
      handleError(
        error,
        navigate,
        setNoRole,
        setIncompletenessOccurred,
        setErrorOccurred
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Message
        success={successOccurred}
        incompleteness={incompletenessOccurred}
        error={errorOccurred}
        noRole={noRole}
        successMessage="Lokalizacja została zaktualizowana!"
        incompletenessMessage="Nie znaleziono potrzebnych danych na serwerze. Skontaktuj się z administratorem!"
        errorMessage="Wewnętrzny błąd serwera. Skontaktuj się z administratorem!"
        noRoleMessage="Brak odpowiednich uprawnień. Skontaktuj się z administratorem!"
      />
      {isLoading && <Spinner />}
      <div className={styles.employeeForm}>
        {userDetails && (
          <form className={styles.form}>
            <div className={styles.columns}>
              <div className={styles.column}>
                <div>
                  <label>
                    ID
                    <input
                      type="text"
                      value={formValues.id || ""}
                      disabled={true}
                      className={styles.myInput}
                    />
                  </label>
                </div>

                <div>
                  <label>
                    Email:
                    <input
                      type="text"
                      value={formValues.email || ""}
                      name="email"
                      onChange={handleInputChange}
                      disabled={buttonAction}
                      className={styles.myInput}
                    />
                  </label>
                </div>

                <label>
                  Rola:
                  <select
                    className={styles.mySelect}
                    value={formValues.roles || ""}
                    name="roles"
                    onChange={handleInputChange}
                    disabled={buttonAction}
                  >
                    <option value="manager">Manager</option>
                    <option value="operator">Operator</option>
                  </select>
                </label>
              </div>
              <div className={styles.column}>
                <label>
                  Użytkownik aktywny:
                  <select
                    className={styles.mySelect}
                    value={formValues.isActive}
                    name="isActive"
                    onChange={handleInputChange}
                    disabled={buttonAction}
                  >
                    <option value="true">Tak</option>
                    <option value="false">Nie</option>
                  </select>
                </label>

                <div>
                  <label>
                    Hasło:
                    <input
                      type="text"
                      value={formValues.visiblePassword || ""}
                      name="visiblePassword"
                      onChange={handleInputChange}
                      disabled={buttonAction}
                      className={styles.myInput}
                    />
                  </label>
                </div>

                <div>
                  <label>
                    Description:
                    <input
                      type="text"
                      value={formValues.description || ""}
                      name="description"
                      onChange={handleInputChange}
                      disabled={buttonAction}
                      className={styles.myInput}
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className={styles.btn}>
              <button
                type="button"
                onClick={handleSubmit}
                disabled={buttonAction}
              >
                Aktualizuj
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default EmployeeItem;

// - AUDIT
// - 25.06.2024
// - LOADING SUPPORT +
// - ERROR HANDLING +
// - SECURING INCOMING DATA +
// - SECURING OUTGOING DATA +
// - API CORRECTNESS +
// - CSS CORRECTNESS +
// - ACCESS TOKEN +
// - MOBILE 360PX +
// - BACKEND CONTROLER +
// - PASS`
