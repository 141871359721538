import React, { useState } from "react";
import { IoIosInformationCircleOutline } from "react-icons/io";
import "../../style/Main.css";
import "./InfoMessage.css";

/**
 * Renders an info message component with a toggleable additional information section.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.additionalInfo - The additional information to be displayed when toggled.
 * @returns {JSX.Element} The rendered InfoMessage component.
 */
const InfoMessage = ({ additionalInfo }) => {
  // Setting the application state
  const [showInfo, setShowInfo] = useState(false);

  // Local state switching
  const toggleInfo = () => {
    setShowInfo(!showInfo);
  };

  return (
    <div className="infoMessage">
      <div className="infoMessage__container">
        <div onClick={toggleInfo}>
          <IoIosInformationCircleOutline className="icon icon__background" />
        </div>
        {showInfo && (
          <div className="infoMessage__icon">
            <div onClick={toggleInfo} className="infoMessage__icon--close">
              &times;
            </div>
            <div className="infoMessage__deep">{additionalInfo}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InfoMessage;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
