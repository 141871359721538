import React, { useState, useContext } from "react";
import axios from "axios";
import * as Sentry from "@sentry/react";
import DOMPurify from "dompurify";
import { useParams, useNavigate } from "react-router-dom";
import AppStateContext from "../../../appState/AppStateContext";
import handleError from "../../../utils/HandleError";
import Message from "../../atoms/General/Message";
import Spinner from "../../atoms/General/Spinner";
import styles from "./LocationForm.module.css";

/**
 * LocationForm component is responsible for rendering a form to create a new location.
 * It handles user input, form submission, and displays success/error messages.
 *
 * @returns {JSX.Element} The LocationForm component.
 */
const LocationForm = () => {
  const { selectedCompany } = useParams();

  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppStateContext);
  const [locationName, setLocationName] = useState("");
  const [address, setAddress] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [localNumber, setLocalNumber] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [noRole, setNoRole] = useState(false);
  const [incompletenessOccurred, setIncompletenessOccurred] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);

  const handleChange = (event, setter) => {
    const sanitizedValue = DOMPurify.sanitize(event.target.value);
    setter(sanitizedValue);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setNoRole(false);
      setIncompletenessOccurred(false);
      setErrorOccurred(false);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/location/create/${selectedCompany}`,
        {
          locationName,
          address,
          streetNumber,
          localNumber,
          city,
          zipCode,
        },
        {
          headers: {
            Authorization: `Bearer ${state.accessToken}`,
            "Refresh-Token": state.userDetails.refreshToken,
          },
          withCredentials: true,
        }
      );

      if (
        response.data &&
        response.data.newAccessToken &&
        response.data.newAccessToken !== state.newAccessToken
      ) {
        dispatch({
          type: "UPDATE_ACCESS_TOKEN",
          payload: response.data.newAccessToken,
        });
      }
      if (response.status === 200) {
        setSuccess(true);
        setNoRole(false);
        setIncompletenessOccurred(false);
        setErrorOccurred(false);
      }
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          message: "Problem creating a new location",
          component: "LocationForm",
        },
      });
      handleError(
        error,
        navigate,
        setNoRole,
        setIncompletenessOccurred,
        setErrorOccurred
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className={styles.message}>
        <Message
          success={success}
          incompleteness={incompletenessOccurred}
          error={errorOccurred}
          noRole={noRole}
          successMessage="Lokalizacja została utworzon pomyślnie."
          incompletenessMessage="Nie znaleziono potrzebnych danych na serwerze. Skontaktuj się z administratorem!"
          errorMessage="Wewnętrzny błąd serwera. Skontaktuj się z administratorem!"
          noRoleMessage="Brak udpowiednich uprawnień. Skontaktuj się z administratorem!"
        />
      </div>

      {isLoading ? (
        <Spinner />
      ) : (
        <div className={styles.locationForm}>
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.columns}>
              <div className={styles.column}>
                <label>
                  Nazwa lokalizacji:
                  <input
                    type="text"
                    value={locationName}
                    onChange={(e) => handleChange(e, setLocationName)}
                  />
                </label>
                <label>
                  Adres:
                  <input
                    type="text"
                    value={address}
                    onChange={(e) => handleChange(e, setAddress)}
                  />
                </label>
                <label>
                  Numer ulicy:
                  <input
                    type="text"
                    value={streetNumber}
                    onChange={(e) => handleChange(e, setStreetNumber)}
                  />
                </label>
              </div>
              <div className={styles.column}>
                <label>
                  Numer lokalu:
                  <input
                    type="text"
                    value={localNumber}
                    onChange={(e) => handleChange(e, setLocalNumber)}
                  />
                </label>
                <label>
                  Miasto:
                  <input
                    type="text"
                    value={city}
                    onChange={(e) => handleChange(e, setCity)}
                  />
                </label>
                <label>
                  Kod pocztowy:
                  <input
                    type="text"
                    value={zipCode}
                    onChange={(e) => handleChange(e, setZipCode)}
                  />
                </label>
              </div>
            </div>
            <div className={styles.submitBtn}>
              <button type="submit">Utwórz</button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default LocationForm;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 *
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
