import React from "react";
import styles from "./SingleMenuItemModal.module.css";
import { IoIosLeaf, IoIosCloseCircleOutline } from "react-icons/io";
import { MdFiberNew } from "react-icons/md";
import { AiFillFire } from "react-icons/ai";

/**
 * Represents a modal component for displaying a single menu item.
 * @component
 * @param {Object} props - The props object.
 * @param {boolean} props.isOpen - Determines if the modal is open or not.
 * @param {function} props.onClose - The function to close the modal.
 * @param {number} props.quantity - The quantity of the menu item.
 * @param {function} props.incrementQuantity - The function to increment the quantity.
 * @param {function} props.decrementQuantity - The function to decrement the quantity.
 * @param {Object} props.menuItem - The menu item object.
 * @param {function} props.roundToTwoDecimals - The function to round a number to two decimals.
 * @param {function} props.addToOrder - The function to add the menu item to the order.
 * @param {string} props.language - The language of the menu item.
 * @returns {JSX.Element|null} The JSX element representing the single menu item modal.
 */
const SingleMenuItemModal = React.forwardRef((props, ref) => {
  const {
    isOpen,
    onClose,
    quantity,
    incrementQuantity,
    decrementQuantity,
    menuItem,
    roundToTwoDecimals,
    addToOrder,
    language,
  } = props;

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent} ref={ref}>
        <div className={styles.closeButton} onClick={onClose}>
          <IoIosCloseCircleOutline />
        </div>
        <div className={styles.modalMenuItem}>
          <img
            className={styles.modalMenuItemImage}
            src={menuItem.itemImageURL}
            alt={menuItem.namePL}
          />
          <h1 className={styles.menuItemTitle}>
            {language === "en" ? menuItem.nameEN : menuItem.namePL}
            {menuItem.size != "" ? (
              <span className={styles.icon}>{menuItem.size.toUpperCase()}</span>
            ) : (
              ""
            )}
            {menuItem.new === true ? (
              <MdFiberNew className={styles.icon} />
            ) : (
              ""
            )}
            {menuItem.vege === true ? (
              <IoIosLeaf className={styles.icon} />
            ) : (
              ""
            )}
            {menuItem.spicy === true ? (
              <AiFillFire className={styles.icon} />
            ) : (
              ""
            )}
          </h1>
          <p className={styles.description}>
            {language === "en"
              ? menuItem.descriptionEN
              : menuItem.descriptionPL}
          </p>
          <p className={styles.ingredients}>
            {language === "en" ? "Ingredients: " : "Składniki: "}
            {language === "en"
              ? menuItem.ingredientsEN
              : menuItem.ingredientsPL}
          </p>
          <div className={styles.orderBtns}>
            <div className={styles.quantityBtn__container}>
              <button
                className={styles.quantityBtn}
                onClick={decrementQuantity}
                disabled={quantity <= 0}
              >
                -
              </button>
              <span className={styles.quantityDisplay}>{quantity}</span>
              <button
                className={styles.quantityBtn + " " + styles.quantityBtnPlus}
                onClick={incrementQuantity}
              >
                +
              </button>
            </div>
            <span className={styles.dynamicPrice}>
              PLN {roundToTwoDecimals(menuItem.unitPrice * quantity)}
            </span>
            <button
              className={styles.addBtn}
              onClick={addToOrder}
              disabled={quantity === 0}
            >
              {language === "en" ? "Add to order" : "Dodaj do zamówienia"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default SingleMenuItemModal;
