import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import handleError from "../../../utils/HandleError";
import AppStateContext from "../../../appState/AppStateContext";
import Message from "../../atoms/General/Message";
import Spinner from "../../atoms/General/Spinner";
import styles from "./UserItem.module.css";

/**
 * Renders a user item component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.buttonAction - The button action function.
 * @returns {JSX.Element} The rendered UserItem component.
 */
const UserItem = ({ buttonAction }) => {
  const navigate = useNavigate();

  const { state, dispatch, cookieLoaded } = useContext(AppStateContext);

  const [userDetails, setUserDetails] = useState({});
  const [file, setFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const [noRole, setNoRole] = useState(false);
  const [successOccurred, setSuccessOccurred] = useState(false);
  const [incompletenessOccurred, setIncompletenessOccurred] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (cookieLoaded && state.userDetails.userSysID) {
      const fetchUserDetails = async () => {
        setIsLoading(true);
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/user/${state.userDetails.userSysID}`,
            {
              headers: {
                Authorization: `Bearer ${state.accessToken}`,
                "Refresh-Token": state.userDetails.refreshToken,
              },
              withCredentials: true,
            }
          );

          if (
            response.data &&
            response.data.newAccessToken &&
            response.data.newAccessToken !== state.newAccessToken
          ) {
            dispatch({
              type: "UPDATE_ACCESS_TOKEN",
              payload: response.data.newAccessToken,
            });
          }

          if (response.status === 200) {
            setUserDetails(response.data.user);
            setNoRole(false);
            setIncompletenessOccurred(false);
            setErrorOccurred(false);
          }
        } catch (error) {
          Sentry.captureException(error, {
            extra: {
              message: "Error retrieving profile details",
              component: "ProfileItem",
            },
          });
          handleError(
            error,
            navigate,
            setNoRole,
            setIncompletenessOccurred,
            setErrorOccurred
          );
        } finally {
          setIsLoading(false);
        }
      };

      fetchUserDetails();
    }
  }, [
    cookieLoaded,
    state.userDetails.userSysID,
    state.accessToken,
    dispatch,
    navigate,
  ]);

  const handleFormSubmit = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/update/${state.userDetails.userSysID}`,
        userDetails,
        {
          headers: {
            Authorization: `Bearer ${state.accessToken}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      if (
        response.data &&
        response.data.newAccessToken &&
        response.data.newAccessToken !== state.newAccessToken
      ) {
        dispatch({
          type: "UPDATE_ACCESS_TOKEN",
          payload: response.data.newAccessToken,
        });
      }

      if (response.status === 200) {
        setSuccessOccurred(true);
        setNoRole(false);
        setIncompletenessOccurred(false);
        setErrorOccurred(false);
      }
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          message: "Error updating profile details",
          component: "ProfileItem",
        },
      });
      handleError(
        error,
        navigate,
        setNoRole,
        setIncompletenessOccurred,
        setErrorOccurred
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleAvatarSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      setErrorMessage("Proszę wybrać plik.");
      return;
    }

    const formData = new FormData();
    formData.append("profileImage", file);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/aws/create/${state.userDetails.userSysID}/profile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${state.accessToken}`,
            "Refresh-Token": state.userDetails.refreshToken,
          },
          withCredentials: true,
        }
      );

      if (
        response.data &&
        response.data.newAccessToken &&
        response.data.newAccessToken !== state.newAccessToken
      ) {
        dispatch({
          type: "UPDATE_ACCESS_TOKEN",
          payload: response.data.newAccessToken,
        });
      }

      if (response.status === 200) {
        setSuccessOccurred(true);
        setNoRole(false);
        setIncompletenessOccurred(false);
        setErrorOccurred(false);
      }
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          message: "Error sending avatar profile",
          component: "ProfileItem",
        },
      });
      handleError(
        error,
        navigate,
        setNoRole,
        setIncompletenessOccurred,
        setErrorOccurred
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevState) => ({
      ...prevState,
      [name]: DOMPurify.sanitize(value),
    }));
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      alert("Nie wybrano pliku.");
      return;
    }

    const fileSizeLimit = 500 * 1024; // 500 KB
    const allowedTypes = ["image/jpeg", "image/png"];

    if (!allowedTypes.includes(selectedFile.type)) {
      setErrorMessage("Plik musi być w formacie JPG lub PNG.");
      return;
    }

    if (selectedFile.size > fileSizeLimit) {
      setErrorMessage("Plik nie może być większy niż 500 KB.");
      return;
    }

    setFile(selectedFile);
  };

  return (
    <div>
      <div className={styles.avatarContainer}>
        <img
          className={styles.avatar}
          src={userDetails.profileImageURL}
          alt=""
        />
        <form>
          <label className={styles.avatarLabel}>Wybierz awatar:</label>
          <input
            className={styles.myInput}
            type="file"
            onChange={handleFileChange}
            disabled={buttonAction}
          />
          <p className={styles.errorMessage}>{errorMessage}</p>
          <div className={styles.avatarBtn}>
            <button onClick={handleAvatarSubmit} disabled={buttonAction}>
              Prześlij
            </button>
          </div>
        </form>
      </div>
      <Message
        success={successOccurred}
        incompleteness={incompletenessOccurred}
        error={errorOccurred}
        noRole={noRole}
        successMessage="Profil użytkownika został zaktualizowany!"
        incompletenessMessage="Nie znaleziono potrzebnych danych na serwerze. Skontaktuj się z administratorem!"
        errorMessage="Wewnętrzny błąd serwera. Skontaktuj się z administratorem!"
        noRoleMessage="Brak udpowiednich uprawnień. Skontaktuj się z administratorem!"
      />
      {isLoading && <Spinner />}
      <div className={styles.userForm}>
        {userDetails && (
          <form className={styles.form}>
            <div className={styles.columns}>
              <div className={styles.column}>
                <div>
                  <label>ID</label>
                  <input
                    type="text"
                    value={userDetails.id || ""}
                    readOnly
                    className={styles.myInput}
                  />
                </div>

                <div>
                  <label>Imię:</label>
                  <input
                    type="text"
                    value={userDetails.firstName || ""}
                    name="firstName"
                    onChange={handleChange}
                    disabled={buttonAction}
                    className={styles.myInput}
                  />
                </div>

                <div>
                  <label>Adres:</label>
                  <input
                    type="text"
                    value={userDetails.address || ""}
                    name="address"
                    onChange={handleChange}
                    disabled={buttonAction}
                    className={styles.myInput}
                  />
                </div>

                <div>
                  <label>Numer mieszkania:</label>
                  <input
                    type="text"
                    value={userDetails.apartmentNumber || ""}
                    name="apartmentNumber"
                    onChange={handleChange}
                    disabled={buttonAction}
                    className={styles.myInput}
                  />
                </div>

                <div>
                  <label>Kod pocztowy:</label>
                  <input
                    type="text"
                    value={userDetails.postalCode || ""}
                    name="postalCode"
                    onChange={handleChange}
                    disabled={buttonAction}
                    className={styles.myInput}
                  />
                </div>
              </div>

              <div className={styles.column}>
                <div>
                  <label>Adres e-mail:</label>
                  <input
                    type="text"
                    value={userDetails.email || ""}
                    name="email"
                    readOnly
                    className={styles.myInput}
                  />
                </div>

                <div>
                  <label>Nazwisko:</label>
                  <input
                    type="text"
                    value={userDetails.lastName || ""}
                    name="lastName"
                    onChange={handleChange}
                    disabled={buttonAction}
                    className={styles.myInput}
                  />
                </div>

                <div>
                  <label>Numer domu:</label>
                  <input
                    type="text"
                    value={userDetails.houseNumber || ""}
                    name="houseNumber"
                    onChange={handleChange}
                    disabled={buttonAction}
                    className={styles.myInput}
                  />
                </div>

                <div>
                  <label>Miasto:</label>
                  <input
                    type="text"
                    value={userDetails.city || ""}
                    name="city"
                    onChange={handleChange}
                    disabled={buttonAction}
                    className={styles.myInput}
                  />
                </div>

                <div>
                  <label>Telefon:</label>
                  <input
                    type="text"
                    value={userDetails.phone || ""}
                    name="phone"
                    onChange={handleChange}
                    disabled={buttonAction}
                    className={styles.myInput}
                  />
                </div>
              </div>
            </div>
            <div className={styles.btn}>
              <button
                type="button"
                onClick={handleFormSubmit}
                disabled={buttonAction}
              >
                Aktualizuj
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default UserItem;

// - AUDIT
// - 25.06.2024
// - LOADING SUPPORT +
// - ERROR HANDLING +
// - SECURING INCOMING DATA +
// - SECURING OUTGOING DATA +
// - API CORRECTNESS +
// - CSS CORRECTNESS +
// - ACCESS TOKEN +
// - MOBILE 360PX +
// - BACKEND CONTROLER +
// - PASS
