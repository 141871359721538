import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AppStateContext from "../../../../appState/AppStateContext";
import { useLocation } from "react-router-dom";
import * as Sentry from "@sentry/react";
import DOMPurify from "dompurify";
import Message from "../../../atoms/General/Message";
import Spinner from "../../../atoms/General/Spinner";
import InfoMessage from "../../../atoms/General/InfoMessage";
import CompanyEditForm from "../../../molecules/Dashboard/Company/CompanyEdit";
import DesktopButtons from "../../../atoms/Dashboard/Button/DesktopButton";
import MobileButtons from "../../../atoms/Dashboard/Button/MobileButton";
import {
  validateNotEmpty,
  validateNIP,
  validateREGON,
  validateZipCode,
} from "../../../../utils/Validation";
import "./CompanyItem.css";

/**
 * Renders the CompanyItem component.
 * This component displays information about a company and allows editing of the company details.
 *
 * @returns {JSX.Element} The rendered CompanyItem component.
 */
const CompanyItem = () => {
  const navigate = useNavigate();

  // Retrieving the global state of the application, action and cookieLoaded
  const { state, dispatch, cookieLoaded } = useContext(AppStateContext);

  // Creating local application state
  const [company, setCompany] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [incompletenessOccurred, setIncompletenessOccurred] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [requestGet, setRequestGet] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [originalCompanyData, setOriginalCompanyData] = useState({});
  const [noRole, setNoRole] = useState(false);
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    nip: "",
    regon: "",
    address: "",
    streetNumber: "",
    localNumber: "",
    city: "",
    zipCode: "",
  });

  // Calling useLocation hook
  const location = useLocation();

  // A function that changes the application state in relation to form fields and errors
  const handleChange = (name, value) => {
    const sanitizedValue = DOMPurify.sanitize(value);

    setCompany((prevCompany) => ({
      ...prevCompany,
      [name]: sanitizedValue,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  // Function responsible for validation
  const validateForm = () => {
    const newErrors = {};
    if (!validateNotEmpty(company.firstName))
      newErrors.firstName = "Pole nie powinno być puste.";
    if (!validateNotEmpty(company.lastName))
      newErrors.lastName = "Pole nie powinno być puste.";
    if (!validateNotEmpty(company.companyName))
      newErrors.companyName = "Pole nie powinno być puste.";
    if (!validateNotEmpty(company.nip))
      newErrors.nip = "Pole nie powinno być puste.";
    if (!validateNIP(company.nip)) newErrors.nip = "Nieprawidłowy numer NIP.";
    if (!validateNotEmpty(company.regon))
      newErrors.regon = "Pole nie powinno być puste.";
    if (!validateREGON(company.regon))
      newErrors.regon = "Nieprawidłowy numer REGON.";
    if (!validateNotEmpty(company.address))
      newErrors.address = "Pole nie powinno być puste.";
    if (!validateNotEmpty(company.streetNumber))
      newErrors.streetNumber = "Pole nie powinno być puste.";
    if (!validateNotEmpty(company.localNumber))
      newErrors.localNumber = "Pole nie powinno być puste.";
    if (!validateNotEmpty(company.city))
      newErrors.city = "Pole nie powinno być puste.";
    if (!validateNotEmpty(company.zipCode))
      newErrors.zipCode = "Pole nie powinno być puste.";
    if (!validateZipCode(company.zipCode))
      newErrors.zipCode = "Nieprawidłowy kod pocztowy.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Editability toggle function
  const toggleEditable = () => {
    setIsEditable(!isEditable);
  };

  // A function that sends a form
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (!validateForm()) {
        return;
      }
      setIsSubmitting(true);

      const changedFields = {};
      for (const key in company) {
        if (
          company.hasOwnProperty(key) &&
          company[key] !== originalCompanyData[key]
        ) {
          changedFields[key] = company[key];
        }
      }

      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/company/${location.state.itemId}`,
        changedFields,
        {
          headers: {
            Authorization: `Bearer ${state.accessToken}`,
            "Refresh-Token": state.userDetails.refreshToken,
          },
          withCredentials: true,
        }
      );
      if (
        response.data.newAccessToken &&
        response.data.newAccessToken !== state.newAccessToken
      ) {
        dispatch({
          type: "UPDATE_ACCESS_TOKEN",
          payload: response.data.newAccessToken,
        });
      }
      if (response.status === 200) {
        setRegistrationSuccess(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/automatic/logout");
      } else if (error.response && error.response.status === 403) {
        setNoRole(true);
      } else if (error.response && error.response.status === 404) {
        setIncompletenessOccurred(true);
      } else {
        setErrorOccurred(true);
      }
      Sentry.captureException(error, {
        extra: {
          message: "Error updating company",
          component: "CompanyItems",
        },
      });
    } finally {
      setIsSubmitting(false);
      setIsEditable(false);
    }
  };

  // Getting the company item
  useEffect(() => {
    if (cookieLoaded) {
      const fetchCompaniesList = async () => {
        try {
          setLoadingSpinner(true);
          setRequestGet(true);
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/company/${location.state.itemId}`,
            {
              headers: {
                Authorization: `Bearer ${state.accessToken}`,
                "Refresh-Token": state.userDetails.refreshToken,
              },
              withCredentials: true,
            }
          );

          if (
            response.data.newAccessToken &&
            response.data.newAccessToken !== state.newAccessToken
          ) {
            dispatch({
              type: "UPDATE_ACCESS_TOKEN",
              payload: response.data.newAccessToken,
            });
          }
          if (response.status === 200) {
            const data = await response.data.company;
            setCompany(data);
            setOriginalCompanyData(data);
            setLoading(false);
            setLoadingSpinner(false);
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            navigate("/automatic/logout");
          } else if (error.response && error.response.status === 403) {
            setNoRole(true);
          } else if (error.response && error.response.status === 404) {
            setIncompletenessOccurred(true);
          } else {
            setErrorOccurred(true);
          }
          Sentry.captureException(error, {
            extra: {
              message: "Error while downloading individual company information",
              component: "CompanyItems",
            },
          });
        }
      };

      fetchCompaniesList();
    }
  }, [cookieLoaded]);

  // The condition is displayed during loading
  if (loading) {
    return (
      <div className="loading">
        <div className="loading__info">Ładowanie...</div>
        {loadingSpinner && (
          <div className="spinner">
            <Spinner />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="item">
      <div className="item__btn">
        <InfoMessage additionalInfo="W tym miejscu możesz edytować swoją firmę. Najpierw naciśnij przycisk odblokuj. Dokonaj odpowiednich modyfikacji i kliknij aktualizuj." />
        <div>
          <DesktopButtons
            action="editCompany"
            isEditable={isEditable}
            toggleEditable={toggleEditable}
          />
          <MobileButtons
            action="editCompany"
            isEditable={isEditable}
            toggleEditable={toggleEditable}
          />
        </div>
      </div>
      <div className="item__status">
        <div className={company.isActive ? "dot--green" : "dot--red"}></div>
        <h1>Firma {company.companyName}</h1>
      </div>
      <div className="item__container">
        <div className="item__message">
          {requestGet ? (
            <Message
              success={registrationSuccess}
              incompleteness={incompletenessOccurred}
              error={errorOccurred}
              noRole={noRole}
              successMessage="Dane firmy zostały pobrane poprawnie!"
              incompletenessMessage="Dane firmy są nie kompletne. Skontaktuj się z administratorem!"
              errorMessage="Dane firmy nie zostały pobrane poprawnie. Skontaktuj się z administratorem!"
              noRoleMessage="Brak odpowiedniej roli. Skontaktuj się z administratorem!"
            />
          ) : (
            <Message
              success={registrationSuccess}
              incompleteness={incompletenessOccurred}
              error={errorOccurred}
              noRole={noRole}
              successMessage="Firma została zaktualizowana poprawnie!"
              incompletenessMessage="Firma nie została zaktualizowana poprawnie. Skontaktuj się z administratorem!"
              errorMessage="Firma nie została zaktualizowana poprawnie. Skontaktuj się z administratorem!"
              noRoleMessage="Brak odpowiedniej roli. Skontaktuj się z administratorem!"
            />
          )}
          {isSubmitting && (
            <div className="spinner">
              <Spinner />
            </div>
          )}
        </div>
        <CompanyEditForm
          company={company}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          isEditable={isEditable}
          error={errors}
        />
      </div>
    </div>
  );
};

export default CompanyItem;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
