import React, { useEffect, useRef, useState } from "react";
import FlagsComponent from "../../../utils/flags/FlagsComponent";
import logo from "../../../img/wolt-removebg-preview.png";
import styles from "./Header.module.css";

/**
 * Header component for the profile page.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.location - The location object.
 * @param {number} props.totalOrderItems - The total number of order items.
 * @param {Object} props.order - The order object.
 * @param {Function} props.openModal - The function to open the modal.
 * @param {Function} props.closeModal - The function to close the modal.
 * @param {Function} props.handleLanguage - The function to change website language.
 * @param {string} props.language - The current language.
 * @returns {JSX.Element} The rendered Header component.
 */
const Header = ({
  location,
  totalOrderItems,
  order,
  openModal,
  closeModal,
  handleLanguage,
  language,
}) => {
  const [scrolled, setScrolled] = useState(false);
  const [open, setOpen] = useState(false);
  const backgroundRef = useRef(null);

  /**
   * Toggles the visibility of the order.
   */
  const toggleOrderVisibility = () => {
    if (!open) {
      openModal();
      setOpen(true);
    } else {
      closeModal();
      setOpen(false);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setScrolled(!entry.isIntersecting),
      { threshold: 0 }
    );

    if (backgroundRef.current) {
      observer.observe(backgroundRef.current);
    }

    return () => {
      if (backgroundRef.current) {
        observer.unobserve(backgroundRef.current);
      }
    };
  }, []);

  /**
   * Handles the click event for a category.
   * Scrolls the page to the element with the specified id.
   *
   * @param {string} id - The id of the element to scroll to.
   */
  const handleCategoryClick = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <div
        ref={backgroundRef}
        className={styles.background}
        style={{ backgroundImage: `url(${location.backgroundURL})` }}
      >
        <div className={styles.header}>
          <img src={logo} alt="Logo" className={styles.logo} />
          <div className={styles.containerCart}>
            <div
              onClick={toggleOrderVisibility}
              className={`${styles.cartButton} ${
                totalOrderItems > 0 ? styles.fadeIn : styles.fadeOut
              }`}
            >
              {totalOrderItems > 0 && (
                <>
                  <span className={styles.cartItemCount}>
                    {totalOrderItems}
                  </span>
                  <span className={styles.cartTotalPrice}>
                    PLN {order.totalAmount}
                  </span>
                </>
              )}
            </div>
            <span onClick={toggleOrderVisibility} className={styles.cartIcon}>
              🛒
            </span>

            <div className={styles.language}>
              <FlagsComponent handleLanguage={handleLanguage} />
            </div>
          </div>
        </div>

        <div className={styles.locationHeader}>
          <img
            className={styles.locationHeader__image}
            src={location.logoURL}
            alt={location.locationName}
          />
          <div className={styles.locationHeader__details}>
            <h1 className={styles.locationName}>{location.locationName}</h1>
            <h4 className={styles.locationDescription}>
              {language === "en"
                ? location.shortDescriptionEN
                : location.shortDescriptionPL}
            </h4>
          </div>
        </div>
      </div>

      {scrolled && (
        <div className={styles.stickyHeader}>
          <div className={styles.stickyHeaderContent}>
            <img src={logo} alt="Logo" className={styles.stickyLogo} />

            <div className={styles.stickyContainerCart}>
              <div
                onClick={toggleOrderVisibility}
                className={`${styles.stickyCartButton} ${
                  totalOrderItems > 0 ? styles.fadeIn : styles.fadeOut
                }`}
              >
                {totalOrderItems > 0 && (
                  <>
                    <span className={styles.cartItemCount}>
                      {totalOrderItems}
                    </span>
                    <span className={styles.cartTotalPrice}>
                      PLN {order.totalAmount}
                    </span>
                  </>
                )}
              </div>

              <span onClick={toggleOrderVisibility} className={styles.cartIcon}>
                🛒
              </span>

              <div className={styles.language}>
                <FlagsComponent handleLanguage={handleLanguage} />
              </div>
            </div>
          </div>
          <div className={styles.categoriesBar}>
            {location.categories.map((category) => (
              <span
                key={category.id}
                className={styles.categoryLink}
                onClick={() => handleCategoryClick(category.id)}
              >
                {language === "en" ? category.nameEN : category.namePL}
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
