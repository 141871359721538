import React from "react";
import SummaryDashboardOrder from "../organisms/SummaryDashboardOrder";

const Order = () => {
    
  return (
    <div>
      <SummaryDashboardOrder/>
    </div>
  );
};

export default Order;
