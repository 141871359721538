/**
 * Creates a new context object. When React renders a component that subscribes
 * to this Context object, it will read the current context value from the
 * closest matching Provider above it in the tree.
 *
 * @typedef {Object} Context
 * @property {Function} Provider - The Provider component which allows consuming components to subscribe to context changes.
 * @property {Function} Consumer - The Consumer component which subscribes to context changes within a functional component.
 * @property {Function} displayName - A property used to identify the context in React Developer Tools.
 */

/**
 * Creates a context object.
 *
 * @function createContext
 * @returns {Context} A Context object.
 */

import { createContext } from "react";

/**
 * Context object for managing application state.
 * When a component subscribes to this context, it can access and update
 * the state provided by the nearest AppStateProvider component in the component tree.
 *
 * @type {Context}
 */
const AppStateContext = createContext();

export default AppStateContext;
