import React from "react";
import { Routes, Route } from "react-router-dom";
import Logout from "./LogoutForm";
import Company from "../../pages/Dashboard/Company";
import CompanyForm from "../Dashboard/Company/CompanyForm";
import CompanyDetails from "./Company/CompanyItem";
import Footer from "../../pages/Home/Footer";
import License from "../../pages/Dashboard/License";
import LicenseForm from "../../organisms/Dashboard/License/LicenseForm";
import StepSeven from "../../molecules/Dashboard/Stepper/Content/StepSeven";
import LicenseDetails from "./License/LicenseItem";
import Location from "../../location/pages/Location";
import LocationNewItem from "../../location/pages/LocationFormPage";
import LocationItemPage from "../../location/pages/LocationItemPage";
import Category from "../../category/pages/Category";
import CategoryItemPage from "../../category/pages/CategoryItemPage";
import CategoryNew from "../../category/pages/CategoryFormPage";
import MenuItemPageForCategory from "../../category/pages/MenuItemPageForCategory";
import MenuFormPageForCategory from "../../category/pages/MenuFormPageForCategory";
import Menu from "../../menu/pages/Menu";
import MenuItemPage from "../../menu/pages/MenuItemPage";
import MenuFormPage from "../../menu/pages/MenuFormPage";
import Credential from "../../credential/pages/Credential";
import CredentialItemPage from "../../credential/pages/CredentialItemPage";
import CredentialFormPage from "../../credential/pages/CredentialFormPage";
import User from "../../user/pages/User";
import ChangeUserPassword from "../../user/pages/ChangeUserPassword";
import Employee from "../../employee/pages/Employee";
import EmployeeFormPage from "../../employee/pages/EmployeeFormPage";
import EmployeeItemPage from "../../employee/pages/EmployeeItemPage";
import Order from "../../order/pages/Order";
import "./Main.css";

/**
 * Component responsible for rendering different pages based on the current route.
 * @returns {JSX.Element} Main component.
 */
const Main = () => {
  return (
    <>
      <div className="main">
        <Routes>
          <Route path="logout" element={<Logout />} />
          <Route path="company" element={<Company />} />
          <Route path="company/create" element={<CompanyForm />} />
          <Route path="company/read/:id" element={<CompanyDetails />} />
          <Route path="license" element={<License />} />
          <Route path="license/create" element={<LicenseForm />} />
          {/* <Route path="license/create/finish/:transactionID/" element={<StepSeven />} /> */}
          <Route
            path="license/create/finish/:transactionID/:contractID/:operation"
            element={<StepSeven />}
          />
          <Route
            path="license/read/:id/:operation"
            element={<LicenseDetails />}
          />
          <Route path="location" element={<Location />} />
          <Route
            path="location/create/:selectedCompany"
            element={<LocationNewItem />}
          />
          <Route path="location/:id" element={<LocationItemPage />} />
          <Route path="category" element={<Category />} />
          <Route path="category/:id" element={<CategoryItemPage />} />
          <Route
            path="category/:id/:item"
            element={<MenuItemPageForCategory />}
          />

          <Route
            path="category/create/:selectedLocation"
            element={<CategoryNew />}
          />
          <Route
            path="category/:selectedCategory/item"
            element={<MenuFormPageForCategory />}
          />
          <Route path="menu" element={<Menu />} />
          <Route path="menu/:id" element={<MenuItemPage />} />
          <Route
            path="menu/create/:selectedCategory"
            element={<MenuFormPage />}
          />
          <Route
            path="category/:selectedCategory/:selectedMenu"
            element={<MenuItemPage />}
          />
          <Route path="credential" element={<Credential />} />
          <Route path="credential/:id" element={<CredentialItemPage />} />
          <Route path="credential/create" element={<CredentialFormPage />} />
          <Route path="user" element={<User />} />
          <Route path="user/password" element={<ChangeUserPassword />} />
          <Route path="location/:selectedLocation/employee" element={<Employee />} />
          <Route path="location/:selectedLocation/employee/create" element={<EmployeeFormPage />} />
          <Route
            path="location/:selectedLocation/employee/:selectedUser"
            element={<EmployeeItemPage />}
          />
          <Route path="order" element={<Order />} />

        </Routes>

        {/* <div className="main__footer">
        <Footer />
      </div> */}
      </div>
    </>
  );
};

export default Main;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
