import React from "react";
import DesktopButtons from "../../../atoms/Dashboard/Button/DesktopButton";
import MobileButtons from "../../../atoms/Dashboard/Button/MobileButton";
import InfoMessage from "../../../atoms/General/InfoMessage";
import HorizontalStepper from "../../../molecules/Dashboard/Stepper/HorizontalStepper";
import "./LicenseForm.css";

/**
 * Renders the LicenseForm component.
 *
 * @returns {JSX.Element} The rendered LicenseForm component.
 */
const LicenseForm = () => {
  return (
    <div className="license">
      <div className="message--info">
        <InfoMessage additionalInfo="W tym miejscu możesz dodać nową licencję postępując zgodnie z poniższymi instrukcjami!" />
        <div>
          <DesktopButtons />
          <MobileButtons />
        </div>
      </div>
      <h1 className="license__h1">UTWÓRZ NOWĄ LICENCJĘ</h1>
      <HorizontalStepper />
    </div>
  );
};

export default LicenseForm;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER+
 *
 * PASS
 ***/
