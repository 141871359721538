import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as Sentry from "@sentry/react";
import AppStateContext from "../../../appState/AppStateContext";
import Message from "../../atoms/General/Message";
import Spinner from "../../atoms/General/Spinner";
import Pagination from "../../atoms/General/Pagination";
import handleError from "../../../utils/HandleError";
import Card from "../../menu/molecules/MenuCard";
import styles from "./MenuItemsListForCategory.module.css";

/**
 * Renders a list of menu items for a selected category.
 *
 * @component
 * @param {Object} selected - The selected category object.
 * @returns {JSX.Element} The rendered component.
 */
const MenuItemsListForCategory = ({ selected }) => {
  const navigate = useNavigate();

  const { state, dispatch, cookieLoaded } = useContext(AppStateContext);

  const [menuItems, setMenuItems] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [noRole, setNoRole] = useState(false);
  const [successOccurred, setSuccessOccurred] = useState(false);
  const [incompletenessOccurred, setIncompletenessOccurred] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [menuItemsPerPage] = useState(3);

  const [render, setRender] = useState(false);

  useEffect(() => {
    if (cookieLoaded) {
      const fetchMenuItems = async () => {
        setSuccessOccurred(false);
        setNoRole(false);
        setIncompletenessOccurred(false);
        setErrorOccurred(false);
        setMenuItems([]);
        setIsLoading(true);
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/menu/all/${selected.id}`,
            {
              headers: {
                Authorization: `Bearer ${state.accessToken}`,
                "Refresh-Token": state.userDetails.refreshToken,
              },
              withCredentials: true,
            }
          );

          if (
            response.data &&
            response.data.newAccessToken &&
            response.data.newAccessToken !== state.newAccessToken
          ) {
            dispatch({
              type: "UPDATE_ACCESS_TOKEN",
              payload: response.data.newAccessToken,
            });
          }

          if (response.status === 200 && Array.isArray(response.data.menu)) {
            setMenuItems(response.data.menu);
            setNoRole(false);
            setIncompletenessOccurred(false);
            setErrorOccurred(false);
          }
        } catch (error) {
          Sentry.captureException(error, {
            extra: {
              message: "Error retrieving all menu items connected to category",
              component: "MenuItemList",
            },
          });
          handleError(
            error,
            navigate,
            setNoRole,
            setIncompletenessOccurred,
            setErrorOccurred
          );
        } finally {
          setIsLoading(false);
        }
      };
      fetchMenuItems();
    }
  }, [selected, render]);

  const indexOfLastMenuItem = currentPage * menuItemsPerPage;
  const indexOfFirstMenuItem = indexOfLastMenuItem - menuItemsPerPage;
  const currentMenuItems = menuItems.slice(
    indexOfFirstMenuItem,
    indexOfLastMenuItem
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleDelete = async (menuItemID) => {
    try {
      setSuccessOccurred(false);
      setNoRole(false);
      setIncompletenessOccurred(false);
      setErrorOccurred(false);
      setIsLoading(true);
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/menu/${menuItemID}`,
        {
          headers: {
            Authorization: `Bearer ${state.accessToken}`,
            "Refresh-Token": state.userDetails.refreshToken,
          },
          withCredentials: true,
        }
      );

      if (
        response.data &&
        response.data.newAccessToken &&
        response.data.newAccessToken !== state.newAccessToken
      ) {
        dispatch({
          type: "UPDATE_ACCESS_TOKEN",
          payload: response.data.newAccessToken,
        });
      }

      if (response.status === 200) {
        setSuccessOccurred(true);
        setRender(!render);}
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          message: "Error deleting category",
          component: "CategoryList",
        },
      });
      handleError(
        error,
        navigate,
        setNoRole,
        setIncompletenessOccurred,
        setErrorOccurred
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h1 className={styles.title}>MENU</h1>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className={styles.content}>
          <div className={styles.message}>
            <Message
              success={successOccurred}
              incompleteness={incompletenessOccurred}
              error={errorOccurred}
              noRole={noRole}
              successMessage="Kategoria została usunięta!"
              incompletenessMessage="Nie znaleziono potrzebnych danych na serwerze. Skontaktuj się z administratorem!"
              errorMessage="Wewnętrzny błąd serwera. Skontaktuj się z administratorem!"
              noRoleMessage="Brak udpowiednich uprawnień. Skontaktuj się z administratorem!"
            />
          </div>
          <div className={styles.card}>
            {currentMenuItems.map((item) => (
              <Card key={item.id} menuItem={item} handleDelete={handleDelete} />
            ))}
          </div>
          <Pagination
            itemsPerPage={menuItemsPerPage}
            totalItems={menuItems.length}
            paginate={paginate}
          />
        </div>
      )}
    </div>
  );
};

export default MenuItemsListForCategory;
