import React, { useState, useContext } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import DOMPurify from "dompurify";
import AppStateContext from "../../../appState/AppStateContext";
import Spinner from "../../atoms/General/Spinner";
import ButtonSpinner from "./ButtonSpinner";
import Message from "../../atoms/General/Message";
import "./Modal.css";

/**
 * Modal component for displaying a modal dialog.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.action - The action to be performed by the modal.
 * @param {boolean} props.isOpen - Flag indicating whether the modal is open or not.
 * @param {function} props.onClose - Callback function to close the modal.
 * @param {string} props.title - The title of the modal.
 * @param {string} props.content - The content of the modal.
 * @param {string} props.contractID - The ID of the contract.
 * @returns {JSX.Element|null} The modal component.
 */
const Modal = ({ action, isOpen, onClose, title, content, contractID }) => {
  const navigate = useNavigate();
  // Global state
  const { state, dispatch } = useContext(AppStateContext);
  // Local state
  const [activationCode, setActivationCode] = useState("");
  const [selectedValue, setSelectedValue] = useState({ key: 1, value: 899 });
  const [loading, setLoading] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [loadingPay, setLoadingPay] = useState(false);
  const [successOccurred, setSuccessOccurred] = useState(false);
  const [warningOccurred, setWarningOccurred] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [noRole, setNoRole] = useState(false);
  const [hiddenContent, setHiddenContent] = useState(false);
  const [disabledButtonPay, setDisabledButtonPay] = useState(true);
  const [disabledButtonConfirm, setDisabledButtonConfirm] = useState(false);
  const [disabledButtonBack, setDisabledButtonBack] = useState(true);
  const [disabledFieldSelect, setDisabledFieldSelect] = useState(false);
  const [URLToPay, setURLToPay] = useState("");
  if (!isOpen) {
    return null;
  }

  // Function responsible for returning a value based on the provided key
  const handleChange = (e) => {
    const rawValue = DOMPurify.sanitize(e.target.value);
    const key = parseInt(rawValue, 10);
    if (isNaN(key) || !Number.isInteger(key)) {
      console.error("Invalid input: value is not a valid integer.");
      return;
    }
    let value;
    switch (key) {
      case 1:
        value = 899;
        break;
      case 3:
        value = 2550;
        break;
      case 6:
        value = 4800;
        break;
      case 12:
        value = 9000;
        break;
      default:
        value = 0;
    }
    setSelectedValue({ key, value });
  };

  // Function responsible for returning a value based on the provided key
  const handleChangeActivation = (e) => {
    const rawValue = DOMPurify.sanitize(e.target.value);
    setActivationCode(rawValue);
  };

  // Function responsible for transforming values
  const transformAmount = (amountPln) => {
    amountPln = amountPln.toString().replace(/\s|zł/g, "");
    if (isNaN(amountPln)) {
      return;
    }
    var amountGr = parseInt(amountPln) * 100;
    return amountGr;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (action === "activation") {
      setLoading(true);
      setLoadingSpinner(true);
      const activationLicense = async () => {
        try {
          const code = {
            activationCode,
          };
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/contract/activation`,
            code,
            {
              headers: {
                Authorization: `Bearer ${state.accessToken}`,
                "Refresh-Token": state.userDetails.refreshToken,
              },
              withCredentials: true,
            }
          );
          if (
            response.data.accessToken &&
            response.data.accessToken !== state.accessToken
          ) {
            dispatch({
              type: "UPDATE_ACCESS_TOKEN",
              payload: response.data.accessToken,
            });
          }
          if (response.status === 200) {
            setLoading(false);
            setLoadingSpinner(false);
            setSuccessOccurred(true);
            setHiddenContent(true);
          } else {
            setLoading(false);
            setLoadingSpinner(false);
            setWarningOccurred(true);
            setHiddenContent(true);
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            navigate("/automatic/logout");
          } else if (error.response && error.response.status === 403) {
            setNoRole(true);
            setLoading(false);
            setLoadingSpinner(false);
          } else if (error.response && error.response.status === 404) {
            setWarningOccurred(true);
            setLoading(false);
            setLoadingSpinner(false);
          } else {
            setErrorOccurred(true);
            setLoading(false);
            setLoadingSpinner(false);
          }
          setHiddenContent(true);
          Sentry.captureException(error, {
            extra: {
              message: "First license activation error",
              component: "Modal",
            },
          });
        }
      };
      activationLicense();
    }

    if (action === "renew") {
      setDisabledButtonConfirm(true);
      setDisabledButtonBack(false);
      setDisabledFieldSelect(true);
      setLoadingPay(true);

      const purchaseData = {
        userId: state.userDetails.userSysID,
        products: [
          {
            name: `Subskrypcja: '${selectedValue.key}'`,
            unitPrice: transformAmount(selectedValue.value),
            quantity: "1",
          },
        ],
        totalAmount: transformAmount(selectedValue.value),
        description: `Subskrypcja: '${selectedValue.key}'`,
        currencyCode: "PLN",
        operation: "renewContract",
        contract: contractID,
      };

      const renewLicense = async () => {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/pay`,
            purchaseData,
            {
              headers: {
                Authorization: `Bearer ${state.accessToken}`,
                "Refresh-Token": state.userDetails.refreshToken,
              },
              withCredentials: true,
            }
          );
          if (
            response.data.accessToken &&
            response.data.accessToken !== state.accessToken
          ) {
            dispatch({
              type: "UPDATE_ACCESS_TOKEN",
              payload: response.data.accessToken,
            });
          }
          if (response.status === 200) {
            setURLToPay(response.data.orderResponse.redirectUri);
            setDisabledButtonPay(false);
            setLoadingPay(false);
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            navigate("/automatic/logout");
          } else if (error.response && error.response.status === 403) {
            setNoRole(true);
            setLoading(false);
            setLoadingSpinner(false);
            setLoadingPay(false);
          } else if (error.response && error.response.status === 400) {
            setWarningOccurred(true);
            setLoading(false);
            setLoadingSpinner(false);
            setLoadingPay(false);
          } else {
            setErrorOccurred(true);
            setLoading(false);
            setLoadingSpinner(false);
            setLoadingPay(false);
          }
          Sentry.captureException(error, {
            extra: {
              message: "License renewal",
              component: "Modal",
            },
          });
        }
      };
      renewLicense();
    }
  };

  // Back action management, visibility of renewal buttons
  const handleBack = (e) => {
    setNoRole(false);
    setWarningOccurred(false);
    setErrorOccurred(false);
    setDisabledButtonConfirm(false);
    setDisabledButtonPay(true);
    setDisabledButtonBack(true);
    setDisabledFieldSelect(false);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          X
        </button>
        <h2 className="modal__element--mobile">{title}</h2>
        <span className="modal__element--mobile">{content}</span>
        {!hiddenContent && action === "activation" && (
          <>
            <form className="modal__form" onSubmit={handleSubmit}>
              <input
                type="text"
                id="activationCode"
                value={activationCode}
                onChange={handleChangeActivation}
                required
              />
              <button className="btn modal__btn" type="submit">
                Aktywuj
              </button>
            </form>
          </>
        )}

        {!hiddenContent && action === "renew" && (
          <>
            <form onSubmit={handleSubmit} className="modal__form">
              <select
                id="activationCode"
                value={selectedValue.key}
                onChange={handleChange}
                required
                disabled={disabledFieldSelect}
              >
                <option value="1">1 miesiąc</option>
                <option value="3">3 miesiące</option>
                <option value="6">6 miesięcy</option>
                <option value="12">12 miesięcy</option>
              </select>
            </form>
            <div className="modal__element--mobile">
              <b>Suma:</b> {selectedValue.value} zł.
            </div>
            <br />

            <div className="modal__container--btn">
              <Link to={URLToPay}>
                <button
                  className="btn modal__btn--pay"
                  disabled={disabledButtonPay}
                >
                  {loadingPay ? (
                    <span className="loading">
                      <span className="spinner">
                        <ButtonSpinner />
                      </span>
                    </span>
                  ) : (
                    <span>Opłać</span>
                  )}
                </button>
              </Link>

              {disabledButtonBack && (
                <button
                  className="btn modal__btn--pay"
                  onClick={handleSubmit}
                  disabled={disabledButtonConfirm}
                >
                  Potwierdź
                </button>
              )}
              {disabledButtonConfirm && (
                <button
                  className="btn"
                  onClick={handleBack}
                  disabled={disabledButtonBack}
                >
                  Cofnij
                </button>
              )}
            </div>
          </>
        )}

        <div className="modal__message">
          <Message
            success={successOccurred}
            warning={warningOccurred}
            error={errorOccurred}
            noRole={noRole}
            successMessage="Licencja została aktywowana prawidłowo. Dziękujemy za zaufanie!"
            warningMessage="Przesłane dane są niekompletne. Skontaktuj się z administratorem!"
            errorMessage="Połączenie z serwerem zakończone błędem. Skontaktuj się z administratorem!"
            noRoleMessage="Brak odpowiedniej roli. Skontaktuj się z administratorem!"
          />
        </div>

        {loading && (
          <div className="loading">
            {loadingSpinner && (
              <div className="spinner">
                <Spinner />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER+
 *
 * PASS
 ***/
