/**
 * Analyzes a string and returns the corresponding job position based on the presence of certain keywords.
 *
 * @param {string} inputString - The input string to be analyzed.
 * @returns {string} - The job position found in the input string. If no suitable position is found, returns "Brak odpowiedniego stanowiska".
 */
const analyzeString = (inputString) => {
  // Split the string into an array of words
  const words = inputString.split(" ");

  // Capitalize the first letter of each word
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  // Check if the array contains "Administrator"
  if (capitalizedWords.includes("Administrator")) {
    return "Administrator";
  }

  // Check if the array contains "Manager"
  if (capitalizedWords.includes("Manager")) {
    return "Manager";
  }

  // Check if the array contains "Operator"
  if (capitalizedWords.includes("Operator")) {
    return "Operator";
  }

  // If neither Administrator, Manager, nor Operator is found
  return "Brak odpowiedniego stanowiska";
};

export default analyzeString;

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
