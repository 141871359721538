import React from "react";
import styles from "./Card.module.css";

/**
 * Card component to display menu items.
 *
 * @param {Object} props - The properties object.
 * @param {Object} props.menuItem - The menu item object.
 * @param {Function} props.openModal - Function to open a modal.
 * @param {string} props.language - The language code.
 * @returns {JSX.Element} - The rendered Card component.
 */
const Card = ({ menuItem, openModal, language }) => {
  /**
   * Truncates the given name if it exceeds 10 characters.
   *
   * @param {string} name - The name to be truncated.
   * @returns {string} - The truncated name.
   */
  const truncateName = (name) => {
    return name.length > 15 ? `${name.slice(0, 20)}...` : name;
  };

  /**
   * Truncates the given ingredients string if it exceeds 50 characters.
   *
   * @param {string} ingredients - The ingredients string to be truncated.
   * @returns {string} - The truncated ingredients string.
   */
  const truncateIngredients = (ingredients) => {
    return ingredients.length > 35
      ? `${ingredients.slice(0, 35)}...`
      : ingredients;
  };

  return (
    <div className={styles.cardBox}>
      <div className={styles.cardDescription}>
      
          <h3>
            {language === "en"
              ? truncateName(menuItem.nameEN)
              : truncateName(menuItem.namePL)}
          </h3>
          <p>
            {language === "en" ? "Ingredients: " : "Składniki: "}
            {language === "en"
              ? truncateIngredients(menuItem.ingredientsEN)
              : truncateIngredients(menuItem.ingredientsPL)}
          </p>
       
        <div className={styles.cardAction}>
          <p>
            <b>PLN {menuItem.unitPrice}</b>
          </p>
        </div>
     

      <div className={styles.cardSingleImage}>
        <button
          className={styles.cardButtonPlus}
          onClick={() => openModal(menuItem)}
        >
          +
        </button>
        <img
          className={styles.cardImage}
          src={menuItem.itemImageURL}
          alt={menuItem.nameEN}
        />
      </div>
      </div>
    </div>
  );
};

export default Card;
