import React, { useContext } from "react";
import { Link } from "react-router-dom";
import AppStateContext from "../../../../appState/AppStateContext";
import analyzeString from "../../../../utils/AnalyzeString";
import { AiOutlineMenuFold } from "react-icons/ai";
import { CiLocationArrow1, CiWallet } from "react-icons/ci";
import { IoMdLogOut } from "react-icons/io";
import { GrUserManager } from "react-icons/gr";
import { LuHelpCircle } from "react-icons/lu";
import { CgProfile } from "react-icons/cg";
import { RiAdminLine, RiContractLine } from "react-icons/ri";
import { BiCategory, BiFoodMenu, BiAddToQueue } from "react-icons/bi";
import { IoPersonOutline } from "react-icons/io5";
import { HiOutlineBuildingStorefront } from "react-icons/hi2";
import logo from "../../../../img/logo_2.jpg";
import "../../../organisms/Dashboard/Navigation.css";

/**
 * Renders the desktop sidebar component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.isClose - Indicates whether the sidebar is closed or not.
 * @param {Function} props.handleCloseDesktop - The function to handle closing the desktop sidebar.
 * @returns {JSX.Element} The JSX element representing the desktop sidebar.
 */
const DesktopSidebar = ({ isClose, handleCloseDesktop }) => {
  // Getting global state
  const { state } = useContext(AppStateContext);

  // Role formatting
  let role;
  if (typeof state.userDetails.roles === "string") {
    role = analyzeString(state.userDetails.roles);
  }

  return (
    <>
      {!isClose && (
        <span
          href="#"
          className="btn__desktop--close"
          onClick={handleCloseDesktop}
        >
          <AiOutlineMenuFold className="icon" />
        </span>
      )}
      <div
        className="sidebar__desktop"
        style={{ transform: isClose ? "translateX(-100%)" : "translateX(0)" }}
      >
        <div className="sidebar__logo">
          <img src={logo} alt="Logo" />
        </div>
        <p>Dashboard</p>
        <ul>
          {role === "Administrator" ? (
            <li>
              <RiAdminLine />
              <Link to="menu">Administrator</Link>
            </li>
          ) : null}
          {role === "Administrator" || role === "Manager" ? (
            <li>
              <GrUserManager />
              <a href="#">Manager</a>
            </li>
          ) : null}
          {role === "Administrator" ||
          role === "Manager" ||
          role === "Operator" ? (
            <li>
              <IoPersonOutline />
              <Link to="order">Operator</Link>
            </li>
          ) : null}
        </ul>
        {role === "Administrator" || role === "Manager" ? (
          <p>Szczegóły</p>
        ) : null}
        <ul>
          {role === "Administrator" || role === "Manager" ? (
            <li>
              <HiOutlineBuildingStorefront />
              <Link to="company">Firma</Link>
            </li>
          ) : null}
          {role === "Administrator" || role === "Manager" ? (
            <li>
              <CiLocationArrow1 />
              <Link to="location">Lokalizacja</Link>
            </li>
          ) : null}
          {role === "Administrator" || role === "Manager" ? (
            <li>
              <BiCategory />
              <Link to="category">Kategoria</Link>
            </li>
          ) : null}
          {/* {role === "Administrator" || role === "Manager" ? (
            <li>
              <BiAddToQueue />
              <Link to="logout">Dodatek</Link>
            </li>
          ) : null} */}
          {role === "Administrator" || role === "Manager" ? (
            <li>
              <BiFoodMenu />
              <Link to="menu">Menu</Link>
            </li>
          ) : null}
        </ul>
        {role === "Administrator" ? <p>Konfiguracja</p> : null}
        <ul>
          {role === "Administrator" ? (
            <li>
              <RiContractLine />
              <Link to="license">Licencje</Link>
            </li>
          ) : null}
          {role === "Administrator" ? (
            <li>
              <CiWallet />
              <Link to="credential">PayU</Link>
            </li>
          ) : null}
        </ul>
        <p>Ustawienia</p>
        <ul>
          <li>
            <CgProfile />
            <Link to="user">Profil</Link>
          </li>
          <li>
            <LuHelpCircle />
            <Link to="menu">Pomoc</Link>
          </li>
          <li>
            <IoMdLogOut />
            <Link to="logout">Wyloguj</Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default DesktopSidebar;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 *
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER  +
 *
 * PASS
 ***/
