import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import AppStateContext from "../../../../appState/AppStateContext";
import analyzeString from "../../../../utils/AnalyzeString";
import { AiOutlineMenuFold } from "react-icons/ai";
import { CiLocationArrow1, CiWallet } from "react-icons/ci";
import { IoMdLogOut } from "react-icons/io";
import { GrUserManager } from "react-icons/gr";
import { LuHelpCircle } from "react-icons/lu";
import { CgProfile } from "react-icons/cg";
import { RiAdminLine, RiContractLine } from "react-icons/ri";
import { BiCategory, BiFoodMenu, BiAddToQueue } from "react-icons/bi";
import { IoPersonOutline } from "react-icons/io5";
import { HiOutlineBuildingStorefront } from "react-icons/hi2";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
import logo from "../../../../img/logo_2.jpg";
import "./MobileSidebar.css";

/**
 * MobileSidebar component represents the sidebar menu for mobile devices.
 * It displays a collapsible menu with different options based on the user's role.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.isOpen - Indicates whether the sidebar is open or closed.
 * @param {function} props.setIsOpen - Callback function to set the state of the sidebar.
 * @returns {JSX.Element} The rendered MobileSidebar component.
 */
const MobileSidebar = ({ isOpen, setIsOpen }) => {
  // Getting global state
  const { state } = useContext(AppStateContext);

  // Role formatting
  let role;
  if (typeof state.userDetails.roles === "string") {
    role = analyzeString(state.userDetails.roles);
  }

  // Local state management
  const [expandedMenu, setExpandedMenu] = useState(null);

  // A function that performs actions related to clicking the hamburger button
  const handleMenuClick = (menuName) => {
    if (expandedMenu === menuName) {
      setExpandedMenu(null);
    } else {
      setExpandedMenu(menuName);
    }
  };

  // A function that changes the position of the menu arrow
  const getMenuArrow = (menuName) => {
    return expandedMenu === menuName ? (
      <MdKeyboardArrowDown />
    ) : (
      <MdKeyboardArrowRight />
    );
  };

  return (
    <div
      className="sidebar__mobile"
      style={{ transform: isOpen ? "translateX(0)" : "translateX(-100%)" }}
    >
      <span
        href="#"
        className="btn__mobile--close"
        onClick={() => setIsOpen(false)}
      >
        <AiOutlineMenuFold className="icon" />
      </span>

      <div className="nestedMenu__container">
        <div className="sidebar__logo">
          <img src={logo} alt="Logo" />
        </div>
        <div
          className="menu__item"
          onClick={() => handleMenuClick("Dashboard")}
        >
          Dashboard <span className="arrow">{getMenuArrow("Dashboard")}</span>
        </div>
        {expandedMenu === "Dashboard" && (
          <div className="submenu">
            <div className="submenu__item">
              {role === "Administrator" ? (
                <li>
                  <RiAdminLine />
                  <Link to="menu">Administrator</Link>
                </li>
              ) : null}
            </div>
            <div className="submenu__item">
              {role === "Administrator" || role === "Manager" ? (
                <li>
                  <GrUserManager />
                  <a href="#">Manager</a>
                </li>
              ) : null}
            </div>
            <div className="submenu__item">
              {role === "Administrator" ||
              role === "Manager" ||
              role === "Operator" ? (
                <li>
                  <IoPersonOutline />
                  <a href="#">Operator</a>
                </li>
              ) : null}
            </div>
          </div>
        )}
        <div className="menu__item" onClick={() => handleMenuClick("Details")}>
          Szczegóły <span className="arrow">{getMenuArrow("Details")}</span>
        </div>
        {expandedMenu === "Details" && (
          <div className="submenu">
            <div className="submenu__item">
              {role === "Administrator" || role === "Manager" ? (
                <li>
                  <HiOutlineBuildingStorefront />
                  <Link to="company">Firma</Link>
                </li>
              ) : null}
            </div>
            <div className="submenu__item">
              {role === "Administrator" || role === "Manager" ? (
                <li>
                  <CiLocationArrow1 />
                  <Link to="location">Lokalizacja</Link>
                </li>
              ) : null}
            </div>
            <div className="submenu__item">
              {role === "Administrator" || role === "Manager" ? (
                <li>
                  <BiCategory />
                  <Link to="category">Kategoria</Link>
                </li>
              ) : null}
            </div>
            {/* <div className="submenu__item">
              {role === "Administrator" || role === "Manager" ? (
                <li>
                  <BiAddToQueue />
                  <Link to="menu">Dodatek</Link>
                </li>
              ) : null}
            </div> */}
            <div className="submenu__item">
              {role === "Administrator" || role === "Manager" ? (
                <li>
                  <BiFoodMenu />
                  <Link to="menu">Menu</Link>
                </li>
              ) : null}
            </div>
          </div>
        )}
        <div
          className="menu__item"
          onClick={() => handleMenuClick("Configuration")}
        >
          Konfiguracja{" "}
          <span className="arrow">{getMenuArrow("Configuration")}</span>
        </div>
        {expandedMenu === "Configuration" && (
          <div className="submenu">
            <div className="submenu__item">
              {" "}
              {role === "Administrator" ? (
                <li>
                  <RiContractLine />
                  <Link to="menu">Licencje</Link>
                </li>
              ) : null}
            </div>
            <div className="submenu__item">
              {role === "Administrator" ? (
                <li>
                  <CiWallet />
                  <Link to="credential">PayU</Link>
                </li>
              ) : null}
            </div>
          </div>
        )}
        <div className="menu__item" onClick={() => handleMenuClick("Settings")}>
          Ustawienia <span className="arrow">{getMenuArrow("Settings")}</span>
        </div>
        {expandedMenu === "Settings" && (
          <div className="submenu">
            <div className="submenu__item">
              <CgProfile />
              <Link to="user">Profil</Link>
            </div>
            <div className="submenu__item">
              <LuHelpCircle />
              <Link to="menu">Pomoc</Link>
            </div>
            <div className="submenu__item">
              <IoMdLogOut />
              <Link to="logout">Wyloguj</Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileSidebar;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 *
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER  +
 *
 * PASS
 ***/
