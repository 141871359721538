import React, { useState, useContext } from "react";
import AppStateContext from "../../../appState/AppStateContext";
import MobileSidebar from "../../molecules/Dashboard/Navigation/MobileSidebar";
import DesktopSidebar from "../../molecules/Dashboard/Navigation/DesktopSidebar";
import Overlay from "../../molecules/Dashboard/Navigation/Overlay";
import ToggleButtons from "../../molecules/Dashboard/Navigation/ToggleButtons";
import "./Navigation.css";

/**
 * Renders the Dashboard Navigation component.
 *
 * @returns {JSX.Element} The rendered Dashboard Navigation component.
 */
const DashboardNavigation = () => {
  // Retrieves actions from the application context
  const { dispatch } = useContext(AppStateContext);

  // Creating a local state
  const [isOpen, setIsOpen] = useState(false);
  const [isClose, setIsClose] = useState(false);

  /**
   * Closes the desktop sidebar and updates settings.
   */
  const handleCloseDesktop = () => {
    setIsClose(true);
    dispatch({
      type: "UPDATE_SETTINGS",
      payload: {
        changingWidthMain: true,
      },
    });
  };

  /**
   * Opens the desktop sidebar and updates settings.
   */
  const handleOpenDesktop = () => {
    setIsClose(false);
    dispatch({
      type: "UPDATE_SETTINGS",
      payload: {
        changingWidthMain: false,
      },
    });
  };

  return (
    <div>
      <MobileSidebar isOpen={isOpen} setIsOpen={setIsOpen} />
      <Overlay isOpen={isOpen} setIsOpen={setIsOpen} />
      <DesktopSidebar
        isClose={isClose}
        handleCloseDesktop={handleCloseDesktop}
      />
      <ToggleButtons
        isOpen={isOpen}
        isClose={isClose}
        setIsOpen={setIsOpen}
        handleOpenDesktop={handleOpenDesktop}
      />
    </div>
  );
};

export default DashboardNavigation;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
