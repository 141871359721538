import React, { useContext } from "react";
import AppStateContext from "../../../../../appState/AppStateContext";
import PackageSelector from "../Card/PackageSelector";
import "./Step.css";

/**
 * StepFifth component.
 * Renders a form for implementation or delivery based on the selected key in the purchase state.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.markComplete - Callback function to mark the step as complete.
 * @param {Function} props.markUnComplete - Callback function to mark the step as incomplete.
 * @returns {JSX.Element} The rendered StepFifth component.
 */
const StepFifth = ({ markComplete, markUnComplete }) => {
  const { state } = useContext(AppStateContext);

  return (
    <div className="content">
      {state.purchase.implementation.key === "Elastyczna" ? (
        <div>
          <h2 className="content__h2">FORMULARZ IMPLEMENTACJI</h2>
          <p className="content__p">
            W celu implementacji uzupełnij formularz adresowy.
          </p>
        </div>
      ) : (
        <div>
          <h2 className="content__h2">FORMULARZ DOSTAWY</h2>
          <p className="content__p">
            W celu dostawy uzupełnij formularz adresowy.
          </p>
        </div>
      )}
      <PackageSelector
        step="delivery"
        markComplete={markComplete}
        markUnComplete={markUnComplete}
      />
    </div>
  );
};

export default StepFifth;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
