import React from "react";
import "./Spinner.css";

/**
 * A component that renders a spinner.
 * @component
 */
const Spinner = () => {
  return (
    <div className="spinner">
      <div className="spinner__container"></div>
    </div>
  );
};

export default Spinner;

/***
 * AUDIT
 * 05.06.2024
 *
 * LOADING SUPPORT +
 * ERROR HANDLING +
 * SECURING INCOMING DATA +
 * SECURING OUTGOING DATA +
 * API CORRECTNESS +
 * CSS CORRECTNESS +
 * ACCESS TOKEN +
 * MOBILE 360PX +
 *
 * BACKEND CONTROLER +
 *
 * PASS
 ***/
