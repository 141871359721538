import React, { useState, useContext } from "react";
import axios from "axios";
import DOMPurify from "dompurify";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router-dom";
import AppStateContext from "../../../appState/AppStateContext";
import handleError from "../../../utils/HandleError";
import Message from "../../atoms/General/Message";
import Spinner from "../../atoms/General/Spinner";
import styles from "./ChangePasswordForm.module.css";

/**
 * ChangePasswordForm component for changing user password.
 *
 * @returns {JSX.Element} The rendered ChangePasswordForm component.
 */
const ChangePasswordForm = () => {
  const navigate = useNavigate();

  const { state, dispatch } = useContext(AppStateContext);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [noRole, setNoRole] = useState(false);
  const [successOccurred, setSuccessOccurred] = useState(false);
  const [incompletenessOccurred, setIncompletenessOccurred] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const validatePassword = (password) => {
    const re =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return re.test(password);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setPasswordError("");
    setSuccessOccurred(false);
    setErrorOccurred(false);
    setIncompletenessOccurred(false);
    setNoRole(false);

    if (newPassword !== repeatNewPassword) {
      setPasswordError("Nowe hasło i powtórzone nowe hasło nie są takie same");
      return;
    }

    if (!validatePassword(newPassword)) {
      setPasswordError(
        "Hasło musi zawierać co najmniej 8 znaków, w tym co najmniej jedną dużą literę, jedną małą literę, jedną cyfrę i jeden znak specjalny"
      );
      return;
    }

    const cleanOldPassword = DOMPurify.sanitize(oldPassword);
    const cleanNewPassword = DOMPurify.sanitize(newPassword);

    try {
      setIsLoading(true);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/changepassword/${state.userDetails.userSysID}`,
        {
          oldPassword: cleanOldPassword,
          newPassword: cleanNewPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${state.accessToken}`,
            "Refresh-Token": state.userDetails.refreshToken,
          },
          withCredentials: true,
        }
      );

      if (
        response.data &&
        response.data.newAccessToken &&
        response.data.newAccessToken !== state.newAccessToken
      ) {
        dispatch({
          type: "UPDATE_ACCESS_TOKEN",
          payload: response.data.newAccessToken,
        });
      }

      if (response.status === 200) {
        setNoRole(false);
        setIncompletenessOccurred(false);
        setErrorOccurred(false);
        setSuccessOccurred(true);
        setOldPassword("");
        setNewPassword("");
        setRepeatNewPassword("");
      }
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          message: "Error changing password.",
          component: "ChangePasswordForm",
        },
      });
      handleError(
        error,
        navigate,
        setNoRole,
        setIncompletenessOccurred,
        setErrorOccurred
      );
      setOldPassword("");
      setNewPassword("");
      setRepeatNewPassword("");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.formContainer}>
      <div className={styles.general}>
        <h1 className={styles.title}>Zmiana hasła</h1>
        <Message
          success={successOccurred}
          incompleteness={incompletenessOccurred}
          error={errorOccurred}
          noRole={noRole}
          successMessage="Hasło zostało zmienione!"
          incompletenessMessage="Nie znaleziono potrzebnych danych na serwerze. Aktualne hasło może być też nie poprawne."
          errorMessage="Wewnętrzny błąd serwera. Skontaktuj się z administratorem!"
          noRoleMessage="Brak udpowiednich uprawnień. Skontaktuj się z administratorem!"
        />
        {isLoading && <Spinner />}
        <div className={styles.passwordRequirements}>
          {passwordError && (
            <p className={styles.passwordError}>{passwordError}</p>
          )}
        </div>
      </div>
      <form onSubmit={handleFormSubmit} className={styles.form}>
        <label>
          Stare hasło:
          <input
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            className={styles.myInput}
          />
        </label>
        <label>
          Nowe hasło:
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className={styles.myInput}
          />
        </label>
        <label>
          Powtórz nowe hasło:
          <input
            type="password"
            value={repeatNewPassword}
            onChange={(e) => setRepeatNewPassword(e.target.value)}
            className={styles.myInput}
          />
        </label>
        <div className={styles.btn}>
          <button className={styles.btn} type="submit">
            Zmień hasło
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChangePasswordForm;

// - AUDIT
// - 25.06.2024
// - LOADING SUPPORT +
// - ERROR HANDLING +
// - SECURING INCOMING DATA +
// - SECURING OUTGOING DATA +
// - API CORRECTNESS +
// - CSS CORRECTNESS +
// - ACCESS TOKEN +
// - MOBILE 360PX +
// - BACKEND CONTROLER +
// - PASS
