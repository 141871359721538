import React from "react";
import LogoutForm from "../../organisms/Dashboard/LogoutForm";

/**
 * Renders the Logout component.
 *
 * @component
 * @returns {JSX.Element} The rendered Logout component.
 */
const Logout = () => (
  <>
    <LogoutForm />
  </>
);

export default Logout;

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
