import React from "react";
import InfoMessage from "../../atoms/General/InfoMessage";
import MobileButtons from "../../atoms/Dashboard/Button/MobileButton";
import DesktopButtons from "../../atoms/Dashboard/Button/DesktopButton";
import EmployeeList from "../organisms/EmployeeList";
import styles from "./Employee.module.css";

/**
 * Represents the Employee component.
 * This component displays a list of employees for a specific location.
 * It also provides options to create a new employee or view details of an existing employee.
 */
const Employee = () => {
  return (
    <div className={styles.employee}>
      <div className={styles.employee__btn}>
        <InfoMessage additionalInfo="W tym miejscu możesz zobaczyć pracowników danej lokalizacji. Jeśli chcesz możesz utworzyć nowego pracownika lub zobaczyć szczegóły istniejącego pracownika pracownika." />
        <div>
          <DesktopButtons action="newEmployee" />
          <MobileButtons action="newEmployee" />
        </div>
      </div>
      <EmployeeList />
    </div>
  );
};

export default Employee;

// - AUDIT
// - 25.06.2024
// - LOADING SUPPORT +
// - ERROR HANDLING +
// - SECURING INCOMING DATA +
// - SECURING OUTGOING DATA +
// - API CORRECTNESS +
// - CSS CORRECTNESS +
// - ACCESS TOKEN +
// - MOBILE 360PX +
// - BACKEND CONTROLER +
// - PASS`
